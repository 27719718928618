import { configureStore } from '@reduxjs/toolkit';

import MentorReducer from './slices/MentorSlice';
import MenteeReducer from './slices/MenteeSlice';
import CommonSlice from './slices/CommonSlice';
import LoginSlice from './slices/LoginSlice';
import UserSlice from './slices/UserSlice';
import AssignMenteeSlice from './slices/AssignMenteeSlice';
import TopicSlice from './slices/TopicSlice';
import AssignWallReducer from './slices/AssignWallSlice';
import CurriculumSlice from './slices/CurriculumSlice';
import ProjectsSlice from './slices/ProjectsSlice';
import BatchSlice from './slices/BatchSlice';
import WallListReducer from './slices/WallListSlice';
import ReportSlice from './slices/ReportSlice';
import PostSlice from './slices/PostSlice';
import AssignSessionSlice from './slices/AssignSessionSlice';
import SessionSlice from './slices/SessionSlice';
import ProfileSlice from './slices/ProfileSlice';
import CommentsSlice from './slices/CommetsSlice';
import RequestFeedbackSlice from './slices/RequestFeedbackSlice';
import InternalInitiativesSlice from './slices/InternalInitiativesSlice';
import MentorLeaderboardSlice from './slices/MentorLeaderboardSlice';
import VirtualTeamHeadSlice from './slices/VirtualTeamHeadSlice';
import KanbanSlice from './slices/kanban/KanbanSlice';
import SprintSlice from './slices/kanban/SprintSlice';

const store = configureStore({
  reducer: {
    mentor: MentorReducer,
    mentee: MenteeReducer,
    user: UserSlice,
    login: LoginSlice,
    common: CommonSlice,
    assignMentee: AssignMenteeSlice,
    topic: TopicSlice,
    assignWall: AssignWallReducer,
    curriculum: CurriculumSlice,
    projects: ProjectsSlice,
    batch: BatchSlice,
    wallList: WallListReducer,
    report: ReportSlice,
    post: PostSlice,
    assignSessions: AssignSessionSlice,
    session: SessionSlice,
    profile: ProfileSlice,
    comments: CommentsSlice,
    requestFeedback: RequestFeedbackSlice,
    // assignSession: assignSessionSlice
    initiatives: InternalInitiativesSlice,
    mentorLeaderboard: MentorLeaderboardSlice,
    virtualTeamHead: VirtualTeamHeadSlice,
    kanban: KanbanSlice,
    sprint: SprintSlice
  }
});

export default store;
