import React from 'react';
import { components } from 'react-select';
import SelectField from '../../input/selectField/SelectField';
import './TagPeople.scss';

function ValueContainer({ children, ...props }) {
  return (
    components.ValueContainer && (
      <components.ValueContainer {...props}>
        {!!children && (
          <i className="bi bi-tag-fill tag-icon" aria-hidden="true" />
        )}
        {children}
      </components.ValueContainer>
    )
  );
}

function TagPeople(props) {
  const formatOptionLabel = (option, { context }) =>
    // eslint-disable-next-line no-nested-ternary
    context === 'value'
      ? option.label
      : option.value === '*'
      ? option.label
      : `${option.label} - ${option.email}`;

  return (
    <div className="tagged-people-wrapper">
      <SelectField
        className="taggedPeople"
        classNamePrefix="taggedPeople-select"
        placeholder="Tag people"
        {...props}
        selectAll
        isClearable
        formatOptionLabel={formatOptionLabel}
        controlShouldRenderValue={false}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
          ValueContainer
        }}
      />
    </div>
  );
}

export default TagPeople;
