import { useEffect, useState } from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import './Tab.scss';

function Tab({ tabPane, defaultActiveTab = 1 }) {
  const [activeTab, setActiveTab] = useState(defaultActiveTab);

  useEffect(() => {
    setActiveTab(defaultActiveTab);
  }, [defaultActiveTab]);

  return (
    <div className="tab-container">
      <Nav tabs>
        {tabPane.map((title) => (
          <NavItem key={title.id}>
            <NavLink
              className={`cursor-pointer {activeTab === title.id ? 'active' : ''}`}
              onClick={() => setActiveTab(title.id)}>
              {title.title}
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <TabContent activeTab={activeTab}>
        {tabPane.map((content) => (
          <TabPane key={content.id} tabId={content.id}>
            {content.content}
          </TabPane>
        ))}
      </TabContent>
    </div>
  );
}

export default Tab;
