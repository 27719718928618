import { useEffect } from 'react';
import { toast } from 'react-toastify';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Toast from '../toast/Toast';

function InjectAxiosInterceptors() {
  const navigate = useNavigate();
  useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        if (
          response.config.method.toUpperCase() !== 'GET' &&
          !response.config.url.includes('/kanban/getKanbanAllTaskDetails')
        ) {
          toast.success(
            response.request.responseURL.includes('/login')
              ? 'Login Successfull!'
              : `${response.data.message}`,
            {
              position: 'bottom-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined
            }
          );
        }
        return response;
      },
      (errorRes) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        let errorMessage = errorRes.response.data.message;
        if (typeof errorMessage === 'object') {
          errorMessage = errorMessage[Object.keys(errorMessage)[0]];
        }
        if (
          errorRes.response.status === 401 &&
          errorMessage.includes('jwt expired')
        ) {
          navigate('/session-expired');
        }
        toast.error(
          errorRes.response.status === 401 &&
            errorMessage.includes('jwt expired')
            ? 'Session timeout, Try logout and login again'
            : `${errorMessage}`, // errorRes.response.data.message
          {
            position: 'bottom-right',
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined
          }
        );
        return Promise.reject(errorRes);
      }
    );
  }, []);
  return <Toast />;
}

export default InjectAxiosInterceptors;
