import React, { useState, useEffect } from 'react';
import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import {
  gitlabClientId,
  gitLabUrl,
  gitlabRedirectUri
} from '../../../../../shared/constants/kanbanConstant';
import gitLabLogo from '../../../../../assets/images/gitlabLogo.svg';

import './GitLabConnectButton.scss';
import { setGitLabPopupState } from '../../../../../redux/slices/kanban/KanbanSlice';

function GitLabConnectButton({
  callback,
  buttonText,
  redirectRoute,
  isCreateBranch = false
}) {
  const dispatch = useDispatch();
  const [popupWindow, setPopupWindow] = useState(null);
  // const [webhookAddedStatus, setWebhookAddedStatus] = useState(null);
  // console.log(webhookAddedStatus);

  useEffect(() => {
    const handleMessage = (event) => {
      const eventType = event.data.type;
      if (eventType === 'branchCreated') {
        dispatch(
          setGitLabPopupState({ status: 'closed', taskId: redirectRoute })
        );
      }
      if (eventType === 'webhookAdded' || eventType === 'webhookRemoved') {
        // if (event.data.success) {
        //   setWebhookAddedStatus('success');
        // } else {
        //   setWebhookAddedStatus('error');
        // }
        callback();
        if (popupWindow) {
          popupWindow.close();
        }
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [popupWindow, isCreateBranch]);

  const gitlabOAuthURL = `${gitLabUrl}/oauth/authorize?client_id=${gitlabClientId}&redirect_uri=${gitlabRedirectUri}&response_type=code&scope=read_user api`;
  const openPopup = () => {
    const screenWidth = window.screen.width;
    const screenHeight = window.screen.height;
    const popupWidth = 750;
    const popupHeight = 700;
    const left = Math.round((screenWidth - popupWidth) / 2);
    const top = Math.round((screenHeight - popupHeight) / 2);

    // Redirect after getting the gitlab access token
    Cookies.set('gitlabCallbackRedirectRoute', redirectRoute);

    const popup = window.open(
      gitlabOAuthURL,
      '_blank',
      `width=${popupWidth},height=${popupHeight},left=${left},top=${top}`
    );

    setPopupWindow(popup);
  };

  const handleGitLabLogin = () => {
    openPopup();
    if (isCreateBranch) {
      dispatch(
        setGitLabPopupState({ status: 'opened', taskId: redirectRoute })
      );
    }
  };

  return (
    <button
      type="button"
      onClick={handleGitLabLogin}
      className={isCreateBranch ? 'createBranchButton' : 'gitLabConnectButton'}>
      {isCreateBranch && (
        <img src={gitLabLogo} alt="gitlablogo" className="gitlabLogo" />
      )}
      {buttonText}
    </button>
  );
}

export default GitLabConnectButton;
