import React from 'react';
import Avatar from 'react-avatar';
import { UncontrolledTooltip } from 'reactstrap';
import './AvatarGroup.scss';

export default function AvatarGroup({ users }) {
  return (
    <div className="avatar-group">
      {users.map(
        (user, index) =>
          index < 3 && (
            <AvatarItem
              key={user.user_id}
              user={user}
              isFirst={index === 0}
              index={index}
            />
          )
      )}
      {users.length > 3 && (
        <div className="d-inline-block avatar-item overlapped-avatar">
          <Avatar
            name={`+ ${users.length - 3}`}
            className="m-auto"
            round
            maxInitials={3}
            size="25"
          />
        </div>
      )}
    </div>
  );
}

function AvatarItem({ user, isFirst, index }) {
  return (
    <>
      <div
        className={`d-inline-block avatar-item ${
          !isFirst && 'overlapped-avatar'
        }`}
        id={`avatarId-${index}`}>
        {user.user_avatar_url ? (
          <img
            className="user-image rounded-circle m-auto"
            src={user.user_avatar_url}
            height={25}
            alt="profilePic"
          />
        ) : (
          <Avatar
            name={user.user_name}
            className="m-auto"
            round
            maxInitials={3}
            size="25"
          />
        )}
      </div>
      <UncontrolledTooltip target={`avatarId-${index}`} placement="bottom">
        {user?.user_name}
      </UncontrolledTooltip>
    </>
  );
}
