import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import './Likes.scss';
import {
  saveLikesActionCreator,
  saveCommentLikesActionCreator,
  getMyNotificationListActionCreator
} from '../../../redux/slices/PostSlice';

function Likes({ likeDetails, likeType = 'post' }) {
  const dispatch = useDispatch();
  const { wallID } = useParams();
  const [likeData, setLikeData] = useState(false);
  const [btnClass, setBtnClass] = useState(false);
  const [btnAnimating, setBtnAnimating] = useState(false);
  const authState = useSelector((state) => state.login.loginDetails);

  useEffect(() => {
    setLikeData({
      isLike: likeDetails?.is_liked,
      count: likeDetails?.like_count || likeDetails?.reply_like_count || 0
    });
  }, [likeDetails]);

  useEffect(() => {
    const buttonClasses = `bi text-primary icon ${
      likeData?.isLike ? 'bi-hand-thumbs-up-fill' : 'bi-hand-thumbs-up'
    } ${btnAnimating ? 'bump' : ''}`;

    setBtnClass(buttonClasses);

    const timer = setTimeout(() => {
      setBtnAnimating(false);
    }, 300);

    return () => {
      clearTimeout(timer);
    };
  }, [likeData, btnAnimating]);

  const onClickLike = () => {
    setBtnAnimating(true);
    setLikeData({
      isLike: !likeData.isLike,
      count: likeData.isLike ? likeData.count - 1 : likeData.count + 1
    });
    const postLike = {
      body: {
        liked_by: authState.employee_id
      }
    };
    let actionCreator = null;
    if (likeType === 'post') {
      postLike.body.post_id = likeDetails.post_id;
      actionCreator = dispatch(saveLikesActionCreator(postLike));
    } else if (likeType === 'comment') {
      postLike.body.comment_id = likeDetails.comment_id;
      actionCreator = dispatch(saveCommentLikesActionCreator(postLike));
    }
    actionCreator.unwrap().then(() => {
      dispatch(getMyNotificationListActionCreator(wallID));
    });
  };

  return (
    <div className="like-container">
      <button
        className="like-btn p-0 d-flex align-items-center"
        onClick={onClickLike}
        type="button">
        <i className={btnClass || ''} />
        <span className="px-2 text-sub font-regular">
          {likeData?.count === 0
            ? `Like`
            : `${likeData?.count > 0 ? likeData?.count : ''} `}
        </span>
        {likeData?.count > 0 && (
          <span className="text-sub font-regular hide-text">Like </span>
        )}
      </button>
    </div>
  );
}

export default Likes;
