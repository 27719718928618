import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SERVER_URL from '../../../shared/constants/Constants';
import fetchUtility from '../../../shared/helper/fetchUtility';

const initialState = {
  sprintList: [],
  isSprintListDataLoaded: false,
  sprintAllTaskDetails: [],
  sprintDetails: null,
  loading: false,
  btnLoader: false,
  status: '',
  statusCode: null,
  error: ''
};

export const createSprintActionCreator = createAsyncThunk(
  'sprint/create',
  async ({ sprintData }) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.KANBAN_SPRINT_CREATE}`,
      sprintData?.body
    );
    return data;
  }
);

export const updateSprintActionCreator = createAsyncThunk(
  'sprint/update',
  async ({ sprintData, sprintId }) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.KANBAN_SPRINT_UPDATE}${sprintId}`,
      sprintData?.body
    );
    return data;
  }
);

export const deleteSprintActionCreator = createAsyncThunk(
  'sprint/delete',
  async (sprintId) => {
    const { data } = await fetchUtility(
      'delete',
      `${SERVER_URL.KANBAN_SPRINT_DELETE}${sprintId}`
    );
    return data;
  }
);

export const getSprintDetailsActionCreator = createAsyncThunk(
  'sprint/details',
  async ({ sprintId }) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.KANBAN_SPRINT_DETAILS}${sprintId}`
    );
    return data;
  }
);

export const getSprintListActionCreator = createAsyncThunk(
  'sprint/list',
  async ({ wallId }) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.KANBAN_SPRINT_LIST}/${wallId}`
    );
    return data;
  }
);

export const startSprintActionCreator = createAsyncThunk(
  'sprint/start',
  async (sprintId) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.KANBAN_SPRINT_START}${sprintId}`
    );
    return data;
  }
);

export const completeSprintActionCreator = createAsyncThunk(
  'sprint/complete',
  async ({ sprintId, body }) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.KANBAN_SPRINT_COMPLETE}${sprintId}`,
      body
    );
    return data;
  }
);

export const getSprintAllTaskDetailsActionCreator = createAsyncThunk(
  'sprint/all_task_details',
  async ({ wallId }) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.KANBAN_SPRINT_ALL_TASK_DETAILS}${wallId}`
    );

    return data;
  }
);

const sprintSlice = createSlice({
  name: 'sprint',
  initialState,
  extraReducers: (builder) => {
    // SPRINT_CREATE
    builder.addCase(createSprintActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createSprintActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(createSprintActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // SPRINT_UPDATE
    builder.addCase(updateSprintActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateSprintActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(updateSprintActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // SPRINT_DELELTE
    builder.addCase(deleteSprintActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteSprintActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(deleteSprintActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // SPRINT_LIST
    builder.addCase(getSprintListActionCreator.pending, (state) => {
      state.loading = true;
      state.isSprintListDataLoaded = false; // Reset isSprintListDataLoaded to false on pending
    });
    builder.addCase(getSprintListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.sprintList = action.payload.result;
      state.error = '';
      state.isSprintListDataLoaded = true; // Set isSprintListDataLoaded to true on successful fetch
    });
    builder.addCase(getSprintListActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // SPRINT_DETAILS
    builder.addCase(getSprintDetailsActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getSprintDetailsActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.sprintDetails = action.payload.result;
        state.error = '';
      }
    );
    builder.addCase(getSprintDetailsActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // SPRINT_START
    builder.addCase(startSprintActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(startSprintActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(startSprintActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // SPRINT_COMPLETE
    builder.addCase(completeSprintActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(completeSprintActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(completeSprintActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // SPRINT_ALL_TASK_DETAILS
    builder.addCase(getSprintAllTaskDetailsActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getSprintAllTaskDetailsActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.sprintAllTaskDetails = action.payload.result;
        state.error = '';
      }
    );
    builder.addCase(
      getSprintAllTaskDetailsActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
  }
});

// Drag and drop tasks through different sprints in Sprint Board
export const updateSprintStatus = createAsyncThunk(
  'kanban/sprintStatus',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.SPRINT_TASK_STATUS_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);

export default sprintSlice.reducer;
