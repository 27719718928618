import React from 'react';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { savePostPinActionCreator } from '../../../redux/slices/PostSlice';

function Pin({ postID, isPin, getPostList }) {
  // const [isPinMarked, setIsPinMarked] = useState(false);
  const authState = useSelector((state) => state.login.loginDetails);
  const isWallMember = useSelector((state) => state.post.isWallMember);

  const { wallID } = useParams();

  const dispatch = useDispatch();

  const handlePinClick = () => {
    const apiData = {
      body: {
        wall_id: wallID,
        employee_id: authState.employee_id,
        post_id: postID,
        is_pin: !isPin
      }
    };

    dispatch(savePostPinActionCreator(apiData)).then((res) => {
      // setIsPinMarked(!isPinMarked);
      if (res.payload.message.toUpperCase() === 'POST UNPIN SUCCESSFULLY')
        getPostList(true, true);
      else getPostList(true);
    });
  };

  return isWallMember ? (
    <Button
      color="none"
      className="cursor-pointer col-1 ms-2"
      onClick={handlePinClick}>
      <i
        className={`bi ${isPin ? 'bi-pin-angle-fill' : 'bi-pin-angle'} fs-4`}
      />
    </Button>
  ) : (
    ''
  );
}

export default Pin;
