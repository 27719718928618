import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';

const initialState = {
  mentorList: [],
  mentorDetails: [],
  loading: false,
  btnLoader: false,
  status: '',
  statusCode: null,
  error: ''
};

export const getMentorListActionCreator = createAsyncThunk(
  'mentor/list',
  async () => {
    const { data } = await fetchUtility('get', `${SERVER_URL.MENTOR_LIST}`, {});
    return data;
  }
);

export const saveMentorActionCreator = createAsyncThunk(
  'mentor/save',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.MENTOR_CREATE}`,
      params
    );
    return { data, insertedData: { ...params } };
  }
);

export const getMentorDetailActionCreator = createAsyncThunk(
  'mentor/detail',
  async (id) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.MENTOR_DETAIL}${id}`
    );
    return data.result;
  }
);

export const deleteMentorActionCreator = createAsyncThunk(
  'mentor/delete',
  async (id) => {
    const { data } = await fetchUtility(
      'delete',
      `${SERVER_URL.MENTOR_DELETE}${id}`
    );
    return { data, insertedData: { mentor_id: id } };
  }
);

export const updateMentorActionCreator = createAsyncThunk(
  'mentor/update',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.MENTOR_UPDATE}${params.mentor_id}`,
      params.body
    );
    return { data, insertedData: { ...params } };
  }
);

const mentorSlice = createSlice({
  name: 'mentor',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getMentorListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMentorListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.mentorList = action.payload.result;
      state.error = '';
    });
    builder.addCase(getMentorListActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.code;
    });

    builder.addCase(deleteMentorActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(deleteMentorActionCreator.fulfilled, (state, action) => {
      state.mentorList = state.mentorList.filter(
        (mentor) => mentor.mentor_id !== action.payload.insertedData.mentor_id
      );
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(deleteMentorActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(saveMentorActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveMentorActionCreator.fulfilled, (state) => {
      // state.mentorList.unshift(action.payload.insertedData);
      state.loading = false;
      state.error = '';
    });
    builder.addCase(saveMentorActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateMentorActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateMentorActionCreator.fulfilled, (state) => {
      // const index = state.mentorList.findIndex(
      //   (mentor) => mentor.mentor_id === action.payload.insertedData.mentor_id
      // );
      // if (index !== -1) {
      //   state.mentorList[index] = action.payload.insertedData;
      // }
      state.loading = false;
      state.error = '';
    });
    builder.addCase(updateMentorActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getMentorDetailActionCreator.pending, (state) => {
      // state.mentorDetails = [];
      state.loading = true;
    });
    builder.addCase(getMentorDetailActionCreator.fulfilled, (state, action) => {
      state.mentorDetails = action.payload;
      state.loading = false;
      state.error = '';
    });
    builder.addCase(getMentorDetailActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  }
});

export default mentorSlice.reducer;
