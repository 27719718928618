import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';

const initialState = {
  userList: [],
  userDetails: null,
  loading: false,
  btnLoader: false,
  status: '',
  statusCode: null,
  error: ''
};

export const getUserListActionCreator = createAsyncThunk(
  'user/list',
  async (params) => {
    let API_URL = `${SERVER_URL.USER_LIST}`;
    if (params?.wallID) API_URL += `/${params?.wallID}`;
    if (params?.user_type) API_URL += `?user_type=${params?.user_type}`;
    const { data } = await fetchUtility('get', `${API_URL}`);
    return data;
  }
);

export const saveUserActionCreator = createAsyncThunk(
  'user/save',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.USER_CREATE}`,
      params.body
    );
    return data;
  }
);

export const getUserDetailActionCreator = createAsyncThunk(
  'user/detail',
  async (id) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.USER_DETAIL}${id}`
    );
    return data;
  }
);

export const deleteUserActionCreator = createAsyncThunk(
  'user/delete',
  async (id) => {
    const { data } = await fetchUtility(
      'delete',
      `${SERVER_URL.USER_DELETE}${id}`
    );
    return data;
  }
);

export const updateUserActionCreator = createAsyncThunk(
  'user/update',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.USER_UPDATE}${params.employee_id}`,
      params.body
    );
    return data;
  }
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getUserListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.userList = action.payload.result;
      state.error = '';
    });
    builder.addCase(getUserListActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteUserActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(deleteUserActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(deleteUserActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(saveUserActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(saveUserActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(saveUserActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(updateUserActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateUserActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updateUserActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(getUserDetailActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserDetailActionCreator.fulfilled, (state, action) => {
      if (action.payload.result.length)
        state.userDetails = { ...action.payload.result[0] };
      state.loading = false;
      state.error = '';
    });
    builder.addCase(getUserDetailActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  }
});

export default userSlice.reducer;
