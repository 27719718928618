import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SERVER_URL from '../../../shared/constants/Constants';
import fetchUtility from '../../../shared/helper/fetchUtility';
import { gitLabUrl } from '../../../shared/constants/kanbanConstant';

const initialState = {
  kanbanList: [],
  kanbanStatusDetails: [],
  kanbanTaskDetails: null,
  kanbanAllTaskDetails: [],
  gitlabAccessToken: null,
  loading: false,
  btnLoader: false,
  status: '',
  statusCode: null,
  error: '',
  gitlabError: '',
  gitLabLoading: false,
  taskGitDetails: null,
  isGitLabPopupOpen: { status: 'notInitialised', taskId: null }
};

export const getKanbanListActionCreator = createAsyncThunk(
  'kanban/list',
  async () => {
    const { data } = await fetchUtility('get', `${SERVER_URL.KANBAN_LIST}`);
    return data;
  }
);

export const getKanbanAllTaskDetailsActionCreator = createAsyncThunk(
  'kanban/all_task_details',
  async ({ wallId, filter }) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.KANBAN_ALL_TASK_DETAILS}${wallId}`,
      filter
    );

    return data;
  }
);

export const getKanbanTaskDetailsActionCreator = createAsyncThunk(
  'kanban/task_details',
  async ({ taskId }) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.KANBAN_TASK_DETAILS}${taskId}`
    );

    return data;
  }
);

export const getKanbanStatusDetailsActionCreator = createAsyncThunk(
  'kanban/status',
  async ({ wallId }) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.KANBAN_STATUS_DETAILS}${wallId}`
    );
    return data;
  }
);

export const exchangeCodeForTokenActionCreator = createAsyncThunk(
  'kanban/exchangeCodeForToken',
  async (code) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL?.KANBAN_GITLAB_ACCESS_TOKEN}${code}`
    );
    return data?.result?.access_token;
  }
);

export const fetchGitlabProjectsActionCreator = createAsyncThunk(
  'kanban/fetchProjects',
  async (accessToken) => {
    const minAccessLevel = 40; // Minimum access level for Maintainer
    const response = await fetch(
      `${gitLabUrl}/api/v4/projects?membership=true&min_access_level=${minAccessLevel}&with_merge_requests_enabled=true`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`
        }
      }
    );
    const data = await response.json();
    return data;
  }
);

export const connectKanbanToGilabActionCreator = createAsyncThunk(
  'kanban/gitlabConnect',
  async ({ body }) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.KANBAN_GITLAB_CONNECT}`,
      body
    );
    return data;
  }
);

export const createGitLabBrancheActionCreator = createAsyncThunk(
  'kanban/gitlab/createBranch',
  async ({ body }) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.KANBAN_GITLAB_CREATE_BRANCH}`,
      body
    );
    return data;
  }
);

export const getGitLabBranchesActionCreator = createAsyncThunk(
  'kanban/gitlab/branchList',
  async (body) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.KANBAN_GITLAB_BRANCH_DETAILS}`,
      body
    );
    return data;
  }
);

export const disconnectKanbanToGilabActionCreator = createAsyncThunk(
  'kanban/gitlabDisconnect',
  async ({ body }) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.KANBAN_GITLAB_DISCONNECT}`,
      body
    );
    return data;
  }
);

export const getGitDetailsOfTaskActionCreator = createAsyncThunk(
  'kanban/gitlab/task',
  async (body) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.KANBAN_GITLAB_TASK_DETAILS}`,
      body
    );

    return data;
  }
);

const kanbanSlice = createSlice({
  name: 'kanban',
  initialState,
  reducers: {
    setGitLabPopupState: (state, action) => {
      state.isGitLabPopupOpen = action.payload;
    }
  },
  extraReducers: (builder) => {
    // KANBAN_LIST
    builder.addCase(getKanbanListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getKanbanListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.kanbanList = action.payload.result;
      state.error = '';
    });
    builder.addCase(getKanbanListActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    // KANBAN_STATUS
    builder.addCase(getKanbanStatusDetailsActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getKanbanStatusDetailsActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.kanbanStatusDetails = action.payload.result;
        state.error = '';
      }
    );
    builder.addCase(
      getKanbanStatusDetailsActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
    // KANBAN_ALL_TASK_DETAILS
    builder.addCase(getKanbanAllTaskDetailsActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getKanbanAllTaskDetailsActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.kanbanAllTaskDetails = action.payload.result;
        state.error = '';
      }
    );
    builder.addCase(
      getKanbanAllTaskDetailsActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
    // KANBAN_TASK_DETAILS
    builder.addCase(getKanbanTaskDetailsActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getKanbanTaskDetailsActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.kanbanTaskDetails = action.payload.result;
        state.error = '';
      }
    );
    builder.addCase(
      getKanbanTaskDetailsActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
    // GET GITLAB ACCESS TOKEN
    builder.addCase(exchangeCodeForTokenActionCreator.pending, (state) => {
      state.gitLabLoading = true;
    });
    builder.addCase(
      exchangeCodeForTokenActionCreator.fulfilled,
      (state, action) => {
        state.gitLabLoading = false;
        state.gitlabAccessToken = action.payload;
        state.gitlabError = '';
      }
    );
    builder.addCase(
      exchangeCodeForTokenActionCreator.rejected,
      (state, action) => {
        state.gitLabLoading = false;
        state.gitlabError = action.error.message;
      }
    );
    // GET GITLAB PROJECT
    builder.addCase(fetchGitlabProjectsActionCreator.pending, (state) => {
      state.gitLabLoading = true;
    });
    builder.addCase(fetchGitlabProjectsActionCreator.fulfilled, (state) => {
      state.gitLabLoading = false;
      state.gitlabError = '';
    });
    builder.addCase(
      fetchGitlabProjectsActionCreator.rejected,
      (state, action) => {
        state.gitLabLoading = false;
        state.gitlabError = action.error.message;
      }
    );
    // CONNECT_KANBAN_TO_GITLAB
    builder.addCase(connectKanbanToGilabActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(connectKanbanToGilabActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(
      connectKanbanToGilabActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
    // CREATE_GETLAB_BRANCHE
    builder.addCase(createGitLabBrancheActionCreator.pending, (state) => {
      state.gitLabLoading = true;
    });
    builder.addCase(createGitLabBrancheActionCreator.fulfilled, (state) => {
      state.gitLabLoading = false;
      state.gitlabError = '';
    });
    builder.addCase(
      createGitLabBrancheActionCreator.rejected,
      (state, action) => {
        state.gitLabLoading = false;
        state.gitlabError = action.error.message;
      }
    );
    // GET_GETLAB_BRANCHES
    builder.addCase(getGitLabBranchesActionCreator.pending, (state) => {
      state.gitLabLoading = true;
    });
    builder.addCase(getGitLabBranchesActionCreator.fulfilled, (state) => {
      state.gitLabLoading = false;
      state.gitlabError = '';
    });
    builder.addCase(
      getGitLabBranchesActionCreator.rejected,
      (state, action) => {
        state.gitLabLoading = false;
        state.gitlabError = action.error.message;
      }
    );
    // DISCONNECT_KANBAN_TO_GITLAB
    builder.addCase(disconnectKanbanToGilabActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(disconnectKanbanToGilabActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(
      disconnectKanbanToGilabActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
    // GET_GITLAB_DETAILS_FOR_BRANCH
    builder.addCase(getGitDetailsOfTaskActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getGitDetailsOfTaskActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.taskGitDetails = action.payload?.result;
        state.error = '';
      }
    );
    builder.addCase(
      getGitDetailsOfTaskActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
  }
});

// Drag and drop tasks through different status columns in Kanban Board
export const updateKanbanTaskStatus = createAsyncThunk(
  'kanban/updateKanbanTaskStatus',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.KANBAN_TASK_STATUS_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);

export const { setGitLabPopupState } = kanbanSlice.actions;

export default kanbanSlice.reducer;
