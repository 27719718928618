import React from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import Avatar from 'react-avatar';
import './PullRequestRow.scss';
import {
  openUrlInNewPage,
  timeago
} from '../../../../../../../shared/helper/commonFunction';
import AvatarGroup from '../../Components/AvatarGroup/AvatarGroup';

export default function PullRequestRow({ pullRequest }) {
  const mrStatusTypes = {
    merged: 'MERGED',
    opened: 'OPEN',
    closed: 'CLOSED'
  };

  return (
    <div className="py-2 hover-highlight">
      <div className="row text-sub">
        <div
          id="prAuthorTooltipTriggerId"
          className="col-md-1 d-flex align-items-center">
          {pullRequest?.user_avatar_url ? (
            <img
              className="user-image rounded-circle m-auto"
              src={pullRequest?.user_avatar_url}
              height={25}
              alt="profilePic"
            />
          ) : (
            <Avatar
              name={pullRequest?.user_name}
              // round="20px"
              className="m-auto"
              round
              maxInitials={3}
              size="25"
            />
          )}
        </div>
        <UncontrolledTooltip
          target="prAuthorTooltipTriggerId"
          placement="bottom">
          {pullRequest?.user_name}
        </UncontrolledTooltip>
        {/* <div className="col-md-1 d-flex align-items-center">
          <div id="prIdTooltip" className="text-truncate">
            #{pullRequest?.object_attributes.id}
          </div>
          <UncontrolledTooltip target="prIdTooltip" placement="bottom">
            {pullRequest?.object_attributes.id}
          </UncontrolledTooltip>
        </div> */}
        <div className="col-md-5 d-flex flex-column align-items-left justify-content-center">
          <p
            role="presentation"
            onClick={() => openUrlInNewPage(pullRequest?.merge_request_url)}
            className="text-primary cursor-pointer mb-0 clickable-link text-truncate">
            {pullRequest?.title}
          </p>
          <div className="d-flex align-items-center">
            <span
              className="source-branch small me-1 cursor-pointer"
              role="presentation"
              onClick={() =>
                openUrlInNewPage(pullRequest?.source_branch_commits_url)
              }>
              {pullRequest?.source_branch}
            </span>
            <i className="bi bi-arrow-right-short" />
            <span className="target-branch small">
              {pullRequest?.target_branch}
            </span>
          </div>
        </div>
        <div className="col-md-1 d-flex align-items-center text-truncate">
          <span className={`status-${pullRequest.state} small`}>
            {mrStatusTypes[pullRequest.state]}
          </span>
        </div>
        <div className="col-md-2 d-flex align-items-center text-truncate">
          <AvatarGroup users={pullRequest?.reviewers} />
        </div>
        <div className="col-md-3 d-flex align-items-center text-truncate">
          {timeago(pullRequest?.updated_at)}
        </div>
      </div>
    </div>
  );
}
