import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookie from 'js-cookie';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';
import { decryptCookieValue } from '../../shared/helper/commonFunction';

const initialState = {
  loginDetails: (() => {
    try {
      const credentials = Cookie.get('credentials');
      if (!credentials) return undefined;

      const decrypted = decryptCookieValue(credentials);
      return decrypted || undefined;
    } catch {
      return undefined;
    }
  })(),
  loading: false,
  error: ''
};

export const getLoginDetailsActionCreator = createAsyncThunk(
  'login/data',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.USER_LOGIN}`,
      {
        token: params.credential
      },
      {},
      {},
      false
    );
    return { ...data, accessToken: params.credential };
  }
);

export const getEmployeeAssigednWallListActionCreator = createAsyncThunk(
  'employeeAssignWalllist',
  async (params) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.EMPLOYEE_ASSIGN_WALL_LIST}/${params}`
    );
    return data;
  }
);

export const logoutUser = createAsyncThunk('logout/detail', async () => {
  const { data } = await fetchUtility('get', `${SERVER_URL.USER_LOGOUT}`);
  return data;
});

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    // logoutUser: (state) => {
    //   state.loginDetails = null;
    // }
  },
  extraReducers: (builder) => {
    builder.addCase(getLoginDetailsActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getLoginDetailsActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.loginDetails = decryptCookieValue(action.payload.result);
      state.error = '';
      Cookie.set('credentials', action.payload.result);
    });
    builder.addCase(getLoginDetailsActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.loginDetails = null;
      state.error = action.error.message;
    });

    builder.addCase(
      getEmployeeAssigednWallListActionCreator.pending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getEmployeeAssigednWallListActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.loginDetails.walls = action?.payload?.result;
        state.error = '';
      }
    );
    builder.addCase(
      getEmployeeAssigednWallListActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.code;
      }
    );

    builder.addCase(logoutUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logoutUser.fulfilled, (state) => {
      state.loading = false;
      Cookie.remove('credentials');
      state.loginDetails = null;
      // state.loginDetails = action.payload.user;
      // // state.loginDetails.role = 1;
      // state.error = '';
      // Cookie.set('credentials', JSON.stringify(state.loginDetails));
    });
    builder.addCase(logoutUser.rejected, (state, action) => {
      state.loading = false;
      state.loginDetails = null;
      state.error = action.error.message;
    });
  }
});

//  export const { logoutUser } = loginSlice.actions;

export default loginSlice.reducer;
