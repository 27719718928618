import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';

const initialState = {
  curriculumList: [],
  curriculumDetails: null,
  loading: false,
  btnLoader: false,
  error: '',
  message: null
};

export const getCurriculumListActionCreator = createAsyncThunk(
  'curriculum/list',
  async (params) => {
    let API_URL = `${SERVER_URL.CURRICULUM_LIST}`;
    if (params?.wallID) API_URL += `/${params?.wallID}`;
    const { data } = await fetchUtility('get', `${API_URL}`);
    return data;
  }
);

export const saveCurriculumActionCreator = createAsyncThunk(
  'curriculum/save',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.CURRICULUM_CREATE}`,
      params.body
    );
    return data;
  }
);

export const getCurriculumDetailActionCreator = createAsyncThunk(
  'curriculum/detail',
  async (id) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.CURRICULUM_DETAIL}${id}`
    );
    return data;
  }
);

export const deleteCurriculumActionCreator = createAsyncThunk(
  'curriculum/delete',
  async (id) => {
    const { data } = await fetchUtility(
      'delete',
      `${SERVER_URL.CURRICULUM_DELETE}${id}`
    );
    return data;
  }
);

export const updateCurriculumActionCreator = createAsyncThunk(
  'curriculum/update',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.CURRICULUM_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);

const curriculumSlice = createSlice({
  name: 'curriculum',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCurriculumListActionCreator.pending, (state) => {
      state.loading = true;
      state.message = null;
      // state.curriculumList = [];
    });
    builder.addCase(
      getCurriculumListActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.curriculumList = action.payload.result;
        state.message = action.payload.message;
        // state.curriculumList = [];
        state.error = '';
      }
    );
    builder.addCase(
      getCurriculumListActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(deleteCurriculumActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(deleteCurriculumActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(deleteCurriculumActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(saveCurriculumActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(saveCurriculumActionCreator.fulfilled, (state) => {
      // state.curriculumList.unshift(action.payload);
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(saveCurriculumActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(updateCurriculumActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateCurriculumActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updateCurriculumActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(getCurriculumDetailActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(
      getCurriculumDetailActionCreator.fulfilled,
      (state, action) => {
        if (action?.payload?.result)
          state.curriculumDetails = { ...action.payload.result };
        state.btnLoader = false;
        state.error = '';
      }
    );
    builder.addCase(
      getCurriculumDetailActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );
  }
});

export default curriculumSlice.reducer;
