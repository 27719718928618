/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Col, Row, FormGroup } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';

import InputField from '../../input/inputField/InputField';
import FileUpload from '../../components/file-upload/FileUpload';
import {
  saveTaskPostActionCreator,
  updateTaskActionCreator
} from '../../../redux/slices/PostSlice';
import SelectField from '../../input/selectField/SelectField';
import TagPeople from '../tagPeople/TagPeople';
import {
  convertToFormData,
  getAutomaticInputHeight,
  listFormatter
} from '../../helper/commonFunction';
import { formatOptionLabel } from '../../helper/commonDataFormatter';

function DropdownIndicator() {
  return (
    <svg
      height="20"
      width="30"
      viewBox="4 0 20 20"
      aria-hidden="true"
      focusable="false"
      className="css-tj5bde-Svg">
      <path
        fill="#E75256"
        d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
      />
    </svg>
  );
}

function WallTaskField({
  postDetail,
  refreshPost,
  users,
  defaultTag,
  assignees,
  onPostValueChange,
  postValue,
  postTypeList,
  handleCancel,
  handleClose,
  formView = 'addView'
}) {
  const authState = useSelector((state) => state.login.loginDetails);
  const btnLoader = useSelector((state) => state.post.btnLoader);

  const [selectedTag, setSelectedTag] = useState(defaultTag || []);
  const [removedFileID, setRemovedFileID] = useState([]);
  const [resetFilePreview, setResetFilePreview] = useState();
  const [fileValue, setFileValue] = useState(null);
  const [descriptionHeight, setDescriptionHeight] = useState(50);

  const { wallID } = useParams();
  const childRef = useRef();
  const {
    reset,
    control,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    watch,
    formState: { errors }
  } = useForm();

  const dispatch = useDispatch();

  const handleSelectedTags = (values) => {
    if (values?.find((option) => option.value === '*')) {
      setSelectedTag(users);
      setValue('taggedPeople', users, { shouldValidate: true });
    } else {
      setSelectedTag(values);
      setValue('taggedPeople', values, { shouldValidate: true });
    }
  };

  if (formView !== 'editView') {
    useEffect(() => {
      setSelectedTag(defaultTag || []);
    }, [defaultTag, users]);
  }

  const watchDescription = watch('task');
  const watchPostToKanban = watch('postToKanban');
  const refDescription = document.getElementsByName('task');
  useEffect(() => {
    setDescriptionHeight(getAutomaticInputHeight(refDescription, 50));
  }, [watchDescription]);

  useEffect(() => {
    if (formView === 'addView') {
      setValue('task', '');
      setValue('taskTile', '');
      setValue('description', '');
    }
  }, [watchPostToKanban]);

  const removeTaggedValue = (e) => {
    const { name: buttonName } = e.currentTarget;
    const removedValue = selectedTag.find((val) => val.value === buttonName);
    if (!removedValue) return;
    handleSelectedTags(selectedTag.filter((val) => val.value !== buttonName));
  };

  useEffect(() => {
    if (postValue) setValue('post', postValue, { shouldValidate: true });
  }, [postValue]);

  useEffect(() => {
    if (postDetail) {
      let postWallData = null;
      if (
        postDetail?.post_type === 'Task' &&
        postDetail?.wall_task &&
        Object.keys(postDetail?.wall_task).length
      ) {
        postWallData = { ...postDetail?.wall_task };
      }

      if (postDetail?.post_attachments?.length) {
        const fileDataValue = [];
        const fileDetail = [];
        postDetail?.post_attachments.forEach((attachment) => {
          fileDataValue.push(attachment?.file_name);
          fileDetail.push(attachment);
        });
        if (fileDataValue?.length) {
          setValue('wall_file', fileDataValue);
          setFileValue(fileDetail);
        }
      }
      if (postWallData) {
        setValue('postToKanban', postWallData.is_kanban_post);
        if (postWallData?.is_kanban_post) {
          setValue('taskTile', postWallData.task);
          setValue('description', postWallData.kanban_details.task_description);
        } else if (postWallData?.task) {
          setValue('task', postWallData.task);
        }

        let taggedEmployees = [];
        let assigneeList = [];

        if (postWallData?.assignees?.length) {
          assigneeList = postWallData?.assignees;
        }

        if (assigneeList?.length) {
          const filteredAssignees = listFormatter(
            assigneeList,
            (assigneeData) => ({
              id: assigneeData?.employee_id,
              label: assigneeData?.name || '',
              value: assigneeData?.name || ''
            })
          );
          setValue('assignees', filteredAssignees, { shouldValidate: true });
        }

        if (postDetail?.tagged_employees?.length) {
          // taggedEmployees = postDetail?.PostsTaggedEmployees;
          // if (['Curriculum'].includes(postDetail.post_type)) {
          taggedEmployees = postDetail?.tagged_employees.filter(
            (taggedEmployee) =>
              !assigneeList.some(
                (assigneeData) =>
                  taggedEmployee.employee_id === assigneeData?.employee_id
              )
          );
          // }
          const filteredTaggedEmployees = listFormatter(
            taggedEmployees,
            (taggedPerson) => ({
              id: taggedPerson.employee_id,
              label: taggedPerson?.name || '',
              value: taggedPerson?.name || ''
            })
          );
          handleSelectedTags(filteredTaggedEmployees);
        }
      }
    }
  }, [postDetail]);

  const resetForm = () => {
    reset({
      task: '',
      task_description: '',
      assignees: []
    });
    setSelectedTag(defaultTag || []);
    setDescriptionHeight(50);
  };

  const onSubmit = (data) => {
    const apiData = {
      body: {
        tagged_employee_id:
          formView === 'addView'
            ? defaultTag.map((tagedPerson) => tagedPerson.id)
            : [],
        // attachment: data.wall_file,
        task: data.task,
        wall_id: wallID
      }
    };

    apiData.body.is_kanban_post = watchPostToKanban || false;

    if (watchPostToKanban) {
      apiData.body.task_description = data.description;
      apiData.body.task = data.taskTile;
      if (formView === 'editView') {
        const kanbanDetails = postDetail?.wall_task?.kanban_details;
        if (kanbanDetails?.sprint_id)
          apiData.body.sprint_id = kanbanDetails?.sprint_id;
        if (kanbanDetails?.task_estimate)
          apiData.body.task_estimate = kanbanDetails?.task_estimate;
        if (kanbanDetails?.task_status_id)
          apiData.body.status_id = kanbanDetails?.task_status_id;
        if (kanbanDetails?.priority)
          apiData.body.priority = kanbanDetails?.priority;
        if (kanbanDetails?.start_date)
          apiData.body.start_date = kanbanDetails?.start_date;
        if (kanbanDetails?.due_date)
          apiData.body.due_date = kanbanDetails?.due_date;
      }
    }

    let actionCreator = null;

    if (formView === 'addView') {
      apiData.body.posted_by = authState.employee_id;
    } else if (formView === 'editView') {
      apiData.id = postDetail.post_id;
    }

    if (data?.taggedPeople) {
      let taggedEmployeeId = [];
      taggedEmployeeId = data.taggedPeople.map((tagedPerson) => tagedPerson.id);
      apiData.body.tagged_employee_id = taggedEmployeeId;
    }

    if (data?.assignees && data?.assignees?.length) {
      const assigneeList = data.assignees.map(
        (selectedData) => selectedData.id
      );
      if (assigneeList.length)
        apiData.body.tagged_employee_id = [
          ...new Set([...apiData.body.tagged_employee_id, ...assigneeList])
        ];
      apiData.body.assignees = assigneeList;
    }
    // const apiValue = {};
    const formData = new FormData();
    const formValue = convertToFormData(apiData.body);
    if (data.wall_file?.length) {
      data.wall_file.forEach((wallFile) => {
        if (wallFile instanceof File) formData.append('attachment', wallFile);
        else if (wallFile?.actualFile instanceof File)
          formData.append('attachment', wallFile?.actualFile);
      });
    }
    if (removedFileID?.length) {
      formData.append('remove_attachment', JSON.stringify(removedFileID));
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const pair of formValue.entries()) {
      formData.append(pair[0], pair[1]);
    }

    apiData.body = formData;
    if (formView === 'addView') {
      actionCreator = dispatch(saveTaskPostActionCreator(apiData));
    } else {
      actionCreator = dispatch(updateTaskActionCreator(apiData));
    }

    actionCreator.unwrap().then(() => {
      if (formView === 'addView') {
        resetForm();
        setResetFilePreview(!resetFilePreview);
        refreshPost();
      } else if (formView === 'editView') {
        handleClose(apiData);
        // navigate(`/report-view-Curriculum/${postDetail?.post_id}`);
      }
    });
  };

  const handleClick = () => {
    childRef.current.parentClick();
  };

  const onChangePostToKanban = (e) => {
    setValue('postToKanban', e.target.checked);
  };

  return (
    <div className="task-field-container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          {formView === 'addView' ? (
            <Col md={5} lg={4} xl={3}>
              <Controller
                name="post"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <SelectField
                    errors={errors.post}
                    onChange={onPostValueChange}
                    field={onChange}
                    value={postValue}
                    placeholder="Post Here"
                    options={postTypeList}
                    isMulti={false}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator
                    }}
                  />
                )}
              />
            </Col>
          ) : (
            ''
          )}
          <Col md={5}>
            <Controller
              name="assignees"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => (
                <SelectField
                  errors={errors.assignees}
                  options={assignees}
                  field={field}
                  isMulti
                  placeholder="Select Assignee"
                  formatOptionLabel={formatOptionLabel}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator
                  }}
                  label={formView === 'editView' ? 'Assignees' : ''}
                />
              )}
            />
          </Col>

          <Col md={4} className="align-self-end">
            {(watchPostToKanban || formView === 'addView') && (
              <Controller
                name="postToKanban"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <FormGroup check>
                    <InputField
                      errors={errors.postToKanban}
                      onChange={(e) => onChangePostToKanban(e)}
                      field={onChange}
                      label={
                        formView === 'addView'
                          ? 'Add to Kanban Backlog'
                          : 'Kanban task'
                      }
                      inputType="checkbox"
                      checked={value}
                      disabled={formView === 'editView'}
                    />
                  </FormGroup>
                )}
              />
            )}
          </Col>
        </Row>
        {watchPostToKanban && (
          <Row className="mt-3 pt-3">
            <Col md={8}>
              <Controller
                name="taskTile"
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /.*\S.*/,
                    message: 'Please enter valid text.'
                  }
                }}
                render={({ field }) => (
                  <InputField
                    errors={errors.taskTile}
                    className="text-area-field"
                    field={field}
                    name="taskTile"
                    inputType="text"
                    placeholder="Task Title"
                    label={formView === 'editView' ? 'Task Title' : ''}
                  />
                )}
              />
            </Col>
          </Row>
        )}
        <Row className="mt-3 pt-3">
          <Col>
            {watchPostToKanban && (
              <Controller
                name="description"
                control={control}
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <InputField
                    errors={errors.description}
                    className="text-area-field"
                    name="description"
                    style={{
                      height: `${descriptionHeight}px`
                    }}
                    inputType="textarea"
                    placeholder="Task Description..."
                    field={field}
                    label={formView === 'editView' ? 'Description' : ''}
                  />
                )}
              />
            )}
            {!watchPostToKanban && (
              <Controller
                name="task"
                control={control}
                rules={{
                  required: true,
                  pattern: {
                    value: /.*\S.*/,
                    message: 'Please enter valid text.'
                  }
                }}
                render={({ field }) => (
                  <InputField
                    errors={errors.task}
                    className="text-area-field"
                    field={field}
                    style={{
                      height: `${descriptionHeight}px`
                    }}
                    name="task"
                    inputType="textarea"
                    placeholder="Type something..."
                  />
                )}
              />
            )}
          </Col>
          <Col
            lg="auto"
            // className="d-flex align-items-start justify-content-center">
            className="d-flex align-items-end justify-content-center">
            <Button
              // className={formView === 'editView' ? 'mt-edit-btn ' : ''}
              outline
              type="button"
              onClick={handleClick}
              color="primary">
              <i className="bi bi-upload " /> Upload File
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Controller
            name="wall_file[]"
            control={control}
            rules={{}}
            render={({ field: { onChange } }) => (
              <FileUpload
                errors={errors.wall_file}
                field={onChange}
                setError={setError}
                clearErrors={clearErrors}
                setValue={setValue}
                fieldColumn="wall_file"
                fileValue={fileValue}
                setRemovedFileID={setRemovedFileID}
                showPreview={false}
                ref={childRef}
                reset={resetFilePreview}
                isMulti
                handleClickFromParent
              />
            )}
          />
        </Row>
        <Row className="mt-3 ">
          {formView === 'addView' ? (
            <Col
              md="auto"
              className="d-flex align-items-start justify-content-center">
              <Button color="primary" disabled={btnLoader}>
                Post
              </Button>
            </Col>
          ) : (
            ''
          )}
          <Col md={3} className="tp-head-col">
            <div>
              <Controller
                name="taggedPeople"
                control={control}
                rules={{
                  required: false
                }}
                render={({ field: { onChange } }) => (
                  <TagPeople
                    errors={errors.taggedPeople}
                    onChange={handleSelectedTags}
                    field={onChange}
                    isMulti
                    options={users}
                    value={selectedTag}
                    setSelected={setSelectedTag}
                  />
                )}
              />
            </div>
          </Col>
          <Col className="tag-people-col align-self-center">
            {selectedTag.map((val) => (
              <Badge key={val.value} className="tagged-badge me-1">
                {val.label}

                <Button
                  className="tagged-close"
                  name={val.value}
                  onClick={removeTaggedValue}>
                  <i className="bi bi-x-circle tag-cls-btn" />
                </Button>
              </Badge>
            ))}
          </Col>
        </Row>

        {formView === 'editView' ? (
          <Row className="btn-container mt-4">
            <Col md="auto">
              <Button
                outline
                color="primary"
                disabled={btnLoader}
                onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
            <Col md="auto">
              <Button color="primary" disabled={btnLoader}>
                Update
              </Button>
            </Col>
          </Row>
        ) : (
          ''
        )}
      </form>
    </div>
  );
}

export default WallTaskField;
