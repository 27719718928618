import React from 'react';
import { Label } from 'reactstrap';
import Select from 'react-select';
import { validationMsg } from '../../helper/commonFunction';
import './SelectField.scss';

const customStyles = {};

function SelectField({
  field,
  label,
  options,
  isMulti,
  errors,
  onChange,
  disabled,
  components,
  styles = customStyles,
  value = '',
  isClearable = false,
  controlShouldRenderValue = true,
  setSelected,
  className,
  placeholder,
  classNamePrefix = 'selectFieldCustom',
  selectAll = false,
  formatOptionLabel = null
}) {
  const setValue = () => {
    let returnValue = '';
    if (field?.value) {
      returnValue = field?.value;
    } else if (field?.field?.value) {
      returnValue = field?.field?.value;
    } else {
      returnValue = value;
    }
    if (selectAll && returnValue.find((option) => option.value === '*')) {
      returnValue = options;
    }
    return returnValue;
  };
  return (
    <>
      {label && (
        <Label className="font-semi-bold" for={label}>
          {label}
        </Label>
      )}
      <Select
        styles={styles}
        options={
          selectAll
            ? [{ label: 'Select All', value: '*' }, ...options]
            : options
        }
        onChange={onChange}
        {...field}
        isMulti={isMulti}
        placeholder={placeholder}
        isDisabled={disabled}
        components={components}
        isClearable={isClearable}
        controlShouldRenderValue={controlShouldRenderValue}
        value={setValue()}
        setSelected={setSelected}
        className={`selectFieldCustomWrapper font-regular ${className || ''}`}
        classNamePrefix={classNamePrefix}
        getOptionValue={(option) => `${option.id}`}
        formatOptionLabel={formatOptionLabel}
        noOptionsMessage={() => 'No Options Available'}
      />
      {/* {errors && <p className="input-field-error pt-2 mb-0">This field is required</p>} */}
      {errors && (
        <p className="input-field-error pt-2 mb-0 font-regular">
          {validationMsg(errors)}
        </p>
      )}
    </>
  );
}

export default SelectField;
