import { useEffect } from 'react';

function FrameBusting() {
  useEffect(() => {
    if (window.self !== window.top) {
      // We're in an iframe, display a message and then break out of it
      const message = document.createElement('div');
      message.textContent = 'This content cannot be displayed in an iframe.';
      document.body.appendChild(message);
      window.top.location = window.self.location;
    }
  }, []);

  return null; // This component doesn't render anything
}

export default FrameBusting;
