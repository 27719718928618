import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';

const initialState = {
  projectsList: [],
  loading: false,
  error: ''
};

export const getProjectsListActionCreator = createAsyncThunk(
  'project/list',
  async (params) => {
    let API_URL = `${SERVER_URL.PROJECT_LIST}`;
    if (params?.wallID) API_URL += `/${params?.wallID}`;
    const { data } = await fetchUtility('get', `${API_URL}`, {});
    return data;
  }
);

export const saveProjectActionCreator = createAsyncThunk(
  'project/save',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.PROJECT_CREATE}`,
      params
    );
    return { data };
  }
);

export const updateProjectActionCreator = createAsyncThunk(
  'project/update',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.PROJECT_UPDATE}${params.project_id}`,
      params.body
    );
    return { data };
  }
);

export const deleteProjectActionCreator = createAsyncThunk(
  'project/delete',
  async (id) => {
    const { data } = await fetchUtility(
      'delete',
      `${SERVER_URL.PROJECT_DELETE}${id}`
    );
    return { data, insertedData: { project_id: id } };
  }
);

export const getProjectDetailActionCreator = createAsyncThunk(
  'project/detail',
  async (id) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.PROJECT_DETAIL}${id}`
    );
    return data.result;
  }
);

const projectsSlice = createSlice({
  name: 'project',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getProjectsListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProjectsListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.projectsList = action.payload.result;
      state.error = '';
    });
    builder.addCase(getProjectsListActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(saveProjectActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveProjectActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(saveProjectActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateProjectActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateProjectActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(updateProjectActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(deleteProjectActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteProjectActionCreator.fulfilled, (state) => {
      // state.projectsList = state.projectsList.filter(
      //   (project) =>
      //     project.project_id !== action.payload.insertedData.project_id
      // );
      state.loading = false;
      state.error = '';
    });
    builder.addCase(deleteProjectActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getProjectDetailActionCreator.pending, (state) => {
      // state.projectsList = [];
      state.loading = true;
    });
    builder.addCase(
      getProjectDetailActionCreator.fulfilled,
      (state, action) => {
        state.projectsList = action.payload.result;
        state.loading = false;
        state.error = '';
      }
    );
    builder.addCase(getProjectDetailActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  }
});

export default projectsSlice.reducer;
