import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';
import { weekDataFormatter } from '../../shared/helper/commonDataFormatter';

const initialState = {
  error: '',
  leaderboardData: [],
  weekListData: [],
  loading: false
};

export const getMentorLeaderboardListActionCreator = createAsyncThunk(
  'mentorLeaderboard/fetchMentorLeaderboard',
  async (date) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.MENTOR_LEADERBOARD_LIST}${date}`
    );
    return data;
  }
);

export const getWeekListActionCreator = createAsyncThunk(
  'mentorLeaderboard/fetchWeekListData',
  async () => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.MENTOR_LEADERBOARD_DATES_LIST}`
    );
    return data;
  }
);

// Create the mentorLeaderboardSlice
const mentorLeaderboardSlice = createSlice({
  name: 'mentorLeaderboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMentorLeaderboardListActionCreator.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        getMentorLeaderboardListActionCreator.fulfilled,
        (state, action) => {
          state.loading = false;
          state.leaderboardData = action.payload.result;
        }
      )
      .addCase(
        getMentorLeaderboardListActionCreator.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.code;
        }
      );

    builder
      .addCase(getWeekListActionCreator.pending, (state) => {
        state.loading = true;
      })
      .addCase(getWeekListActionCreator.fulfilled, (state, action) => {
        state.loading = false;
        state.weekListData = weekDataFormatter(action.payload.result);
      })
      .addCase(getWeekListActionCreator.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.code;
      });
  }
});

// Export the reducer
export default mentorLeaderboardSlice.reducer;
