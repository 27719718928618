import './Loader.scss';
import { MoonLoader } from 'react-spinners';

function Loader({ show }) {
  return (
    <div className={show ? 'd-block' : 'd-none'}>
      <div className="loader">
        <MoonLoader loading />
      </div>
      <div className="overlay" />
    </div>
  );
}
export default Loader;
