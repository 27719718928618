import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
  Tooltip
} from 'reactstrap';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import Avatar from 'react-avatar';
import CustomModal from '../../components/modal/Modal';
import WallPostForms from '../../../pages/wall/WallPostForms';
import DeleteWallPost from '../../../pages/wall/DeleteWallPost';
import Likes from '../likes/Likes';
import Comments from '../comments/Comments';
import Pin from '../pin/Pin';
import Comment from '../comment/Comment';
import './WallCurriculum.scss';
import { UserComments } from '../comment/CommentView';
import {
  attachmentPreview,
  filterLinks,
  onViewProfile,
  timeago
} from '../../helper/commonFunction';
import TaggedEmployees from '../taggedEmployeeList/TaggedEmployees';

function WallCurriculum({
  postData,
  getPostList,
  wallActiveStatus,
  copyToClipboard,
  hideActions = false
}) {
  const [showModal, setShowModal] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [popupView, setPopupView] = useState(null);
  const [addComment, setAddComment] = useState(false);
  const [isPostUpdated, setIsPostUpdated] = useState(false);
  const authState = useSelector((state) => state.login.loginDetails);
  const isWallMember = useSelector((state) => state.post.isWallMember);
  const [taggedEmployeesList, setTaggedEmployeesList] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);

  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  const getTaggedPeople = () => {
    if (postData?.tagged_employees?.length) {
      const taggedEmployees = postData?.tagged_employees.filter(
        (taggedEmployee) =>
          !postData?.wall_curriculum?.assignees.some(
            (assigneeData) =>
              taggedEmployee.employee_id === assigneeData?.employee_id
          )
      );
      setTaggedEmployeesList(taggedEmployees);
    }
  };

  useEffect(() => {
    if (postData && postData?.wall_curriculum?.assignees?.length) {
      getTaggedPeople();
      if (postData?.created_at !== postData?.post_order) {
        setIsPostUpdated(true);
      } else {
        setIsPostUpdated(false);
      }
    }
  }, [postData]);

  const openComments = () => {
    setIsOpen(!isOpen);
  };

  const onClickEditHandler = () => {
    setModalHeader('Edit Wall Curriculum');
    setPopupView('editView');
    setShowModal(true);
  };
  const onClickDeleteHandler = () => {
    setModalHeader('Delete Wall Curriculum');
    setPopupView('deleteView');
    setShowModal(true);
  };

  const handleClose = (result) => {
    if (result) {
      getPostList(true);
    }
    setShowModal(false);
    setPopupView(null);
  };

  return (
    <div className="curriculum-container">
      <div className="col-sm-12 mt-5">
        <CustomModal
          body={
            (['editView'].includes(popupView) && (
              <WallPostForms
                handleClose={handleClose}
                postData={postData}
                formView={popupView}
              />
            )) ||
            (popupView === 'deleteView' && (
              <DeleteWallPost
                handleClose={handleClose}
                postData={postData}
                getPostList={getPostList}
              />
            ))
          }
          showModal={showModal}
          heading={modalHeader}
          setShowModal={setShowModal}
          size="xl"
        />
        <Card className="wall-card">
          <CardHeader className="p-0">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                {postData?.post_creator_profile_image ? (
                  <img
                    className="user-image rounded-circle m-auto"
                    src={postData?.post_creator_profile_image}
                    alt="profilePic"
                  />
                ) : (
                  <Avatar
                    className="m-auto"
                    name={postData?.post_creator?.name}
                    // round="20px"
                    round
                    maxInitials={3}
                    size="38"
                  />
                )}
                <div className="px-3 d-flex flex-column justify-content-center">
                  <span
                    className="fs-6 font-bold cursor-pointer"
                    role="presentation"
                    onClick={() =>
                      onViewProfile(
                        postData?.post_creator?.employee_id,
                        navigate
                      )
                    }>
                    {postData?.post_creator?.name}
                  </span>
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <span className="text-sub font-regular time-text">
                    {isPostUpdated && 'Updated '}
                    {timeago(
                      isPostUpdated
                        ? postData?.post_order
                        : postData?.created_at
                    )}
                  </span>
                </div>
              </div>
              {postData?.wall_name && (
                <div className="d-flex flex-column justify-content-cente justify-content-evenlyr">
                  <span className="text-sub font-semi-bold text-primary wall-name-tag mx-2">
                    {postData?.wall_name}
                  </span>
                </div>
              )}
              {(authState.role === '0' &&
                postData.allowed_like_and_comment === 1) ||
              hideActions ? (
                ''
              ) : (
                <div className="d-flex align-items-center justify-content-evenly">
                  <Pin
                    postID={postData.post_id}
                    isPin={postData.is_pinned}
                    getPostList={getPostList}
                  />
                  <UncontrolledDropdown>
                    <DropdownToggle
                      nav
                      className="d-flex align-items-center p-0">
                      <span className="col-1 ms-2 pt-2 ps-1">
                        <i className="bi bi-three-dots text-dark fs-4" />
                      </span>
                    </DropdownToggle>
                    <DropdownMenu end>
                      {authState?.employee_id ===
                        postData?.post_creator?.employee_id &&
                        wallActiveStatus === 1 && (
                          <>
                            <DropdownItem onClick={onClickEditHandler}>
                              Edit
                            </DropdownItem>
                            <DropdownItem onClick={onClickDeleteHandler}>
                              Delete
                            </DropdownItem>
                          </>
                        )}
                      <DropdownItem
                        onClick={() => copyToClipboard(postData?.post_id)}>
                        Copy Link
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )}
            </div>
          </CardHeader>
          <div className="content-container mt-2">
            <CardBody className="py-0 px-0">
              <div>
                <div style={{ whiteSpace: 'pre-line' }}>
                  <div>
                    <p className="font-regular card-text-content mb-2">
                      {postData?.wall_curriculum?.curriculum?.curriculum_name}
                    </p>

                    <span
                      className="description-content card-text-content font-regular"
                      style={{ whiteSpace: 'pre-line' }}
                      // eslint-disable-next-line react/no-danger
                      dangerouslySetInnerHTML={{
                        __html: filterLinks(
                          postData?.wall_curriculum?.description
                        )
                      }}
                    />
                  </div>
                  <div className="status-bar mt-3">
                    <div>
                      <h6 className="text-primary font-semi-bold mb-0">
                        Progress
                      </h6>
                    </div>
                    {postData?.wall_curriculum?.assignees?.map(
                      (data, index) => (
                        <div key={data.id}>
                          <div className="d-flex justify-content-center gap-3 pt-2 ">
                            <span className="assignee-data me-auto mb-2">
                              {data?.profile_image ? (
                                <img
                                  className="user-image rounded-circle"
                                  src={data?.profile_image}
                                  alt="profilePic"
                                />
                              ) : (
                                <Avatar
                                  name={data?.name}
                                  round
                                  maxInitials={3}
                                  size="38"
                                />
                              )}
                              <span
                                className="px-3 my-auto font-regular cursor-pointer"
                                role="presentation"
                                onClick={() =>
                                  onViewProfile(data?.employee_id, navigate)
                                }>
                                {data?.name}
                              </span>
                            </span>
                            <div style={{ width: 35, height: 40 }}>
                              <CircularProgressbar
                                value={data?.percentage_of_completion || 0}
                                text={`${data?.percentage_of_completion || 0}%`}
                                strokeWidth={7}
                                styles={buildStyles({
                                  textColor: '#e75256',
                                  pathColor: '#e75256',
                                  trailColor: 'grey',
                                  textSize: '28px'
                                })}
                              />
                            </div>
                            <div>
                              {!hideActions && (
                                <Button
                                  onClick={() =>
                                    navigate(
                                      `/report-view-Curriculum/${postData?.post_id}/${data?.employee_id}`
                                    )
                                  }
                                  color="primary back-btn">
                                  View
                                </Button>
                              )}
                            </div>
                          </div>
                          {index + 1 !==
                            postData?.wall_curriculum?.assignees.length && (
                            <hr className="m-0" />
                          )}
                        </div>
                      )
                    )}
                  </div>
                </div>
                {postData.post_attachments.length > 0 &&
                  attachmentPreview(postData.post_attachments)}
              </div>
            </CardBody>
            {authState.role === '0' &&
            postData.allowed_like_and_comment === 1 &&
            postData.comment_count === 0 &&
            !isPostUpdated &&
            taggedEmployeesList?.length === 0 ? (
              ''
            ) : (
              <CardFooter className="d-flex flex-wrap gap-3 px-0 py-0 mt-3">
                <div className="d-flex flex-grow-1">
                  {!hideActions && (isWallMember || authState.role === '0') ? (
                    <div className="d-flex gap-3">
                      {authState.role === '0' &&
                      postData.allowed_like_and_comment === 1
                        ? ''
                        : wallActiveStatus === 1 && (
                            <>
                              <div>
                                <Likes likeDetails={postData} />
                              </div>
                              <div
                                id={`post-${postData.post_id}-cmt`}
                                aria-hidden="true"
                                role="button"
                                onClick={() => setAddComment(!addComment)}>
                                <i className="bi bi-chat-left-fill text-primary" />
                                <span className="px-2 text-sub font-regular hide-text">
                                  {' '}
                                  Comment
                                </span>
                                <Tooltip
                                  placement="top"
                                  isOpen={tooltipOpen}
                                  toggle={toggle}
                                  autohide={false}
                                  target={`post-${postData.post_id}-cmt`}>
                                  Post a comment
                                </Tooltip>
                              </div>
                            </>
                          )}
                      {postData.comment_count > 0 ? (
                        <div
                          aria-hidden="true"
                          role="button"
                          onClick={openComments}>
                          <Comments commentDetails={postData} />
                        </div>
                      ) : (
                        ''
                      )}
                      &nbsp;
                    </div>
                  ) : (
                    ''
                  )}
                  {taggedEmployeesList?.length > 0 && (
                    <TaggedEmployees taggedPeople={taggedEmployeesList} />
                  )}
                </div>
                {isPostUpdated && (
                  <div className="flex-grow-1 text-end">
                    <span className="text-sub font-regular time-text">
                      {'Created at: '}
                      {timeago(postData?.created_at)}
                    </span>
                  </div>
                )}
              </CardFooter>
            )}
            <div className="comments-container mt-2">
              {addComment && (
                <div className="m-4">
                  <Comment postData={postData} getPostList={getPostList} />
                </div>
              )}
              {isOpen && (
                <div className="">
                  <UserComments
                    postID={postData.post_id}
                    parentId={postData.parent_comment_id}
                    getPostList={getPostList}
                    isView={postData.allowed_like_and_comment}
                  />
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default WallCurriculum;
