import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';

const initialState = {
  assignMenteeList: [],
  loading: false,
  btnLoader: false,
  status: '',
  statusCode: null,
  error: ''
};

export const getAssignMenteeListActionCreator = createAsyncThunk(
  'assignMentee/list',
  async () => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.ASSIGN_MENTEE_LIST}`
    );
    return data;
  }
);

export const saveAssignMenteeActionCreator = createAsyncThunk(
  'assignMentee/save',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.ASSIGN_MENTEE_CREATE}`,
      params.body
    );
    return data;
  }
);

export const deleteAssignMenteeActionCreator = createAsyncThunk(
  'assignMentee/delete',
  async (id) => {
    const { data } = await fetchUtility(
      'delete',
      `${SERVER_URL.ASSIGN_MENTEE_DELETE}${id}`
    );
    return data;
  }
);

export const updateAssignMenteeActionCreator = createAsyncThunk(
  'assignMentee/update',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.ASSIGN_MENTEE_UPDATE}`,
      params.body
    );
    return data;
  }
);

const assignMenteeSlice = createSlice({
  name: 'assignMenteeSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getAssignMenteeListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAssignMenteeListActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.assignMenteeList = action.payload.result;
        state.error = '';
      }
    );
    builder.addCase(
      getAssignMenteeListActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(saveAssignMenteeActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(saveAssignMenteeActionCreator.fulfilled, (state) => {
      // state.assignMenteeList.unshift(action.payload);
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(saveAssignMenteeActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(updateAssignMenteeActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateAssignMenteeActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(
      updateAssignMenteeActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(deleteAssignMenteeActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(deleteAssignMenteeActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(
      deleteAssignMenteeActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );
  }
});

export default assignMenteeSlice.reducer;
