import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';

const initialState = {
  assignWallList: [],
  assignWallDetails: null,
  architectList: [],
  designerList: [],
  mentorList: [],
  menteeList: [],
  wallState: 'ViewWall',
  loading: false,
  combinedLoading: false,
  btnLoader: false,
  status: '',
  statusCode: null,
  error: ''
};

export const getUsersCombinedListActionCreator = createAsyncThunk(
  'users/combinedlist',
  async () => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.ASSIGN_WALL_JOIN_LIST}`
    );
    return data;
  }
);
export const createAssignWallActionCreator = createAsyncThunk(
  'assignWall/save',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.ASSIGN_WALL_CREATE}`,
      params.body
    );
    return data;
  }
);
export const getAssignWallListActionCreator = createAsyncThunk(
  'assignWall/assignWalllist',
  async () => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.ASSIGN_WALL_LIST}`
    );
    return data;
  }
);
export const deleteAssignWallActionCreator = createAsyncThunk(
  'assignWall/delete',
  async (id) => {
    const { data } = await fetchUtility(
      'delete',
      `${SERVER_URL.ASSIGN_WALL_DELETE}${id}`
    );
    return data;
  }
);
export const updateAssignWallActionCreator = createAsyncThunk(
  'assignWall/update',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.ASSIGN_WALL_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);
export const getAssignWallActionCreator = createAsyncThunk(
  'assignWall/detail',
  async (id) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.ASSIGN_WALL_DETAILS}${id}`
    );
    return data;
  }
);
export const assignWallStatusChangeActionCreator = createAsyncThunk(
  'assignWall/statusChange',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.ASSIGN_WALL_SATUS_CHANGE}${params.assign_wall_id}`,
      params.body
    );
    return data;
  }
);

const assignWallSlice = createSlice({
  name: 'assignWall',
  initialState,
  reducers: {
    assignWall: (state, { payload }) => {
      state.wallState = payload.wallState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUsersCombinedListActionCreator.pending, (state) => {
      state.loading = true;
      state.combinedLoading = true;
    });
    builder.addCase(
      getUsersCombinedListActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.combinedLoading = false;
        state.designerList = action.payload.result.designers;
        state.architectList = action.payload.result.architects;
        state.mentorList = action.payload.result.mentors;
        state.menteeList = action.payload.result.mentees;
        state.error = '';
      }
    );
    builder.addCase(
      getUsersCombinedListActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.combinedLoading = false;
        state.error = action.error.code;
      }
    );

    builder.addCase(createAssignWallActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(createAssignWallActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(createAssignWallActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(getAssignWallListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getAssignWallListActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.assignWallList = action?.payload?.result?.map((list) => {
          if (!list.Architect)
            list.Architect = { name: 'N/A', employee_id: null };
          if (!list.Designer)
            list.Designer = { name: 'N/A', employee_id: null };
          return list;
        });
        state.error = '';
      }
    );
    builder.addCase(
      getAssignWallListActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.code;
      }
    );

    builder.addCase(deleteAssignWallActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(deleteAssignWallActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(deleteAssignWallActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(getAssignWallActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(getAssignWallActionCreator.fulfilled, (state, action) => {
      if (action.payload.result)
        state.assignWallDetails = { ...action.payload.result };
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(getAssignWallActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(updateAssignWallActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateAssignWallActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updateAssignWallActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(assignWallStatusChangeActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(assignWallStatusChangeActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(
      assignWallStatusChangeActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );
  }
});

export const { assignWall } = assignWallSlice.actions;
export default assignWallSlice.reducer;
