import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Input,
  CardHeader,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col
} from 'reactstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';
import './CommentView.scss';
import { useDispatch, useSelector } from 'react-redux';
import Avatar from 'react-avatar';
import { getCommentsParentListActionCreator } from '../../../redux/slices/CommetsSlice';
import Likes from '../likes/Likes';
import {
  getMyNotificationListActionCreator,
  saveCommentActionCreator
} from '../../../redux/slices/PostSlice';
import CustomModal from '../../components/modal/Modal';
import CommentForms from './CommentForms';
import {
  convertToFormData,
  filterLinks,
  timeago,
  attachmentPreview,
  onViewProfile
} from '../../helper/commonFunction';
import FileUpload from '../../components/file-upload/FileUpload';

export function RecursiveComment({
  userComment,
  index,
  getPostList,
  isView,
  disabledReplyAndLike,
  updateCommentsCountHandler
}) {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [viewChildComment, setviewChildComment] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [heading, setHeading] = useState('');
  const [view, setView] = useState('');
  const pattern = /.*\S.*/;
  const authState = useSelector((state) => state.login.loginDetails);
  const { wallID } = useParams();
  const navigate = useNavigate();

  const openComments = () => {
    setOpen(!open);
  };

  const {
    control,
    watch,
    setError,
    clearErrors,
    handleSubmit,
    setValue,
    formState: { errors },
    reset
  } = useForm();

  const [resetFilePreview, setResetFilePreview] = useState();
  const childRef = useRef();

  const reloadChild = () => {
    const reqBody = {};
    reqBody.postID = userComment.post_id;
    reqBody.parentID = userComment.comment_id || 0;
    dispatch(getCommentsParentListActionCreator(reqBody));
  };
  const reloadParent = () => {
    const reqBody = {};
    reqBody.postID = userComment.post_id;
    reqBody.parentID = userComment.parent_comment_id || 0;
    dispatch(getCommentsParentListActionCreator(reqBody)).then(() => {
      if (view !== 'commentsDelete') reloadChild();
    });
    dispatch(getMyNotificationListActionCreator(wallID));
  };

  const onSubmit = (data) => {
    setOpen(!open);
    setView('commentsAdd');
    const request = {
      body: {
        post_id: userComment.post_id,
        comment: data.reply,
        parent_comment_id: userComment.comment_id
      }
    };

    const formData = new FormData();
    const formValue = convertToFormData(request.body);
    if (data.wall_file?.length) {
      data.wall_file.forEach((wallFile) => {
        if (wallFile instanceof File) formData.append('attachment', wallFile);
        else if (wallFile?.actualFile instanceof File)
          formData.append('attachment', wallFile?.actualFile);
      });
    }

    // eslint-disable-next-line no-restricted-syntax
    for (const pair of formValue.entries()) {
      formData.append(pair[0], pair[1]);
    }

    request.body = formData;

    dispatch(saveCommentActionCreator(request))
      .unwrap()
      .then(() => {
        setResetFilePreview(!resetFilePreview);
        reset({
          reply: ''
        });
        getPostList(true);
        reloadParent();
        if (updateCommentsCountHandler) {
          updateCommentsCountHandler(1);
        }
      });
  };

  const getChildComment = () => {
    setviewChildComment(!viewChildComment);
  };
  const editComments = () => {
    if (userComment?.parent_comment_id) {
      setHeading('Edit Reply');
    } else {
      setHeading('Edit Comment');
    }
    setView('commentsEdit');
    setShowModal(true);
  };
  const deleteComments = () => {
    setHeading('Delete Comment');
    setView('commentsDelete');
    setShowModal(true);
  };
  const handleClose = (data) => {
    if (data) {
      if (view === 'commentsDelete') {
        const superParentId = data?.result?.grand_parent_comment_id;
        const reqBody = {};
        reqBody.postID = userComment.post_id;
        reqBody.parentID = superParentId || 0;
        dispatch(getCommentsParentListActionCreator(reqBody));
      }
      getPostList(true);
      reloadParent();
    }
    setShowModal(false);
  };

  const handleClick = () => {
    childRef.current.parentClick();
  };

  const refreshForm = () => {
    setResetFilePreview(!resetFilePreview);
    reset({
      reply: ''
    });
  };

  return (
    <div>
      <div className="d-flex my-3">
        <div className=" w-100">
          <Card className="border-0 no-bg mb-1">
            <CardHeader className="no-bg p-0">
              <div className="d-flex justify-content-between">
                <div className="d-flex ps-3 ">
                  {userComment?.commentBy?.UserProfiles[0]?.profile_image ? (
                    <img
                      className="user-image rounded-circle m-auto"
                      src={
                        userComment?.commentBy?.UserProfiles[0]?.profile_image
                      }
                      alt="profilePic"
                    />
                  ) : (
                    <Avatar
                      className="m-auto"
                      name={userComment?.commentBy?.name}
                      round
                      maxInitials={3}
                      size="38"
                    />
                  )}
                  <div className="px-3 d-flex flex-column justify-content-center">
                    <span
                      className="fs-6 font-bold cursor-pointer"
                      role="presentation"
                      onClick={() =>
                        onViewProfile(
                          userComment?.commentBy?.employee_id,
                          navigate
                        )
                      }>
                      {userComment?.commentBy?.name}
                    </span>
                  </div>
                  <div className="d-flex flex-column justify-content-center">
                    <span className="text-sub font-regular">
                      {timeago(userComment?.created_at)}
                    </span>
                  </div>
                </div>
                {authState.role === '0' && isView === 1
                  ? ''
                  : authState.employee_id ===
                      userComment?.commentBy?.employee_id && (
                      <div className=" d-flex">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            nav
                            className="d-flex align-items-center p-0">
                            <span className="col-1 ms-2 pt-2 ps-1 pe-3">
                              <i className="bi bi-three-dots dots-clr fs-4" />
                            </span>
                          </DropdownToggle>
                          <DropdownMenu end>
                            <DropdownItem onClick={editComments}>
                              <span className="text-sub">
                                <i className="bi bi-pencil-square" /> Edit
                              </span>
                            </DropdownItem>
                            <DropdownItem onClick={deleteComments}>
                              <span className="text-sub">
                                <i className="bi bi-trash" /> Delete
                              </span>
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                    )}
              </div>
            </CardHeader>
            <div className="content-container px-3">
              <div className="msg">
                <span
                  className="comment-text font-regular"
                  style={{ whiteSpace: 'pre-line' }}
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: filterLinks(userComment?.comment)
                  }}
                />
                {userComment.attachments.length > 0 &&
                  attachmentPreview(userComment.attachments)}
              </div>
              <div className="d-flex mt-2 align-items-center">
                {authState.role === '0' && isView === 1
                  ? ''
                  : !disabledReplyAndLike && (
                      <>
                        <Likes likeDetails={userComment} likeType="comment" />
                        <div
                          className="mx-2 text-sub font-regular"
                          aria-hidden="true"
                          role="button"
                          onClick={openComments}>
                          Reply
                        </div>
                      </>
                    )}

                {userComment?.child_comment_count !== 0 ? (
                  <div
                    className="mx-2 text-sub font-regular"
                    aria-hidden="true"
                    role="button"
                    onClick={() => getChildComment(userComment?.comment_id)}>
                    <div className="div-rply-icon">
                      <i className="bi bi-reply-fill " />
                    </div>
                    &nbsp; {userComment?.child_comment_count}{' '}
                    <span>Replies</span>
                  </div>
                ) : (
                  ''
                )}
              </div>
              {open && (
                <div className="add-comment card-background ms-5 mt-3">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Controller
                      name="reply"
                      control={control}
                      rules={{
                        required: true
                      }}
                      render={({ field }) => (
                        <Input
                          className="input-text-area border-0 p-1 font-regular"
                          errors={errors.comment}
                          name="reply"
                          type="textarea"
                          rows="2"
                          placeholder="Type here...."
                          {...field}
                        />
                      )}
                    />
                    <Controller
                      name="wall_file[]"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <FileUpload
                          errors={errors.wall_file}
                          field={onChange}
                          setError={setError}
                          clearErrors={clearErrors}
                          setValue={setValue}
                          fieldColumn="wall_file"
                          showPreview={false}
                          reset={resetFilePreview}
                          ref={childRef}
                          isMulti
                          handleClickFromParent
                          // options={users}
                          // setSelected={setSelectedTag}
                        />
                      )}
                    />

                    {/* <Button color="light" className="rotate-btn">
                      <i className="bi bi-send fs-4 text-primary" />
                    </Button> */}
                    <Row>
                      <Col sm="auto">
                        {watch('reply') && pattern.test(watch('reply')) && (
                          <Button
                            outline
                            type="button"
                            onClick={refreshForm}
                            color="primary">
                            Cancel
                          </Button>
                        )}
                      </Col>
                      <Col sm="auto">
                        {watch('reply') && pattern.test(watch('reply')) && (
                          <Button
                            outline
                            type="button"
                            onClick={handleClick}
                            color="primary">
                            <i className="bi bi-upload " /> Upload File
                          </Button>
                        )}
                      </Col>
                      <Col sm="auto">
                        {watch('reply') && pattern.test(watch('reply')) && (
                          <Button color="primary">Post</Button>
                        )}
                      </Col>
                    </Row>
                  </form>
                </div>
              )}
              {viewChildComment && (
                <div className="child-wrap mt-2">
                  <UserComments
                    key={`${userComment.comment_id}_${index}`}
                    postID={userComment.post_id}
                    parentID={userComment.comment_id}
                    isRecurssive
                    getPostList={getPostList}
                    isView={isView}
                  />
                </div>
              )}
            </div>
          </Card>
        </div>
      </div>

      <CustomModal
        body={
          <CommentForms
            handleClose={handleClose}
            commentsData={userComment}
            view={view}
            updateCommentsCountHandler={updateCommentsCountHandler}
          />
        }
        showModal={showModal}
        heading={heading}
        setShowModal={setShowModal}
        size="xl"
      />
    </div>
  );
}

export function UserComments({
  postID,
  parentID,
  isRecurssive = false,
  getPostList,
  isView,
  disabledReplyAndLike,
  updateCommentsCountHandler
}) {
  const [filteredReplyList, setfilteredReplyList] = useState([]);

  const commentsList = useSelector((store) => store.comments.commentsList);
  const replyLists = useSelector((store) => store.comments.replyLists);

  useEffect(() => {
    if (isRecurssive) {
      if (replyLists?.length && parentID) {
        const postReplies = replyLists.find((item) => item.postID === postID);
        const filtered = postReplies?.[parentID];
        if (filtered) setfilteredReplyList(filtered);
      }
    }
  }, [replyLists]);
  const dispatch = useDispatch();

  const getCommentList = () => {
    const reqBody = {};
    reqBody.postID = postID;
    reqBody.parentID = parentID || 0;

    dispatch(getCommentsParentListActionCreator(reqBody));
  };

  useEffect(() => {
    getCommentList();
  }, []);
  return (
    <div className=" comment-wrap ">
      <div className="list">
        {!isRecurssive && commentsList[postID]?.length
          ? commentsList[postID].map((userComment, index) => (
              <RecursiveComment
                key={userComment.comment_id}
                userComment={userComment}
                index={index}
                getPostList={getPostList}
                isView={isView}
                disabledReplyAndLike={disabledReplyAndLike}
                updateCommentsCountHandler={updateCommentsCountHandler}
              />
            ))
          : ''}

        {isRecurssive && filteredReplyList?.length
          ? filteredReplyList.map((userComment, index) => (
              <RecursiveComment
                key={userComment.comment_id}
                userComment={userComment}
                index={index}
                getPostList={getPostList}
                isView={isView}
                disabledReplyAndLike={disabledReplyAndLike}
                updateCommentsCountHandler={updateCommentsCountHandler}
              />
            ))
          : ''}
      </div>
    </div>
  );
}
