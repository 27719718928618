import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';
import {
  locationFormatter,
  streamFormatter,
  qualificationFormatter,
  spaceStreamFormatter
} from '../../shared/helper/commonDataFormatter';

const initialState = {
  streamList: [],
  spaceStreamList: [],
  qualificationList: [],
  joinLocationList: [],
  teamList: [],
  status: '',
  statusCode: null,
  error: '',
  isLoading: false
};

export const getStreamListActionCreator = createAsyncThunk(
  'commonSlice/streamList',
  async () => {
    const { data } = await fetchUtility('get', `${SERVER_URL.STREAM_LIST}`);
    return data;
  }
);

export const getSpaceStreamListActionCreator = createAsyncThunk(
  'commonSlice/spaceStreamList',
  async () => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.SPACE_STREAM_LIST}`
    );
    return data;
  }
);

export const getQualificationListActionCreator = createAsyncThunk(
  'commonSlice/qualificationList',
  async () => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.QUALIFICATION_LIST}`
    );
    return data;
  }
);

export const getJoinLocationListActionCreator = createAsyncThunk(
  'commonSlice/joinLocationList',
  async () => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.JOIN_LOCATION_LIST}`
    );
    return data;
  }
);

export const getTeamListActionCreator = createAsyncThunk(
  'mentor-mentee-mapping-list',
  async () => {
    const { data } = await fetchUtility('get', `${SERVER_URL.USERS_LIST}`);
    return data;
  }
);

const commonSlice = createSlice({
  name: 'commonSlice',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getStreamListActionCreator.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getStreamListActionCreator.fulfilled, (state, action) => {
      state.isLoading = false;
      state.streamList = streamFormatter(action.payload.result);
      state.error = '';
    });
    builder.addCase(getStreamListActionCreator.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error.message;
    });

    builder.addCase(getSpaceStreamListActionCreator.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getSpaceStreamListActionCreator.fulfilled,
      (state, action) => {
        state.isLoading = false;
        state.spaceStreamList = spaceStreamFormatter(action.payload.result);
        state.error = '';
      }
    );
    builder.addCase(
      getSpaceStreamListActionCreator.rejected,
      (state, action) => {
        state.isLoading = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(getQualificationListActionCreator.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(
      getQualificationListActionCreator.fulfilled,
      (state, action) => {
        state.qualificationList = qualificationFormatter(action.payload.result);
        state.isLoading = false;
        state.error = '';
      }
    );
    builder.addCase(
      getQualificationListActionCreator.rejected,
      (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      }
    );

    builder.addCase(getJoinLocationListActionCreator.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(
      getJoinLocationListActionCreator.fulfilled,
      (state, action) => {
        state.joinLocationList = locationFormatter(action.payload.result);
        state.error = '';
        state.isLoading = false;
      }
    );
    builder.addCase(
      getJoinLocationListActionCreator.rejected,
      (state, action) => {
        state.error = action.error.message;
        state.isLoading = false;
      }
    );

    builder.addCase(getTeamListActionCreator.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getTeamListActionCreator.fulfilled, (state, action) => {
      state.teamList = action.payload.result;
      state.error = '';
      state.isLoading = false;
    });
    builder.addCase(getTeamListActionCreator.rejected, (state, action) => {
      state.error = action.error.message;
      state.isLoading = false;
    });
  }
});

export default commonSlice.reducer;
