import React, { useEffect, useState } from 'react';
import './GitDetails.scss';
import { Col, Row } from 'reactstrap';
import { useDispatch } from 'react-redux';
import CustomModal from '../../../../../shared/components/modal/Modal';
import Tab from '../../../../../shared/components/tab/Tab';
import Commits from './Commits/Commits';
import Branches from './Branches/Branches';
import CommitsTab from './Commits/CommitsTab/CommitsTab';
import BranchesTab from './Branches/BranchesTab/BranchesTab';
import PullRequestsTab from './PullRequests/PullRequestsTab/PullRequestsTab';
import { getGitDetailsOfTaskActionCreator } from '../../../../../redux/slices/kanban/KanbanSlice';

export default function GitDetails({ taskData, isInKanban = true }) {
  const dispatch = useDispatch();
  const [showDevelopmentModal, setShowDevelopmentModal] = useState(false);
  const [activeDevTab, setActiveDevTab] = useState(1);
  const [gitData, setGitData] = useState(taskData);
  const [refetch, setRefetch] = useState(false);

  useEffect(() => {
    if (refetch) {
      dispatch(
        getGitDetailsOfTaskActionCreator({
          kanban_task_details_id:
            taskData?.kanban_details?.kanban_task_details_id ??
            taskData?.kanban_task_details_id,
          wall_id: taskData?.kanban_details?.wall_id ?? taskData?.wall_id
        })
      ).then((response) => {
        setGitData((prev) => ({ ...prev, git: response?.payload?.result }));
      });
      setRefetch(false);
    }
  }, [refetch]);

  const developmentTabPane = [
    {
      id: 1,
      title: 'Branches',
      content: <BranchesTab gitDetails={gitData?.git} />
    },
    {
      id: 2,
      title: 'Commits',
      content: <CommitsTab gitDetails={gitData?.git} />
    },
    {
      id: 3,
      title: 'Pull requests',
      content: <PullRequestsTab gitDetails={gitData?.git} />
    }
  ];

  const openDevelopmentModal = (activeTab) => {
    setActiveDevTab(activeTab);
    // setShowCommitPopover(false);
    setShowDevelopmentModal(true);
  };

  if (!gitData?.git?.git_project_id) {
    const className = isInKanban ? 'text-primary small' : 'text-secondary ';
    return (
      <p className={`${className} mb-0 `}>
        <em>
          Link GitLab repository to Kanban board for visibility into development
          progress.
        </em>
      </p>
    );
  }

  return (
    <Row>
      <Col className={isInKanban ? 'col-12' : 'col-6'}>
        <Branches
          callback={() => {
            setRefetch(true);
          }}
          kanbanData={gitData}
          taskData={gitData}
          isDevelopmentModalOpen={showDevelopmentModal}
          openBranchModal={() => {
            openDevelopmentModal(developmentTabPane[0].id);
          }}
        />
      </Col>

      <Col className={isInKanban ? 'col-12' : 'col-6'}>
        <Commits
          kanbanData={gitData}
          taskData={gitData}
          isDevelopmentModalOpen={showDevelopmentModal}
          openCommitsModal={() => {
            openDevelopmentModal(developmentTabPane[1].id);
          }}
        />
      </Col>

      <CustomModal
        body={
          <div>
            <Tab tabPane={developmentTabPane} defaultActiveTab={activeDevTab} />
          </div>
        }
        showModal={showDevelopmentModal}
        heading={`Development ${gitData?.kanban_task_id}`}
        setShowModal={setShowDevelopmentModal}
        style={{ maxWidth: '900px' }}
      />
    </Row>
  );
}
