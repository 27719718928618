import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';

const initialState = {
  wallLists: [],
  wallListsFiltered: [],
  wallDetails: null,
  wallListElementDetails: {},
  wallListView: 'viewWallList',
  loading: false,
  status: '',
  btnLoader: false,
  statusCode: null,
  error: ''
};

export const getWallListActionCreator = createAsyncThunk(
  'wallList/list',
  async () => {
    const { data } = await fetchUtility('get', `${SERVER_URL.WALL_LIST}`);
    return data;
  }
);
export const deleteWallListActionCreator = createAsyncThunk(
  'wallList/delete',
  async (id) => {
    const { data } = await fetchUtility(
      'delete',
      `${SERVER_URL.WALL_LIST_DELETE}${id}`
    );
    return data;
  }
);
export const createWallListActionCreator = createAsyncThunk(
  'wallList/save',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.WALL_LIST_CREATE}`,
      params.body
    );
    return data;
  }
);
export const updateWallListActionCreator = createAsyncThunk(
  'wallList/update',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.WALL_LIST_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);
export const getWallListFilteredActionCreator = createAsyncThunk(
  'wallList/filteredList',
  async (id) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.WALL_FILTERED_LIST}${id || 0}`
    );
    return data;
  }
);
export const getWallDetailsActionCreator = createAsyncThunk(
  'wallList/details',
  async (id) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.WALL_DETAILS}${id}`
    );
    return data;
  }
);

const wallSubmenuSlice = createSlice({
  name: 'wallList',
  initialState,
  reducers: {
    wallListState: (state, { payload }) => {
      state.wallListView = payload.wallListView;
    },
    wallDetails: (state, { payload }) => {
      state.wallDetails = payload.wallDetails;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getWallListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getWallListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.wallLists = action.payload.result.map((data) => {
        const list = {
          active: data.active,
          wall_id: data.wall_id,
          wall_name: data.wall_name,
          start_date: data.start_date,
          timezone: data.timezone,
          wall_file: data.wall_file,
          is_editable: data.is_editable
        };
        return list;
      });
      state.error = '';
    });
    builder.addCase(getWallListActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.code;
    });

    builder.addCase(deleteWallListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteWallListActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(deleteWallListActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(createWallListActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(createWallListActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(createWallListActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(updateWallListActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateWallListActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updateWallListActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });
    builder.addCase(getWallDetailsActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(getWallDetailsActionCreator.fulfilled, (state, action) => {
      state.btnLoader = false;
      state.wallListElementDetails = action.payload.result;
      state.error = '';
    });
    builder.addCase(getWallDetailsActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(getWallListFilteredActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getWallListFilteredActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.error = '';
        state.wallListsFiltered = action.payload.result;
      }
    );
    builder.addCase(
      getWallListFilteredActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
  }
});
export const { wallListState, wallDetails } = wallSubmenuSlice.actions;
export default wallSubmenuSlice.reducer;
