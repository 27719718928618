import { TASK_PRIORITY } from '../../../../../shared/constants/kanbanConstant';
import './TaskCard.scss';

function PriorityIcon({ priorityId, className }) {
  const taskPriority = TASK_PRIORITY.find(
    (priority) => priority.value === +priorityId
  );

  return (
    <img
      className={`task-card__priority ${className}`}
      src={taskPriority?.prioritySrc}
      alt={taskPriority?.priorityAlt}
    />
  );
}

export default PriorityIcon;
