/* eslint-disable import/prefer-default-export */
import lowPriority from '../../assets/images/low-priority.svg';
import mediumPriority from '../../assets/images/medium-priority.svg';
import highPriority from '../../assets/images/high-priority.svg';
import criticalPriority from '../../assets/images/critical-priority.svg';

export const gitlabClientId = process.env.REACT_APP_GITLAB_CLIENT_ID;
export const gitLabUrl = process.env.REACT_APP_GITLAB_URL;
export const gitlabRedirectUri = process.env.REACT_APP_GITLAB_REDIRECT_URI;
export const gitlabClientSecret = process.env.REACT_APP_GITLAB_CLIENT_SECRET;

export const SPRINT_TYPE = {
  '1 Week': 1,
  '2 Weeks': 2,
  '3 Weeks': 3,
  '4 Weeks': 4
  // Custom: 5
};

export const TASK_PRIORITY = [
  {
    id: 1,
    value: 1,
    label: 'Low',
    prioritySrc: lowPriority,
    priorityAlt: 'Low priority'
  },
  {
    id: 2,
    value: 2,
    label: 'Medium',
    prioritySrc: mediumPriority,
    priorityAlt: 'Medium priority'
  },
  {
    id: 3,
    value: 3,
    label: 'High',
    prioritySrc: highPriority,
    priorityAlt: 'High priority'
  },
  {
    id: 4,
    value: 4,
    label: 'Critical',
    prioritySrc: criticalPriority,
    priorityAlt: 'Critical priority'
  }
];

export const SPRINT_STATUS = {
  open: 0,
  inprogress: 1,
  completed: 2
};
