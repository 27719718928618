import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';
import { decryptCookieValue } from '../../shared/helper/commonFunction';

const initialState = {
  curriculumReportList: [],
  taskReportList: [],
  projectReportList: [],
  menteeProjectReportList: [],
  menteeReportList: [],
  goalReportList: [],
  lastViewedUser: null,
  lastViewedtype: null,
  isCurriculumViewed: null,
  loading: false,
  error: ''
};

export const getCurriculumReportListActionCreator = createAsyncThunk(
  'report/curriculum_list',
  async (params) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.CURRICULUM_STATUS_LIST}${params.id}/${params.type}`
    );
    return data;
  }
);

export const getTaskReportListActionCreator = createAsyncThunk(
  'report/task_list',
  async (params) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.TASK_REPORT_LIST}${params.id}/${params.type}`
    );
    return data;
  }
);

export const getGoalReportListActionCreator = createAsyncThunk(
  'report/goal_list',
  async (params) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.GOAL_REPORT_LIST}${params.id}/${params.type}`
    );
    return data;
  }
);

export const getProjectReportListActionCreator = createAsyncThunk(
  'report/project_list',
  async (params) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.PROJECT_REPORT_LIST}${params.id}/${params.type}`
    );
    return data;
  }
);

export const getReportMentorActionCreator = createAsyncThunk(
  'mentor/list',
  async () => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.REPORT_MENTOR}`,
      {}
    );
    return data;
  }
);
export const getReportMenteeActionCreator = createAsyncThunk(
  'mentee/list',
  async () => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.REPORT_MENTEE}`,
      {}
    );
    return data;
  }
);

export const getMenteeProjectReportActionCreator = createAsyncThunk(
  'report/mentee_project_list',
  async (id) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.MENTEE_PROJECT_REPORT}${id}`
    );
    return data;
  }
);
export const downloadReport = async (URL) => {
  const result = await fetch(URL, {
    headers: {
      Authorization: `Bearer ${
        decryptCookieValue(Cookies.get('credentials')).token
      }`,
      responseType: 'blob'
    }
  });
  let fileName = 'Report.xlsx';
  if (result?.headers.get('Content-Disposition')?.includes('filename'))
    fileName = result?.headers
      .get('Content-Disposition')
      .split('filename=')[1]
      .split(';')[0];
  const blob = await result.blob();
  const href = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName);
  link.click();
  link.remove();
};

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    resetReportList: (state) => {
      state.curriculumReportList = [];
    },
    lastViewed: (state, { payload }) => {
      state.lastViewedUser = payload.user;
      state.lastViewedtype = payload.type;
    },
    isCurriculumViewed: (state, { payload }) => {
      state.isCurriculumViewed = payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getCurriculumReportListActionCreator.pending, (state) => {
      state.loading = true;
      // state.curriculumReportList = [];
    });
    builder.addCase(
      getCurriculumReportListActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.curriculumReportList = action.payload.result;
        state.error = '';
      }
    );
    builder.addCase(
      getCurriculumReportListActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(getTaskReportListActionCreator.pending, (state) => {
      state.loading = true;
      // state.taskReportList = [];
    });
    builder.addCase(
      getTaskReportListActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.taskReportList = action.payload.result;
        state.error = '';
      }
    );
    builder.addCase(
      getTaskReportListActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(getGoalReportListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getGoalReportListActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.goalReportList = action.payload.result;
        state.error = '';
      }
    );
    builder.addCase(
      getGoalReportListActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(getProjectReportListActionCreator.pending, (state) => {
      state.loading = true;
      // state.projectReportList = [];
    });
    builder.addCase(
      getProjectReportListActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.projectReportList = action.payload.result;
        state.error = '';
      }
    );
    builder.addCase(
      getProjectReportListActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(getReportMentorActionCreator.pending, (state) => {
      state.loading = true;
      // state.mentorReportList = [];
    });
    builder.addCase(getReportMentorActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.mentorReportList = action.payload.result;
      state.error = '';
    });
    builder.addCase(getReportMentorActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
    builder.addCase(getReportMenteeActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getReportMenteeActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.menteeReportList = action.payload.result;
      state.error = '';
    });
    builder.addCase(getReportMenteeActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getMenteeProjectReportActionCreator.pending, (state) => {
      state.loading = true;
      // state.projectReportList = [];
    });
    builder.addCase(
      getMenteeProjectReportActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.menteeProjectReportList = action.payload.result;
        state.error = '';
      }
    );
    builder.addCase(
      getMenteeProjectReportActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
  }
});

export const { resetReportList, lastViewed, isCurriculumViewed } =
  reportSlice.actions;

export default reportSlice.reducer;
