/* eslint-disable import/prefer-default-export */
import { SPRINT_STATUS } from '../../shared/constants/kanbanConstant';

export const convertObjectToOptionArray = (obj) =>
  Object.entries(obj).map(([label, value]) => ({ label, value }));

export const commonTaskStatusFormatter = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }

  const formattedData = data
    ?.map((item) => ({
      id: item.kanban_status_id,
      value: item.kanban_status_id,
      label: item.status_name
    }))
    .sort((a, b) => a.id - b.id);

  return formattedData;
};

export const commonSprintFormatter = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }

  const formattedData = data?.map((item) => ({
    id: item?.id,
    value: item?.id,
    label: item?.sprint_name
  }));

  return formattedData;
};

export const formatTaskPriorityOption = (option) => (
  <div className="d-flex align-items-center">
    <img
      src={option.prioritySrc}
      alt={option.priorityAlt}
      className="mr-2"
      style={{ width: '20px', height: '20px' }}
    />
    <span>{option.label}</span>
  </div>
);

const formatSprintStatus = (str) => {
  let formattedStatus;
  switch (str.toLowerCase()) {
    case 'open':
      formattedStatus = 'Not Started';
      break;
    case 'inprogress':
      formattedStatus = 'In Progress';
      break;
    default:
      formattedStatus = str.charAt(0).toUpperCase() + str.slice(1);
  }
  return formattedStatus;
};

export const getSprintStatusLabel = (sprintStatus) => {
  const statusKey = Object.keys(SPRINT_STATUS).find(
    (key) => SPRINT_STATUS[key] === Number(sprintStatus)
  );
  return statusKey ? formatSprintStatus(statusKey) : 'N/A';
};
