import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';

const initialState = {
  initiativesList: [],
  technologiesList: [],
  categoriesList: [],
  loading: false,
  status: '',
  statusCode: null,
  btnLoader: false,
  error: ''
};

export const getInitiativesListActionCreator = createAsyncThunk(
  'initiatives/list',
  async () => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.INTERNAL_INITIATIVES_LIST}`
    );
    return data;
  }
);

export const getTechnologiesActionCreator = createAsyncThunk(
  'technologies/list',
  async () => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.TECHNOLOGIES_LIST}`
    );
    return data;
  }
);

export const getCategoriesActionCreator = createAsyncThunk(
  'categories/list',
  async () => {
    const { data } = await fetchUtility('get', `${SERVER_URL.CATEGORIES_LIST}`);
    return data;
  }
);

export const saveInitiativeActionCreator = createAsyncThunk(
  'initiatives/save',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.CREATE_INTERNAL_INITIATIVES}`,
      params.body
    );
    return data;
  }
);

export const deleteInitiativeActionCreator = createAsyncThunk(
  'initiatives/delete',
  async (id) => {
    const { data } = await fetchUtility(
      'delete',
      `${SERVER_URL.DELETE_INTERNAL_INITIATIVES}${id}`
    );
    return data;
  }
);

export const updateInitiativeActionCreator = createAsyncThunk(
  'initiatives/update',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.UPDATE_INTERNAL_INITIATIVES}${params.initiative_id}`,
      params.body
    );
    return data;
  }
);

export const requestAllocationActionCreator = createAsyncThunk(
  'initiatives/request_allocation',
  async (id) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.REQUEST_ALLOCATION}${id}`
    );
    return data;
  }
);

const InternalInitiativesSlice = createSlice({
  name: 'initiatives',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getInitiativesListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getInitiativesListActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.initiativesList = action.payload.result;
        state.error = '';
      }
    );
    builder.addCase(
      getInitiativesListActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(deleteInitiativeActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(deleteInitiativeActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(deleteInitiativeActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(saveInitiativeActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(saveInitiativeActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(saveInitiativeActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(updateInitiativeActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateInitiativeActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updateInitiativeActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(getTechnologiesActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTechnologiesActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.technologiesList = action.payload.result;
      state.error = '';
    });
    builder.addCase(getTechnologiesActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getCategoriesActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getCategoriesActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.categoriesList = action.payload.result;
      state.error = '';
    });
    builder.addCase(getCategoriesActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(requestAllocationActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(requestAllocationActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(
      requestAllocationActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
  }
});

export default InternalInitiativesSlice.reducer;
