import React, { useState } from 'react';
import './PullRequestsTab.scss';
import { openUrlInNewPage } from '../../../../../../../shared/helper/commonFunction';
import PullRequestRow from '../PullRequestRow/PullRequestRow';

export default function PullRequestsTab({ gitDetails }) {
  const [showPullRequestRows, setShowPullRequestRows] = useState(true);

  const toggleShowPullRequestRows = () => {
    setShowPullRequestRows((value) => !value);
  };

  return (
    <div className="scroll-pr-content">
      <div className="hover-highlight p-2 my-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div
            role="presentation"
            className="d-flex align-items-center cursor-pointer text-dark me-3"
            onClick={toggleShowPullRequestRows}>
            <i
              className={`bi fw-500 ${
                showPullRequestRows ? 'bi-chevron-down' : 'bi-chevron-right'
              }`}
            />
          </div>
          <h6
            role="presentation"
            onClick={() => openUrlInNewPage(gitDetails?.git_repo_url)}
            className="text-primary git-link cursor-pointer mb-0">
            {gitDetails?.git_repo_name}
          </h6>
        </div>
      </div>

      {showPullRequestRows && (
        <div className="pr-details-table px-3">
          <div className="text-sub font-bold border-bottom">
            <div className="row">
              <div className="col-md-1">Author</div>
              {/* <div className="col-md-1">ID</div> */}
              <div className="col-md-5">Summary</div>
              <div className="col-md-1">Status</div>
              <div className="col-md-2">Reviewers</div>
              <div className="col-md-3">Updated</div>
            </div>
          </div>

          {gitDetails?.mr?.length ? (
            gitDetails?.mr.map((pr) => (
              <PullRequestRow pullRequest={pr} key={pr.merge_request_id} />
            ))
          ) : (
            <p className="text-secondary text-center mt-3">No pull requests</p>
          )}
        </div>
      )}
    </div>
  );
}
