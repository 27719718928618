import React, { Suspense, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import 'bootstrap/dist/css/bootstrap.min.css';
import { routes, subNavRoutes, publicRoute } from './routes';

import InjectAxiosInterceptors from './shared/components/InjectAxiosInterceptors/InjectAxiosInterceptors';
import Loader from './shared/components/loader/Loader';
import ScrollToTop from './ScrollToTop';
import './App.scss';
import { getEmployeeAssigednWallListActionCreator } from './redux/slices/LoginSlice';
import FrameBusting from './shared/components/frameBusting/FrameBusting';

const Header = React.lazy(() => import('./layout/header/Header'));
const SideBar = React.lazy(() => import('./layout/sideNav/SideNav'));

function App() {
  const dispatch = useDispatch();
  const authState = useSelector((state) => state.login.loginDetails);
  const isLoginLoading = useSelector((state) => state.login.loading);
  const location = useLocation();

  const createPrivateRoutes = (routesArray) => {
    // condition for protected routes
    if (authState) {
      // loop over routes array
      return routesArray?.map(
        ({ path, component, allowedRoles, additionalRoles }) => {
          // Check if the user has any of the allowedRoles
          const hasAllowedRole = allowedRoles?.some(
            (allowRole) => authState.role === allowRole
          );

          // Check if the user has any of the additionalRoles
          const hasAdditionalRole = additionalRoles?.some(
            (additionalRole) => authState.additional_role === additionalRole
          );

          // Check if either allowedRoles or additionalRoles are satisfied
          if (
            (additionalRoles && hasAllowedRole && hasAdditionalRole) ||
            (!additionalRoles && hasAllowedRole)
          ) {
            return <Route key={path} path={path} element={component} exact />;
          }

          return null;
        }
      );
    }
    return null;
  };

  // for public routes accessable to all
  const createPublicRoute = (routesArray) =>
    routesArray?.map(({ path, component }) => (
      <Route key={path} path={path} element={component} exact />
    ));

  useEffect(() => {
    if (!authState) {
      Cookies.set('redirectURL', window.location.href);
    }
    if (authState)
      dispatch(
        getEmployeeAssigednWallListActionCreator(authState?.employee_id)
      );
  }, []);

  const gitLabCallbackPaths = [
    /^\/gitlab-callback$/,
    /^\/gitlab-linkProjectToGitLabForm\/[^/]+$/,
    /^\/gitlab-unlinkProjectFromGitLabForm\/[^/]+\/[^/]+$/,
    /^\/gitlab-create-branch\/.+$/
  ];

  const isGitLabCallbackPage = gitLabCallbackPaths.some((pattern) =>
    pattern.test(location.pathname)
  );

  return (
    <>
      <ScrollToTop />
      <FrameBusting />
      <InjectAxiosInterceptors />
      <Suspense fallback={<Loader />}>
        {!isGitLabCallbackPage && <Header />}
        {authState && !isGitLabCallbackPage && <SideBar />}
        <main>
          <Loader show={isLoginLoading} />
          <div className="container-fluid">
            <div className="row">
              <div
                className={
                  authState && !isGitLabCallbackPage
                    ? 'set-sidebar col-xl-10 col-lg-9'
                    : ''
                }>
                <Suspense fallback={<Loader />}>
                  <Routes>
                    {createPublicRoute(publicRoute)}
                    {authState && createPrivateRoutes(routes)}
                    {authState && createPrivateRoutes(subNavRoutes)}
                    <Route path="*" element={<Navigate to="/" replace />} />
                  </Routes>
                </Suspense>
              </div>
            </div>
          </div>
        </main>
        {!isGitLabCallbackPage && (
          <div className="footer">
            <footer className="text-center text-muted">
              <small>© bridge</small>
            </footer>
          </div>
        )}
      </Suspense>
    </>
  );
}

export default App;
