import React, { useState } from 'react';
import './CommitsTab.scss';
import { openUrlInNewPage } from '../../../../../../../shared/helper/commonFunction';
import CommitRow from '../CommitRow/CommitRow';

export default function CommitsTab({ gitDetails }) {
  const [showCommitRows, setShowCommitRows] = useState(true);
  const [openedCommitRows, setOpenedCommitRows] = useState({});

  const toggleShowCommitRows = () => {
    setOpenedCommitRows({});
    setShowCommitRows((value) => !value);
  };

  const isFilesShown = () =>
    Object.values(openedCommitRows).length
      ? Object.values(openedCommitRows).includes(true)
      : false;

  const handleShowAllFiles = () => {
    setShowCommitRows(true);
    setOpenedCommitRows(() =>
      Object.assign(
        ...gitDetails.commits.map((commit) => ({ [commit.commit_id]: true }))
      )
    );
  };

  const handleHideAllFiles = () => {
    setOpenedCommitRows(() =>
      Object.assign(
        ...gitDetails.commits.map((commit) => ({ [commit.commit_id]: false }))
      )
    );
  };

  const toggleAllFiles = () => {
    if (gitDetails.commits?.length) {
      const toggleFn = isFilesShown() ? handleHideAllFiles : handleShowAllFiles;
      toggleFn();
    }
  };

  const handleCommitToggle = (id) => {
    setOpenedCommitRows((value) => ({ ...value, [id]: !value[id] }));
  };

  return (
    <div className="scroll-commit-content">
      <div className="hover-highlight p-2 my-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div
            role="presentation"
            className="d-flex align-items-center cursor-pointer text-dark me-3"
            onClick={toggleShowCommitRows}>
            <i
              className={`bi fw-500 ${
                showCommitRows ? 'bi-chevron-down' : 'bi-chevron-right'
              }`}
            />
          </div>
          <h6
            role="presentation"
            onClick={() => openUrlInNewPage(gitDetails?.git_repo_url)}
            className="text-primary git-link cursor-pointer mb-0">
            {gitDetails?.git_repo_name}
          </h6>
        </div>
        <p
          role="presentation"
          onClick={toggleAllFiles}
          className="text-primary cursor-pointer small clickable-link mb-0">
          {isFilesShown() ? 'Hide' : 'Show'} all files
        </p>
      </div>

      {showCommitRows && (
        <div className="commit-details-table px-3">
          <div className="text-sub font-bold border-bottom">
            <div className="row">
              <div className="col-md-1">Author</div>
              <div className="col-md-2">Commit</div>
              <div className="col-md-4">Message</div>
              <div className="col-md-3">Date</div>
              <div className="col-md-2">Files</div>
            </div>
          </div>

          {gitDetails.commits?.length ? (
            gitDetails.commits.map((commit) => (
              <CommitRow
                commit={commit}
                handleCommitToggle={handleCommitToggle}
                showFiles={openedCommitRows[commit.commit_id]}
                repoUrl={gitDetails.git_repo_url}
                key={commit.commit_id}
              />
            ))
          ) : (
            <p className="text-secondary text-center mt-3">No commits</p>
          )}
        </div>
      )}
    </div>
  );
}
