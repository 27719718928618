import React, { useEffect, useState } from 'react';
import Avatar from 'react-avatar';
// import { UncontrolledTooltip } from 'reactstrap';
import './CommitRow.scss';
import {
  filterLinks,
  timeago,
  openUrlInNewPage
} from '../../../../../../../shared/helper/commonFunction';

const FILE_CHANGE_TYPES = {
  ADDED: 'ADDED',
  MODIFIED: 'MODIFIED',
  DELETED: 'DELETED'
};

export default function CommitRow({
  commit,
  handleCommitToggle,
  repoUrl,
  showFiles = false
}) {
  const [files, setFiles] = useState([]);

  const toggleShowFiles = () => {
    handleCommitToggle(commit.commit_id);
  };

  const createFileUrl = (filePath) =>
    `${repoUrl}/-/blob/${commit?.commit_id}/${filePath}`;

  const createFileObj = (file, fileType) => ({
    changeType: fileType,
    path: file,
    url: createFileUrl(file)
  });

  const getAllFilesFormatted = () => {
    const addedFiles = commit?.added_files.map((file) =>
      createFileObj(file, FILE_CHANGE_TYPES.ADDED)
    );

    const modifiedFiles = commit?.modified_files.map((file) =>
      createFileObj(file, FILE_CHANGE_TYPES.MODIFIED)
    );

    const deletedFiles = commit?.removed_files.map((file) =>
      createFileObj(file, FILE_CHANGE_TYPES.DELETED)
    );

    return [...addedFiles, ...modifiedFiles, ...deletedFiles];
  };

  useEffect(() => {
    setFiles(getAllFilesFormatted());
  }, [commit, repoUrl]);

  return (
    <div className="py-2 hover-highlight">
      <div className="row text-sub">
        <div
          id={commit.commit_id}
          className="col-md-1 d-flex align-items-center">
          {commit?.author_avatar_url ? (
            <img
              className="user-image rounded-circle m-auto"
              src={commit?.author_avatar_url}
              height={25}
              alt="profilePic"
            />
          ) : (
            <Avatar
              name={commit?.author}
              // round="20px"
              className="m-auto"
              round
              maxInitials={3}
              size="25"
            />
          )}
        </div>
        {/* <UncontrolledTooltip placement="bottom" target={commit.commit_id}>
          {commit?.author}
        </UncontrolledTooltip> */}
        <div className="col-md-2 d-flex align-items-center">
          <p
            role="presentation"
            onClick={() => openUrlInNewPage(commit?.commit_url)}
            className="text-primary cursor-pointer mb-0 clickable-link text-truncate">
            #{commit?.commit_id}
          </p>
        </div>
        <div className="col-md-4 d-flex align-items-center text-truncate">
          <span
            className="font-regular"
            style={{ whiteSpace: 'pre-line' }}
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: filterLinks(commit?.commit_message)
            }}
          />
        </div>
        <div className="col-md-3 d-flex align-items-center text-truncate">
          {timeago(commit?.created_at)}
        </div>
        <div className="col-md-2 d-flex align-items-center text-truncate">
          <p
            role="presentation"
            onClick={toggleShowFiles}
            className="text-primary font-bold cursor-pointer mb-0 clickable-link">
            {/* {commit?.files?.length}{' '}
            {commit?.files?.length === 1 ? 'file' : 'files'} */}
            {files.length} {files.length === 1 ? 'file' : 'files'}
          </p>
        </div>
      </div>

      {showFiles && (
        <div className="px-2 mt-2">
          {files.map((file) => (
            <CommitFile file={file} key={file.path} />
          ))}
        </div>
      )}
    </div>
  );
}

function CommitFile({ file }) {
  const CHANGE_TYPE_CLASSES = {
    [FILE_CHANGE_TYPES.ADDED]: 'file-added',
    [FILE_CHANGE_TYPES.MODIFIED]: 'file-modified',
    [FILE_CHANGE_TYPES.DELETED]: 'file-removed'
  };

  const changeTypeClassName = CHANGE_TYPE_CLASSES[file.changeType] || '';

  return (
    <div className="row">
      <div className="col-md-2 d-flex align-items-center my-1">
        {/* <>
            <span className="file-added small me-1">+{file.linesAdded}</span>
            <span className="file-removed small">-{file.linesRemoved}</span>
          </> */}

        <span className={`${changeTypeClassName} small`}>
          {file.changeType}
        </span>
      </div>
      <div className="col-md-10 d-flex align-items-center text-secondary">
        <p
          role="presentation"
          onClick={() => openUrlInNewPage(file?.url)}
          className="small cursor-pointer clickable-link mb-0">
          {file?.path}
        </p>
      </div>
    </div>
  );
}
