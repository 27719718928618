import React, { useState, useEffect } from 'react';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Row,
  Col,
  Label,
  Button,
  Tooltip
} from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import StarRatings from 'react-star-ratings';
import Avatar from 'react-avatar';
import { useNavigate } from 'react-router-dom';
import InputField from '../../input/inputField/InputField';
import SelectField from '../../input/selectField/SelectField';
import CustomModal from '../../components/modal/Modal';
import WallPostForms from '../../../pages/wall/WallPostForms';
import DeleteWallPost from '../../../pages/wall/DeleteWallPost';
import Likes from '../likes/Likes';
import Comments from '../comments/Comments';
import Pin from '../pin/Pin';
import {
  rating,
  // feedbackData,
  assigneeStatus
} from '../../constants/ConstantsData';
import Comment from '../comment/Comment';
import './WallProject.scss';
import {
  saveFeedbackActionCreator,
  updateProjectStatusActionCreator
} from '../../../redux/slices/PostSlice';
import { UserComments } from '../comment/CommentView';
import {
  timeago,
  listFormatter,
  filterLinks,
  attachmentPreview,
  onViewProfile
} from '../../helper/commonFunction';
import TaggedEmployees from '../taggedEmployeeList/TaggedEmployees';
import { formatOptionLabel } from '../../helper/commonDataFormatter';
import FeedbackForms from '../Feedback/FeedbackForms';
import PriorityIcon from '../../../pages/kanban/board/board-components/TaskCard/PriorityIcon';
import { getSprintStatusLabel } from '../../../pages/kanban/kanbanUtils';
import GitDetails from '../../../pages/kanban/board/board-components/GitDetails/GitDetails';

function DropdownIndicator() {
  return (
    <svg
      height="20"
      width="30"
      viewBox="4 0 20 20"
      aria-hidden="true"
      focusable="false"
      className="css-tj5bde-Svg">
      <path
        fill="#E75256"
        d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
      />
    </svg>
  );
}

function WallProject({
  postData,
  getPostList,
  // isWallMember,
  isWallMentee,
  wallActiveStatus,
  copyToClipboard,
  hideActions = false
}) {
  const dispatch = useDispatch();
  // const { wallID } = useParams();
  const authState = useSelector((state) => state.login.loginDetails);
  const feedbackLoader = useSelector((state) => state.post.loading);
  const isWallMember = useSelector((state) => state.post.isWallMember);
  const [showModal, setShowModal] = useState(false);
  const [modalHeader, setModalHeader] = useState('');
  const [popupView, setPopupView] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [projectData, setProjectData] = useState(null);
  const [addComment, setAddComment] = useState(false);
  const [wallProjectId, setWallProjectId] = useState(false);
  const [isPostUpdated, setIsPostUpdated] = useState(false);
  const [status, setStatus] = useState();
  const [feedBackAssigneeList, setFeedBackAssigneeList] = useState([]);
  const [taggedEmployeesList, setTaggedEmployeesList] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [feedback, setFeedBack] = useState(null);
  const [feedbackHeight, setFeedbackHeight] = useState(50);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const navigate = useNavigate();

  const {
    control,
    setValue,
    handleSubmit,
    formState: { errors },
    reset,
    watch
  } = useForm();

  const watchFeedback = watch('feedback');
  const refFeedback = document.getElementsByName('feedback');
  useEffect(() => {
    if (refFeedback[0]?.scrollHeight > 50) {
      setFeedbackHeight(refFeedback[0]?.scrollHeight);
    } else {
      setFeedbackHeight(50);
    }
  }, [watchFeedback]);

  const getFeedbackAssignees = (WallProjectAssignees) => {
    const assignees = WallProjectAssignees;
    if (assignees) {
      const feedBackAssignees = listFormatter(assignees, (assigneeData) => ({
        id: assigneeData?.employee_id,
        label: assigneeData?.name || '',
        value: assigneeData?.name || '',
        email: assigneeData?.email || ''
      }));
      if (feedBackAssignees) setFeedBackAssigneeList(feedBackAssignees);
    }
  };

  const getTaggedPeople = (assignees) => {
    if (postData?.tagged_employees?.length) {
      const taggedEmployees = postData?.tagged_employees.filter(
        (taggedEmployee) =>
          !assignees.some(
            (assigneeData) =>
              taggedEmployee.employee_id === assigneeData?.assigneeID
          )
      );
      setTaggedEmployeesList(taggedEmployees);
    }
  };

  useEffect(() => {
    if (postData && postData?.wall_project?.assignees?.length) {
      if (postData?.created_at !== postData?.post_order) {
        setIsPostUpdated(true);
      } else {
        setIsPostUpdated(false);
      }

      getFeedbackAssignees(postData.wall_project.assignees);
      // let showFeedback = false;
      let isFeedbackEnabled = false;
      let currentUserFeedback = false;
      let isFeedbackNull = true;
      const assignees = postData.wall_project.assignees.map(
        (wallProjectData) => {
          // const feedback = [];
          if (wallProjectData?.employee_id === authState?.employee_id) {
            if (wallProjectData.feedbacks?.length) currentUserFeedback = true;
            const taskStatus = assigneeStatus?.find(
              (item) => item.value === wallProjectData?.status
            );
            if (taskStatus) setStatus(taskStatus);
          }

          if (wallProjectData.feedbacks?.length) isFeedbackNull = false;
          return {
            id: wallProjectData.id,
            assigneeName: wallProjectData?.name,
            profilePic: wallProjectData?.profile_image,
            assigneeID: wallProjectData?.employee_id,
            status: wallProjectData.status,
            post_id: postData.post_id,
            feedback: wallProjectData?.feedbacks
          };
        }
      );
      if (assignees) {
        const filteredAssignees = [];
        assignees.forEach((assigneeData) => {
          filteredAssignees.push(assigneeData);
        });
        if (filteredAssignees.length > 0) {
          isFeedbackEnabled = true;
        }
      }
      getTaggedPeople(assignees);
      setProjectData({
        projectName:
          `${postData.wall_project?.project?.project_name} - ${postData.wall_project?.project?.platform}` ||
          '',
        project_description:
          postData.wall_project?.project.project_description || '',
        description: postData.wall_project?.description,
        assignees,
        // showFeedback,
        isFeedbackEnabled,
        currentUserFeedback,
        isFeedbackNull,
        post_attachments: postData?.post_attachments,
        isKanbanPost: postData?.wall_project?.is_kanban_post,
        task_description:
          postData.wall_project?.kanban_details?.task_description || '',
        sprint:
          postData?.wall_project?.kanban_details?.Sprint?.sprint_name || 'N/A',
        priority: postData?.wall_project?.priority_name || 'N/A',
        priorityId: postData?.wall_project?.priority,
        taskStatus:
          postData?.wall_project?.kanban_details?.KanbanStatusBoard
            ?.status_name || 'N/A',
        sprintStatus: getSprintStatusLabel(
          postData?.wall_project?.kanban_details?.Sprint?.sprint_status
        ),
        isTaskSprintCompleted:
          postData?.wall_project?.kanban_details?.Sprint?.is_completed
      });
      const assigne = postData.wall_project?.assignees.find(
        (item) => item?.employee_id === authState?.employee_id
      );
      if (assigne) {
        const stats = assigneeStatus.find(
          (item) => item.value === assigne.status
        );
        setWallProjectId(assigne?.wall_project_id);
        setValue('status', stats);
      }
    }
  }, [postData]);

  const openComments = () => {
    setIsOpen(!isOpen);
  };
  const onClickEditHandler = () => {
    setModalHeader('Edit Project');
    setPopupView('editView');
    setShowModal(true);
  };
  const onClickDeleteHandler = () => {
    setModalHeader('Delete Project');
    setPopupView('deleteView');
    setShowModal(true);
  };

  const handleClose = (result) => {
    if (result) {
      getPostList(true);
    }
    setShowModal(false);
    setPopupView(null);
  };
  const feedbackEditHandler = (data) => {
    setFeedBack(data);
    setModalHeader('Edit Feedback');
    setPopupView('editFeedBackView');
    setShowModal(true);
  };
  const onChangeStatus = (statusChange, index) => {
    if (
      projectData &&
      projectData.assignees[index] &&
      projectData.assignees[index].status
    )
      projectData.assignees[index].status = statusChange?.value;
    if (
      statusChange?.value === 'Completed' ||
      statusChange?.value === 'In Progress'
    ) {
      setStatus(statusChange);
      const apiData = {
        body: {
          status: statusChange.value,
          employee_id: authState.employee_id,
          wall_project_id: wallProjectId
        }
      };
      apiData.id = postData.post_id;
      dispatch(updateProjectStatusActionCreator(apiData)).then(() => {
        getPostList(true);
      });
    }
  };

  const onSubmit = (data) => {
    const projectReq = {
      post_id: postData.post_id,
      body: {
        feedback: data.feedback,
        rating: data.rating.value,
        assignee: data.assignee.id,
        feedback_given_by: authState?.employee_id
      }
    };
    dispatch(saveFeedbackActionCreator(projectReq))
      .unwrap()
      .then(() => {
        getPostList(true);
        reset();
        setFeedbackHeight(50);
      });
  };
  const avgRating = (value) => {
    let sum = 0;
    value?.feedback?.forEach((item) => {
      sum += +item.rating;
    });
    const avg = sum / value.feedback.length || 0;

    return (
      <StarRatings
        rating={avg || 0}
        starRatedColor="#e35156"
        starDimension="15px"
        starSpacing="0px"
      />
    );
  };

  return (
    <div className="project-container">
      <div className="col-sm-12 mt-5">
        <CustomModal
          body={
            (['editView'].includes(popupView) && (
              <WallPostForms
                handleClose={handleClose}
                postData={postData}
                formView={popupView}
              />
            )) ||
            (popupView === 'deleteView' && (
              <DeleteWallPost
                handleClose={handleClose}
                postData={postData}
                getPostList={getPostList}
              />
            )) ||
            (popupView === 'editFeedBackView' && (
              <FeedbackForms
                handleClose={handleClose}
                postData={postData}
                feedback={feedback}
                getPostList={getPostList}
              />
            ))
          }
          showModal={showModal}
          heading={modalHeader}
          setShowModal={setShowModal}
          size="xl"
        />
        <Card className="wall-card">
          <CardHeader className="p-0">
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                {postData?.post_creator_profile_image ? (
                  <img
                    className="user-image rounded-circle m-auto"
                    src={postData?.post_creator_profile_image}
                    alt="profilePic"
                  />
                ) : (
                  <Avatar
                    className="m-auto"
                    name={postData?.post_creator?.name}
                    // round="20px"
                    round
                    maxInitials={3}
                    size="38"
                  />
                )}

                <div className="px-3 d-flex flex-column justify-content-center">
                  <span
                    className="fs-6 font-bold cursor-pointer"
                    role="presentation"
                    onClick={() =>
                      onViewProfile(
                        postData?.post_creator?.employee_id,
                        navigate
                      )
                    }>
                    {postData?.post_creator?.name}
                  </span>
                </div>
                <div className="d-flex flex-column justify-content-center">
                  <span className="text-sub font-regular time-text">
                    {isPostUpdated && 'Updated '}
                    {timeago(
                      isPostUpdated
                        ? postData?.post_order
                        : postData?.created_at
                    )}
                  </span>
                </div>
              </div>
              {postData?.wall_name && (
                <div className="d-flex flex-column justify-content-cente justify-content-evenlyr">
                  <span className="text-sub font-semi-bold text-primary wall-name-tag ">
                    {postData?.wall_name}
                  </span>
                </div>
              )}
              {(authState.role === '0' &&
                postData.allowed_like_and_comment === 1) ||
              hideActions ? (
                ''
              ) : (
                <div className="d-flex align-items-center justify-content-evenly">
                  <Pin
                    postID={postData.post_id}
                    isPin={postData.is_pinned}
                    getPostList={getPostList}
                  />
                  <UncontrolledDropdown>
                    <DropdownToggle
                      nav
                      className="d-flex align-items-center p-0">
                      <span className="col-1 ms-2 pt-2 ps-1">
                        <i className="bi bi-three-dots text-dark fs-4" />
                      </span>
                    </DropdownToggle>
                    <DropdownMenu end>
                      {authState?.employee_id ===
                        postData?.post_creator?.employee_id &&
                        wallActiveStatus === 1 &&
                        !projectData?.isTaskSprintCompleted && (
                          <>
                            <DropdownItem onClick={onClickEditHandler}>
                              Edit
                            </DropdownItem>
                            <DropdownItem onClick={onClickDeleteHandler}>
                              Delete
                            </DropdownItem>
                          </>
                        )}
                      <DropdownItem
                        onClick={() => copyToClipboard(postData?.post_id)}>
                        Copy Link
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </div>
              )}
            </div>
          </CardHeader>
          <div className="content-container">
            <CardBody className="pt-1 px-0 pb-0">
              {projectData && (
                <div>
                  {projectData?.isKanbanPost ? (
                    <>
                      <Row className="m-0  mb-3">
                        <Col className="ps-1">
                          <h6 className="text-primary font-semi-bold">
                            Project Name
                          </h6>
                          <span className="card-text-content card-text-content font-regular">
                            {projectData?.projectName}
                          </span>
                        </Col>
                      </Row>
                      <div className="p-3 assign-feedback mb-3">
                        <Row className="mb-1">
                          <Col xxl="3" xl="3" md="3" className="">
                            <h6 className="text-primary pb-1 font-semi-bold">
                              Status
                            </h6>
                            <span className="font-regular">
                              {projectData?.taskStatus}
                            </span>
                          </Col>
                          <Col xxl="3" xl="3" md="3" className="">
                            <h6 className="text-primary pb-1 font-semi-bold">
                              Priority
                            </h6>
                            <div className="d-flex align-items-center">
                              <PriorityIcon
                                priorityId={projectData?.priorityId}
                              />
                              <div className="ml-2">
                                <span className="font-regular">
                                  {projectData?.priority}
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col xxl="3" xl="3" md="3" className="">
                            <h6 className="text-primary pb-1 font-semi-bold">
                              Sprint
                            </h6>
                            <span className="font-regular">
                              {projectData?.sprint}
                            </span>
                          </Col>
                          <Col xxl="3" xl="3" md="3" className="">
                            <h6 className="text-primary pb-1 font-semi-bold">
                              Sprint Status
                            </h6>
                            <span className="font-regular">
                              {projectData?.sprintStatus}
                            </span>
                          </Col>
                        </Row>
                      </div>
                      <div className="p-3 assign-feedback mb-3">
                        <Row className="">
                          <div>
                            <h6 className="text-primary font-semi-bold">
                              Task Title
                            </h6>
                            <span className="font-regular">
                              {projectData?.description}
                            </span>
                          </div>
                        </Row>
                      </div>
                      <div className="p-3 assign-feedback mb-3">
                        <Row className="">
                          <div>
                            <h6 className="text-primary font-semi-bold">
                              Task Description
                            </h6>
                            <span
                              className="description-content card-text-content font-regular"
                              style={{ whiteSpace: 'pre-line' }}
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: filterLinks(
                                  projectData?.task_description
                                )
                              }}
                            />
                          </div>
                        </Row>
                      </div>
                      {postData?.wall_project?.is_kanban_post && (
                        <div className="p-3 assign-feedback mb-3">
                          <Row className="">
                            <div>
                              <h6 className="text-primary font-semi-bold">
                                Development
                              </h6>
                              <GitDetails
                                taskData={postData?.wall_project}
                                isInKanban={false}
                              />
                            </div>
                          </Row>
                        </div>
                      )}
                    </>
                  ) : (
                    <>
                      <div className="card-section px-1 py-0 mb-3">
                        <span
                          className="description-content card-text-content font-regular"
                          style={{ whiteSpace: 'pre-line' }}
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: filterLinks(projectData?.description)
                          }}
                        />
                      </div>
                      <div
                        className="card-inner-body"
                        style={{ whiteSpace: 'pre-line' }}>
                        <Row className="m-0  mb-3">
                          <Col className="ps-1">
                            <h6 className="text-primary font-semi-bold">
                              Project Name
                            </h6>
                            <span className="card-text-content card-text-content font-regular">
                              {projectData?.projectName}
                            </span>
                          </Col>
                        </Row>
                        <Row className="m-0  mb-3">
                          <Col className="ps-1">
                            <h6 className="text-primary font-semi-bold">
                              Project Description
                            </h6>
                            <span
                              className="card-text-content font-regular"
                              style={{ whiteSpace: 'pre-line' }}
                              // eslint-disable-next-line react/no-danger
                              dangerouslySetInnerHTML={{
                                __html: filterLinks(
                                  projectData?.project_description
                                )
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </>
                  )}

                  <div
                    className="card-inner-body"
                    style={{ whiteSpace: 'pre-line' }}>
                    {(authState.role === '0' &&
                      postData.allowed_like_and_comment === 1) ||
                    hideActions
                      ? ''
                      : wallActiveStatus === 1 &&
                        !isWallMentee &&
                        isWallMember &&
                        !projectData?.isKanbanPost && (
                          <div className="p-3 assign-feedback mb-3">
                            <Row className="">
                              <div>
                                <h6 className="text-primary font-semi-bold">
                                  Feedback
                                </h6>
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <Row className="container-fluid p-0 mt-3">
                                    <Col xl={3} lg={6}>
                                      <Label
                                        for="Assignee"
                                        className="font-semi-bold">
                                        Assignee
                                      </Label>
                                      <Controller
                                        name="assignee"
                                        control={control}
                                        rules={{
                                          required: true
                                        }}
                                        render={({ field }) => (
                                          <SelectField
                                            className="font-regular"
                                            errors={errors.assignee}
                                            options={feedBackAssigneeList}
                                            field={field}
                                            isMulti={false}
                                            formatOptionLabel={
                                              formatOptionLabel
                                            }
                                            placeholder={
                                              <div className="font-regular">
                                                Select
                                              </div>
                                            }
                                            components={{
                                              IndicatorSeparator: () => null,
                                              DropdownIndicator
                                            }}
                                          />
                                        )}
                                      />
                                    </Col>
                                    <Col xl={2} lg={6}>
                                      <Label
                                        for="Rating"
                                        className="font-semi-bold">
                                        Rating
                                      </Label>
                                      <Controller
                                        name="rating"
                                        control={control}
                                        render={({ field }) => (
                                          <SelectField
                                            className="font-regular"
                                            errors={errors.rating}
                                            options={rating}
                                            field={field}
                                            isMulti={false}
                                            placeholder={
                                              <div className="font-regular">
                                                Rating
                                              </div>
                                            }
                                            components={{
                                              IndicatorSeparator: () => null,
                                              DropdownIndicator
                                            }}
                                          />
                                        )}
                                      />
                                    </Col>
                                    <Col xl={5} lg={12}>
                                      <Label
                                        for="Feedback"
                                        className="font-semi-bold">
                                        Comment
                                      </Label>
                                      <Controller
                                        name="feedback"
                                        control={control}
                                        rules={{
                                          required: true
                                        }}
                                        render={({ field }) => (
                                          <InputField
                                            errors={errors.feedback}
                                            className="text-area-field font-regular"
                                            field={field}
                                            style={{
                                              height: `${feedbackHeight}px`
                                            }}
                                            name="feedback"
                                            inputType="textarea"
                                            placeholder="Type something..."
                                          />
                                        )}
                                      />
                                    </Col>

                                    <Col xl={2} lg={6}>
                                      <Button
                                        disabled={feedbackLoader}
                                        className="submit-btn"
                                        color="primary">
                                        Submit
                                      </Button>
                                    </Col>
                                  </Row>
                                </form>
                              </div>
                            </Row>
                          </div>
                        )}
                    <Row className="m-0 ">
                      {projectData?.assignees.map((assigneeData, index) => (
                        <div
                          key={assigneeData?.id}
                          className="grid-assignees p-3 mt-3">
                          <Row className="mb-1">
                            <Col
                              xxl={projectData?.isKanbanPost ? '9' : '6'}
                              xl={projectData?.isKanbanPost ? '9' : '6'}
                              md="5"
                              className="">
                              <h6 className="assignee-text pb-1 font-semi-bold">
                                Assignee
                              </h6>
                            </Col>
                            <Col xxl="3" xl="3" md="4" className="">
                              <h6 className="assignee-text pb-1 font-semi-bold">
                                Status
                              </h6>
                            </Col>
                            {!projectData?.isKanbanPost && (
                              <Col xxl="3" xl="3" md="3" className="">
                                <h6 className="assignee-text pb-1 font-semi-bold">
                                  Rating
                                </h6>
                              </Col>
                            )}
                          </Row>
                          <Row>
                            <Col
                              xxl={projectData?.isKanbanPost ? '9' : '6'}
                              xl={projectData?.isKanbanPost ? '9' : '6'}
                              md="5"
                              className="mb-3">
                              <span className="assignee-data">
                                {assigneeData?.profilePic ? (
                                  <img
                                    className="user-image rounded-circle"
                                    src={assigneeData?.profilePic}
                                    alt="profilePic"
                                  />
                                ) : (
                                  <Avatar
                                    name={assigneeData?.assigneeName}
                                    round
                                    maxInitials={3}
                                    size="38"
                                  />
                                )}

                                <span
                                  className="px-3 my-auto font-regular cursor-pointer"
                                  role="presentation"
                                  onClick={() =>
                                    onViewProfile(
                                      assigneeData?.assigneeID,
                                      navigate
                                    )
                                  }>
                                  {assigneeData?.assigneeName}
                                </span>
                              </span>
                            </Col>
                            <Col xxl="3" xl="3" md="4" className="p-1">
                              {assigneeData?.assigneeID ===
                                authState?.employee_id &&
                              status?.value !== 'Completed' &&
                              postData.allowed_like_and_comment === 0 &&
                              !hideActions ? (
                                <Controller
                                  name="status"
                                  control={control}
                                  render={({ field: { onChange } }) => (
                                    <SelectField
                                      className="font-regular"
                                      field={onChange}
                                      options={assigneeStatus}
                                      value={status}
                                      onChange={(event) =>
                                        onChangeStatus(event, index)
                                      }
                                    />
                                  )}
                                />
                              ) : (
                                <span
                                  className="assignee-status font-bold"
                                  style={{
                                    backgroundColor: `${
                                      // eslint-disable-next-line no-nested-ternary
                                      assigneeData?.status === 'Completed'
                                        ? '#BDDFBD'
                                        : assigneeData?.status === 'Assigned'
                                        ? '#B4E2FC'
                                        : '#eff57f'
                                    }`,
                                    color: `${
                                      // eslint-disable-next-line no-nested-ternary
                                      assigneeData?.status === 'Completed'
                                        ? '#2D832D'
                                        : assigneeData?.status === 'Assigned'
                                        ? '#176998'
                                        : '#d49408'
                                    }`
                                  }}>
                                  {assigneeData?.assigneeID ===
                                  authState?.employee_id
                                    ? status?.value
                                    : assigneeData?.status}
                                </span>
                              )}
                            </Col>
                            {!projectData?.isKanbanPost && (
                              <Col xxl="3" xl="3" md="3" className="">
                                <span className="assignee-data">
                                  <span className="">
                                    {avgRating(assigneeData)}
                                  </span>
                                </span>
                              </Col>
                            )}
                          </Row>
                          {assigneeData?.feedback?.length &&
                          ((authState?.role === '0' &&
                            (!isWallMentee ||
                              postData.allowed_like_and_comment === 1)) ||
                            assigneeData?.assigneeID ===
                              authState?.employee_id ||
                            (!isWallMentee && isWallMember)) ? (
                            <div className="col-12">
                              <h6 className="assignee-text pt-3 font-semi-bold">
                                Feedback
                              </h6>
                              {authState?.role === '0' ||
                              assigneeData?.assigneeID ===
                                authState?.employee_id ||
                              (!isWallMentee && isWallMember)
                                ? assigneeData?.feedback?.map(
                                    (feedbackData) => (
                                      <div key={feedbackData.feedback_id}>
                                        {feedbackData?.feedback_given_by ===
                                          authState?.employee_id && (
                                          <Button
                                            title="Edit"
                                            color="none"
                                            onClick={() => {
                                              feedbackEditHandler(feedbackData);
                                            }}
                                            className="pr-2 feedback-edit">
                                            <i className="bi bi-pencil-square" />
                                          </Button>
                                        )}
                                        <StarRatings
                                          rating={Number(feedbackData?.rating)}
                                          starRatedColor="#e35156"
                                          starDimension="18px"
                                          starSpacing="0px"
                                        />
                                        <p
                                          className="pt-2 mb-0 font-regular"
                                          style={{ whiteSpace: 'pre-line' }}
                                          // eslint-disable-next-line react/no-danger
                                          dangerouslySetInnerHTML={{
                                            __html: filterLinks(
                                              feedbackData?.feedback
                                            )
                                          }}
                                        />
                                        <p className="assignee-text font-regular">
                                          Feedback by:&nbsp;
                                          <span
                                            className="font-semi-bold"
                                            style={{ color: 'black' }}>
                                            {feedbackData?.name}
                                          </span>
                                        </p>
                                      </div>
                                    )
                                  )
                                : ''}
                            </div>
                          ) : (
                            ''
                          )}
                        </div>
                      ))}
                    </Row>
                  </div>
                  {postData.post_attachments.length > 0 &&
                    attachmentPreview(postData.post_attachments)}
                </div>
              )}
            </CardBody>
            {authState.role === '0' &&
            postData.allowed_like_and_comment === 1 &&
            !isPostUpdated &&
            postData.comment_count === 0 &&
            taggedEmployeesList?.length === 0 ? (
              ''
            ) : (
              <CardFooter className="d-flex flex-wrap gap-3 px-0 py-0 mt-3">
                <div className="d-flex flex-grow-1">
                  {!hideActions && (isWallMember || authState.role === '0') ? (
                    <div className="d-flex gap-3">
                      {authState.role === '0' &&
                      postData.allowed_like_and_comment === 1
                        ? ''
                        : wallActiveStatus === 1 && (
                            <>
                              <div>
                                <Likes likeDetails={postData} />
                              </div>
                              <div
                                id={`post-${postData.post_id}-cmt`}
                                aria-hidden="true"
                                role="button"
                                onClick={() => setAddComment(!addComment)}>
                                <i className="bi bi-chat-left-fill text-primary" />
                                <span className="px-2 text-sub font-regular hide-text">
                                  {' '}
                                  Comment
                                </span>
                                <Tooltip
                                  placement="top"
                                  isOpen={tooltipOpen}
                                  toggle={toggle}
                                  autohide={false}
                                  target={`post-${postData.post_id}-cmt`}>
                                  Post a comment
                                </Tooltip>
                              </div>
                            </>
                          )}
                      {postData.comment_count > 0 ? (
                        <div
                          aria-hidden="true"
                          role="button"
                          onClick={openComments}>
                          <Comments commentDetails={postData} />
                        </div>
                      ) : (
                        ''
                      )}
                      &nbsp;
                    </div>
                  ) : (
                    ''
                  )}
                  {taggedEmployeesList?.length > 0 && (
                    <TaggedEmployees taggedPeople={taggedEmployeesList} />
                  )}
                </div>

                {isPostUpdated && (
                  <div className="flex-grow-1 text-end">
                    <span className="text-sub font-regular time-text">
                      {'Created at: '}
                      {timeago(postData?.created_at)}
                    </span>
                  </div>
                )}
              </CardFooter>
            )}
            <div className="comments-container mt-2">
              {addComment && (
                <div className="m-4">
                  <Comment postData={postData} getPostList={getPostList} />
                </div>
              )}
              {isOpen && (
                <div className="">
                  <UserComments
                    postID={postData.post_id}
                    parentId={postData.parent_comment_id}
                    getPostList={getPostList}
                    isView={postData.allowed_like_and_comment}
                    disabledReplyAndLike={projectData?.isKanbanPost}
                  />
                </div>
              )}
            </div>
          </div>
        </Card>
      </div>
    </div>
  );
}

export default WallProject;
