import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';

const initialState = {
  generalSessionsList: [],
  generalSessionsListItemDetails: null,
  assignSessionDetailsList: [],
  trainerList: [],
  viewType: 'add',
  loading: false,
  btnLoader: false,
  status: '',
  statusCode: null,
  error: ''
};
export const getGeneralSessionListActionCreator = createAsyncThunk(
  'assignSessions/generalSessionList',
  async () => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.ASSIGN_SESSION_SESSION_LIST}`
    );
    return data;
  }
);
export const createSessionListActionCreator = createAsyncThunk(
  'assignSessions/save',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.ASSIGN_SESSION_SAVE}`,
      params.body
    );
    return data;
  }
);
export const getTrainerListActionCreator = createAsyncThunk(
  'assignSessions/trainerList',
  async () => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.ASSIGN_SESSION_TRAINER_LIST}`
    );
    return data;
  }
);
export const deleteGeneralSessionListActionCreator = createAsyncThunk(
  'assignSessions/delete',
  async (id) => {
    const { data } = await fetchUtility(
      'delete',
      `${SERVER_URL.ASSIGN_SESSION_DELETE}${id}`
    );
    return data;
  }
);
export const updateSessionListActionCreator = createAsyncThunk(
  'assignSessions/update',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.ASSIGN_SESSION_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);
export const getSessionListItemDetailActionCreator = createAsyncThunk(
  'assignSessions/detail',
  async (id) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.ASSIGN_SESSION_ITEM_DETAIL}${id}`
    );
    return data;
  }
);

export const getassignSessionDetailsListActionCreator = createAsyncThunk(
  'assignSessionsList/detail',
  async (id) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.ASSIGN_SESSION_DETAIL}${id}`
    );
    return data;
  }
);

export const getAssignDetailSessionStatusCompleteActionCreator =
  createAsyncThunk('assignSessions/detail/MarkCompleted', async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.ASSIGN_SESSION_DETAIL_STATUS_MARKCOMPLETE}${params.assign_session_id}`,
      params.body
    );
    return { data, insertedData: { ...params } };
  });

const assignSessionSlice = createSlice({
  name: 'assignSession',
  initialState,
  reducers: {
    assignSession: (state, { payload }) => {
      state.viewType = payload.viewType;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getGeneralSessionListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getGeneralSessionListActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.generalSessionsList = action.payload.result;
        state.error = '';
      }
    );
    builder.addCase(
      getGeneralSessionListActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.code;
      }
    );

    builder.addCase(createSessionListActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(createSessionListActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(
      createSessionListActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(getTrainerListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTrainerListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.trainerList = action.payload.result;
      state.error = '';
    });
    builder.addCase(getTrainerListActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.code;
    });

    builder.addCase(deleteGeneralSessionListActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(
      deleteGeneralSessionListActionCreator.fulfilled,
      (state) => {
        state.btnLoader = false;
        state.error = '';
      }
    );
    builder.addCase(
      deleteGeneralSessionListActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(getSessionListItemDetailActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(
      getSessionListItemDetailActionCreator.fulfilled,
      (state, action) => {
        if (action.payload.result)
          state.generalSessionsListItemDetails = { ...action.payload.result };
        state.btnLoader = false;
        state.error = '';
      }
    );
    builder.addCase(
      getSessionListItemDetailActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(updateSessionListActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateSessionListActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(
      updateSessionListActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );

    // Assign session details

    builder.addCase(
      getassignSessionDetailsListActionCreator.pending,
      (state) => {
        // state.assignSessionDetailsList = [];
        state.loading = true;
      }
    );
    builder.addCase(
      getassignSessionDetailsListActionCreator.fulfilled,
      (state, action) => {
        state.assignSessionDetailsList = action.payload.result;
        state.loading = false;
        state.error = '';
      }
    );
    builder.addCase(
      getassignSessionDetailsListActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(
      getAssignDetailSessionStatusCompleteActionCreator.pending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addCase(
      getAssignDetailSessionStatusCompleteActionCreator.fulfilled,
      (state, action) => {
        const index =
          state.assignSessionDetailsList.assignSessionDetails.findIndex(
            (assignSessionDetail) =>
              assignSessionDetail.assign_session_id ===
              action?.payload?.insertedData.assign_session_id
          );
        if (index !== -1) {
          state.assignSessionDetailsList.assignSessionDetails[
            index
          ].session_status = 2;
        }
        state.loading = false;
        state.error = '';
      }
    );
    builder.addCase(
      getAssignDetailSessionStatusCompleteActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
  }
});

export const { assignSession } = assignSessionSlice.actions;
export default assignSessionSlice.reducer;
