import React, { useState } from 'react';
import './CopyInput.scss';
import { UncontrolledTooltip } from 'reactstrap';
import CopyIcon from '../IconComponents/CopyIcon';

export default function CopyInput({ label, value }) {
  const [showSuccess, setShowSuccess] = useState(false);

  const copyContent = () => {
    navigator.clipboard.writeText(value);
    setShowSuccess(true);
  };

  return (
    <>
      {label && <p className="font-bold text-secondary small mb-1">{label}</p>}
      <div className="d-flex align-items-center mb-3">
        <input
          type="text"
          className="form-control copy-input"
          placeholder="Task key"
          value={value}
          readOnly
        />

        <div
          id="copyInputId"
          role="presentation"
          onClick={copyContent}
          onMouseLeave={() => setShowSuccess(false)}
          className="ms-1 text-primary copy-btn">
          <CopyIcon />
        </div>
      </div>

      <UncontrolledTooltip
        placement="top"
        target="copyInputId"
        isOpen={showSuccess}>
        Copied!
      </UncontrolledTooltip>
    </>
  );
}
