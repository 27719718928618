import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';
import './Comments.scss';

function Comments({ commentDetails }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  return (
    <div className="d-inline-flex" id={`cmt-icon-${commentDetails.post_id}`}>
      <i className="bi bi-reply-fill div-rply-icon text-primary" />
      <span className="px-2 text-sub font-regular ">
        {commentDetails?.comment_count === 0
          ? `Comment`
          : `${commentDetails?.comment_count}`}
      </span>
      <span className="text-sub font-regular hide-text">Comments </span>
      {/* <span className="hoverText">View Comments</span> */}
      <Tooltip
        className="hoverText"
        placement="top"
        toggle={toggle}
        isOpen={tooltipOpen}
        autohide={false}
        target={`cmt-icon-${commentDetails.post_id}`}>
        View Comments
      </Tooltip>
    </div>
  );
}

export default Comments;
