import { googleLogout } from '@react-oauth/google';
import Cookie from 'js-cookie';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { logoutUser } from '../../redux/slices/LoginSlice';
import './SessionExpired.scss';

function SessionExpired() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const logout = async () => {
    Cookie.remove('credentials');
    await googleLogout();
    dispatch(logoutUser()).then(() => {
      navigate('/session-expired');
    });
  };

  const redirectToLogin = () => {
    navigate('/login');
  };

  useEffect(() => {
    logout();
  }, []);

  return (
    <div className="notfound">
      <div className="notfound-404">
        <h3>Oops! Session expired !</h3>
        <h1>
          <span>4</span>
          <span>0</span>
          <span>1</span>
        </h1>
      </div>
      <h5>Login again to continue</h5>
      <Button
        className="mt-3"
        type="button"
        outline
        color="primary"
        onClick={redirectToLogin}>
        Go to Login
      </Button>
    </div>
  );
}

export default SessionExpired;
