import React from 'react';

import WallReportList from './pages/wall/WallReportList';
import WallUnavailable from './shared/wall/wall-unavailable/WallUnavailable';
import SessionExpired from './pages/auth/SessionExpired';

import { additionalRolesObject } from './shared/constants/ConstantsData';

const Login = React.lazy(() => import('./pages/auth/Login'));
const AssignWall = React.lazy(() => import('./pages/assign-wall/AssignWall'));
const Topic = React.lazy(() => import('./pages/topic/Topic'));
const TopicForms = React.lazy(() => import('./pages/topic/TopicForms'));
const Curriculum = React.lazy(() => import('./pages/curriculum/Curriculum'));
const CurriculumForms = React.lazy(() =>
  import('./pages/curriculum/CurriculumForms')
);
const Users = React.lazy(() => import('./pages/user/User'));
const Wall = React.lazy(() => import('./pages/wall/Wall'));
const WallNotAdded = React.lazy(() => import('./pages/wall/WallNotAdded'));
const Project = React.lazy(() => import('./pages/project/Project'));
const ProjectForms = React.lazy(() => import('./pages/project/ProjectForm'));
const Batch = React.lazy(() => import('./pages/batch/Batch'));
const BatchForms = React.lazy(() => import('./pages/batch/BatchForms'));
const Report = React.lazy(() => import('./pages/report/Report'));
const ViewCurriculum = React.lazy(() =>
  import('./pages/view-curriculum/viewCurriculum')
);
const AssignWallForms = React.lazy(() =>
  import('./pages/assign-wall/AssignWallForms')
);
const WallList = React.lazy(() => import('./pages/wall-list/WallList'));
const WallListForms = React.lazy(() =>
  import('./pages/wall-list/WallListForms')
);
const AssignSession = React.lazy(() =>
  import('./pages/assign-session/AssignSession')
);
const AssignSessionForms = React.lazy(() =>
  import('./pages/assign-session/AssignSessionForms')
);
const AssignSessionDetails = React.lazy(() =>
  import('./pages/assign-session/AssignSessionDetails')
);
const Sessions = React.lazy(() => import('./pages/sessions/Sessions'));
const SessionsForms = React.lazy(() =>
  import('./pages/sessions/SessionsForms')
);
const Mentors = React.lazy(() => import('./pages/team-list/Mentors'));
const WallPostForms = React.lazy(() => import('./pages/wall/WallPostForms'));
const Profile = React.lazy(() => import('./pages/profile/Profile'));
const RequestFeedback = React.lazy(() =>
  import('./pages/request-feedback/RequestFeedback/RequestFeedback')
);
const MenteeFeedbackList = React.lazy(() =>
  import('./pages/request-feedback/RequestFeedback/MenteeFeedbackList')
);
const FeedbackDetails = React.lazy(() =>
  import('./pages/request-feedback/FeedbackDetails/FeedbackDetails')
);
const MenteeFeedbackFrom = React.lazy(() =>
  import('./pages/request-feedback/MenteeFeedbackForm/MenteeFeedbackForm')
);
const InternalInitiatives = React.lazy(() =>
  import('./pages/internal-initiatives/InternalInitiatives')
);
const MentorLeaderboard = React.lazy(() =>
  import('./pages/mentor-leaderboard/mentorLeaderboard')
);

const KanbanBoard = React.lazy(() => import('./pages/kanban/board/board'));
const SprintBoard = React.lazy(() =>
  import('./pages/kanban/sprint/sprintBoard')
);
const KanbanProjects = React.lazy(() =>
  import('./pages/kanban/kanbanProjects/kanbanProjects')
);

const GitLabCallbackPage = React.lazy(() =>
  import(
    './pages/kanban/board/board-components/GitLabCallbackPage/GitLabCallbackPage'
  )
);

const LinkProjectToGitLabForm = React.lazy(() =>
  import(
    './pages/kanban/board/board-components/LinkProjectToGitLabForm/LinkProjectToGitLabForm'
  )
);

const UnlinkProjectToGitLabForm = React.lazy(() =>
  import(
    './pages/kanban/board/board-components/UnlinkProjectToGitLabForm/UnlinkProjectToGitLabForm'
  )
);
const GitLabCreateBranch = React.lazy(() =>
  import(
    './pages/kanban/board/board-components/GitLabCreateBranch/GitLabCreateBranch'
  )
);

// 1=admin,2=Mentor,3=Mentee,4=Architect,5=Designer // old version

// 0=admin, 1=mentee, 2=mentor/architect/designer of 1 or more walls  //new version

const routes = [
  {
    path: '/user',
    name: 'Users',
    component: <Users />,
    isMenu: true,
    iconClassName: 'bi-person-fill',
    allowedRoles: ['0']
  },
  {
    path: '/mentors',
    name: 'Mentors',
    component: <Mentors />,
    isMenu: true,
    iconClassName: 'bi-person-video3',
    allowedRoles: ['0']
  },
  {
    path: '/internal-initiatives',
    name: 'Internal Initiatives',
    component: <InternalInitiatives />,
    isMenu: false,
    iconClassName: 'bi bi-lightbulb',
    allowedRoles: ['0', '1', '2']
  },
  {
    path: '/assign-wall',
    name: 'Assign Wall',
    component: <AssignWall />,
    isMenu: false,
    iconClassName: 'bi bi-people-fill',
    allowedRoles: ['0', '2']
  },
  {
    path: '/assign-wall/add-assign-wall',
    name: 'Assign Wall',
    component: <AssignWallForms />,
    isMenu: false,
    allowedRoles: ['0', '2']
  },
  {
    path: '/assign-wall/edit-assign-wall/:assignWallID',
    name: 'Assign Wall',
    component: <AssignWallForms />,
    isMenu: false,
    allowedRoles: ['0', '2']
  },
  {
    path: '/wall/:wallID',
    name: 'Wall',
    component: <Wall />,
    isMenu: true,
    iconClassName: 'bi bi-person-video2',
    allowedRoles: ['0', '1', '2']
  },
  {
    path: '/wall/:wallID/:postID',
    name: 'WallPost',
    component: <Wall />,
    isMenu: false,
    iconClassName: 'bi bi-person-video2',
    allowedRoles: ['0', '1', '2']
  },
  {
    path: '/wall/:wallID/:adminView',
    name: 'Wall',
    component: <Wall />,
    isMenu: false,
    iconClassName: 'bi bi-person-video2',
    allowedRoles: ['0']
  },
  {
    path: '/wall-not-added',
    name: 'WallNotAdded',
    component: <WallNotAdded />,
    isMenu: false,
    iconClassName: 'bi bi-person-video2',
    allowedRoles: ['0', '1', '2']
  },
  {
    path: `/report-view-Curriculum/:postId/:userId`,
    name: 'Curriculum',
    component: <ViewCurriculum />,
    isMenu: false,
    iconClassName: 'bi bi-book-fill',
    allowedRoles: ['0', '1', '2']
  },
  {
    path: '/topics/add-topic',
    name: 'Topics',
    component: <TopicForms />,
    isMenu: false,
    allowedRoles: ['0']
  },
  {
    path: '/topics/edit-topic',
    name: 'Topics',
    component: <TopicForms />,
    isMenu: false,
    allowedRoles: ['0']
  },
  {
    path: '/projects/add-project',
    name: 'Projects',
    component: <ProjectForms />,
    isMenu: false,
    allowedRoles: ['0']
  },
  {
    path: '/projects/edit-project/:projectID',
    name: 'Projects',
    component: <ProjectForms />,
    isMenu: false,
    allowedRoles: ['0', '2']
  },
  {
    path: '/curriculum/add-curriculum',
    name: 'Curriculum',
    component: <CurriculumForms />,
    isMenu: false,
    allowedRoles: ['0']
  },
  {
    path: '/curriculum/edit-curriculum',
    name: 'Curriculum',
    component: <CurriculumForms />,
    isMenu: false,
    allowedRoles: ['0']
  },
  {
    path: '/batch/add-batch',
    name: 'Batch Form',
    component: <BatchForms />,
    isMenu: false,
    allowedRoles: ['0']
  },
  {
    path: '/batch/edit-batch/:batchID',
    name: 'Batch Form',
    component: <BatchForms />,
    isMenu: false,
    allowedRoles: ['0']
  },
  {
    path: '/report',
    name: 'Reports',
    component: <Report />,
    isMenu: true,
    iconClassName: 'bi bi-file-ruled',
    allowedRoles: ['0']
  },
  {
    path: '/wall-list/add-wall-list',
    name: 'Wall List Form',
    component: <WallListForms />,
    isMenu: false,
    allowedRoles: ['0', '2']
  },
  {
    path: '/wall-list/edit-wall-list/:wallListID',
    name: 'Wall List Form',
    component: <WallListForms />,
    isMenu: false,
    allowedRoles: ['0', '2']
  },
  {
    path: '/assign-session',
    name: 'Assign Sessions',
    component: <AssignSession />,
    iconClassName: 'bi bi-list-task',
    isMenu: true,
    allowedRoles: ['0']
  },
  {
    path: '/assign-session/add-assign-session',
    name: 'Assign session Form',
    component: <AssignSessionForms />,
    isMenu: false,
    allowedRoles: ['0']
  },
  {
    path: '/assign-session/edit-assign-session/:sessionListID',
    name: 'Assign session Form',
    component: <AssignSessionForms />,
    allowedRoles: ['0']
  },
  {
    path: '/assign-session/session-assign-detail/:sessionDetailId',
    name: 'Session Assign Detail',
    component: <AssignSessionDetails />,
    isMenu: false,
    allowedRoles: ['0']
  },
  {
    path: '/sessions/add-session/',
    name: 'Session Form',
    component: <SessionsForms />,
    isMenu: false,
    allowedRoles: ['0']
  },
  {
    path: '/sessions/edit-session/:sessionID',
    name: 'Session Form',
    component: <SessionsForms />,
    isMenu: false,
    allowedRoles: ['0']
  },
  {
    path: '/wall/:wallID/post-form/:postID',
    name: 'Wall Post Form',
    component: <WallPostForms />,
    isMenu: false,
    iconClassName: 'bi bi-person-video2',
    allowedRoles: ['0', '1']
  },
  {
    path: '/profile/:employeeID',
    name: 'Profile',
    component: <Profile />,
    isMenu: false,
    allowedRoles: ['0', '1', '2']
  },
  {
    path: '/wall-report',
    name: 'Wall Report',
    component: <WallReportList />,
    isMenu: false,
    iconClassName: 'bi bi-file-ruled',
    allowedRoles: ['0', '1', '2']
  },
  {
    path: '/performance-report/:performanceReportID',
    name: 'Performance Report',
    component: <WallReportList />,
    isMenu: false,
    iconClassName: 'bi bi-file-ruled',
    allowedRoles: ['0', '1', '2']
  },
  {
    path: '/wall-unavailable',
    name: 'Wall unavailable',
    component: <WallUnavailable />,
    isMenu: false,
    allowedRoles: ['0', '2', '1']
  },
  {
    path: '/request-feedback',
    name: 'Request Feedback',
    component: <RequestFeedback />,
    isMenu: true,
    iconClassName: 'bi-chat-right-dots',
    allowedRoles: ['0'],
    additionalRoles: [additionalRolesObject.admin]
  },
  {
    path: '/feedback-detail/:feedbackID',
    name: 'Feedback Details',
    component: <FeedbackDetails />,
    isMenu: false,
    iconClassName: 'bi-chat-right-dots',
    allowedRoles: ['0']
  },
  {
    path: '/mentee-feedback-form/:feedbackID',
    name: 'Mentee Feedback Form',
    component: <MenteeFeedbackFrom />,
    isMenu: false,
    iconClassName: 'bi-chat-right-dots',
    allowedRoles: ['0', '2']
  },
  {
    path: '/mentee-feedback-form/review/:virtualTeamHeadId/:feedbackID',
    name: 'Mentee Feedback Form',
    component: <MenteeFeedbackFrom />,
    isMenu: false,
    iconClassName: 'bi-chat-right-dots',
    allowedRoles: ['0', '2']
  },
  {
    path: '/mentor-leaderboard',
    name: 'Leaderboard',
    component: <MentorLeaderboard />,
    isMenu: true,
    iconClassName: 'bi bi-trophy',
    allowedRoles: ['0']
  },
  {
    path: '/mentee-feedback-list',
    name: 'Mentee Feedback',
    component: <MenteeFeedbackList />,
    isMenu: false,
    allowedRoles: ['0', '2']
  },
  {
    path: '/kanban/board/:wallId',
    name: 'Board',
    component: <KanbanBoard />,
    isMenu: false,
    allowedRoles: ['0', '1', '2']
  },
  {
    path: '/kanban/sprintBoard/:wallId',
    name: 'Sprint',
    component: <SprintBoard />,
    isMenu: false,
    allowedRoles: ['0', '2']
  },
  {
    path: '/kanban/kanbanProjects',
    name: 'Sprint',
    component: <KanbanProjects />,
    isMenu: false,
    allowedRoles: ['0', '1', '2']
  },
  {
    path: '/gitlab-callback',
    name: 'GitLabCallbackPage',
    component: <GitLabCallbackPage />,
    isMenu: false,
    allowedRoles: ['0', '1', '2']
  },
  {
    path: '/gitlab-linkProjectToGitLabForm/:wallId',
    name: 'LinkProjectToGitLabForm',
    component: <LinkProjectToGitLabForm />,
    isMenu: false,
    allowedRoles: ['0', '1', '2']
  },
  {
    path: '/gitlab-unlinkProjectFromGitLabForm/:wallId/:projectId',
    name: 'LinkProjectToGitLabForm',
    component: <UnlinkProjectToGitLabForm />,
    isMenu: false,
    allowedRoles: ['0', '1', '2']
  },
  {
    path: '/gitlab-create-branch/:projectId/:taskId/:titleName/:repoName',
    name: 'GitLabCreateBranch',
    component: <GitLabCreateBranch />,
    isMenu: false,
    allowedRoles: ['0', '1', '2']
  }
];

const subNavRoutes = [
  {
    path: '/sessions',
    name: 'Sessions',
    component: <Sessions />,
    isMenu: false,
    allowedRoles: ['0']
  },
  {
    path: '/topics',
    name: 'Topics',
    component: <Topic />,
    isMenu: false,
    allowedRoles: ['0']
  },
  {
    path: '/curriculum-list',
    name: 'Curriculum',
    component: <Curriculum />,
    isMenu: false,
    allowedRoles: ['0']
  },
  {
    path: '/projects',
    name: 'Projects',
    component: <Project />,
    isMenu: false,
    allowedRoles: ['0', '2']
  },
  {
    path: '/batch',
    name: 'Batch',
    component: <Batch />,
    isMenu: false,
    allowedRoles: ['0']
  },
  {
    path: '/wall-list',
    name: 'Wall List',
    component: <WallList />,
    isMenu: false,
    allowedRoles: ['0', '2']
  }
];

const publicRoute = [
  {
    path: '/login',
    name: 'Login',
    component: <Login />,
    isMenu: false
    // allowedRoles: [0, 1, 2]
  },
  {
    path: '/',
    name: '',
    component: <Login />,
    isMenu: false
    // allowedRoles: [0, 1, 2]
  },
  {
    path: '/session-expired',
    name: '',
    component: <SessionExpired />,
    isMenu: false
    // allowedRoles: [0, 1, 2]
  }
];

export { routes, subNavRoutes, publicRoute };
