import React, { useState } from 'react';
import './BranchesTab.scss';
import { openUrlInNewPage } from '../../../../../../../shared/helper/commonFunction';
import BranchRow from '../BranchRow/BranchRow';

export default function BranchesTab({ gitDetails }) {
  const [showBranchesRows, setShowBranchesRows] = useState(true);

  const toggleShowBranchRows = () => {
    setShowBranchesRows((value) => !value);
  };

  return (
    <div className="scroll-branch-content">
      <div className="hover-highlight p-2 my-3 d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div
            role="presentation"
            className="d-flex align-items-center cursor-pointer text-dark me-3"
            onClick={toggleShowBranchRows}>
            <i
              className={`bi fw-500 ${
                showBranchesRows ? 'bi-chevron-down' : 'bi-chevron-right'
              }`}
            />
          </div>
          <h6
            role="presentation"
            onClick={() => openUrlInNewPage(gitDetails?.git_repo_url)}
            className="text-primary git-link cursor-pointer mb-0">
            {gitDetails?.git_repo_name}
          </h6>
        </div>
      </div>

      {showBranchesRows && (
        <div className="branch-details-table px-3">
          <div className="text-sub font-bold border-bottom">
            <div className="row">
              <div className="col-md-4">Branch</div>
              <div className="col-md-5">Pull request</div>
              <div className="col-md-3">Action</div>
            </div>
          </div>

          {gitDetails?.branches?.length ? (
            gitDetails?.branches.map((branch) => (
              <BranchRow branch={branch} key={branch.branch_name} />
            ))
          ) : (
            <p className="text-secondary text-center mt-3">No branches</p>
          )}
        </div>
      )}
    </div>
  );
}
