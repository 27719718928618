import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';

const initialState = {
  batchList: [],
  batchDetails: null,
  loading: false,
  status: '',
  statusCode: null,
  error: ''
};

export const getBatchListActionCreator = createAsyncThunk(
  'batch/list',
  async () => {
    const { data } = await fetchUtility('get', `${SERVER_URL.BATCH_LIST}`);
    return data;
  }
);

export const saveBatchActionCreator = createAsyncThunk(
  'batch/save',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.BATCH_CREATE}`,
      params.body
    );
    return data;
  }
);

export const getBatchDetailActionCreator = createAsyncThunk(
  'batch/detail',
  async (id) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.BATCH_DETAIL}${id}`
    );
    return data;
  }
);

export const deleteBatchActionCreator = createAsyncThunk(
  'batch/delete',
  async (id) => {
    const { data } = await fetchUtility(
      'delete',
      `${SERVER_URL.BATCH_DELETE}${id}`
    );
    return data;
  }
);

export const updateBatchActionCreator = createAsyncThunk(
  'batch/update',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.BATCH_UPDATE}${params.batch_id}`,
      params.body
    );
    return data;
  }
);

const batchSlice = createSlice({
  name: 'batch',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getBatchListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBatchListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.batchList = action.payload.result.map((x) => {
        const obj = {
          batch_id: x.batch_id,
          batch_name: x.batch_name,
          start_date: x.start_date,
          timezone: x.timezone
        };
        return obj;
      });
      state.error = '';
    });
    builder.addCase(getBatchListActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteBatchActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteBatchActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(deleteBatchActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(saveBatchActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveBatchActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(saveBatchActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateBatchActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updateBatchActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(updateBatchActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getBatchDetailActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getBatchDetailActionCreator.fulfilled, (state, action) => {
      if (action.payload.result)
        state.batchDetails = { ...action.payload.result };
      state.loading = false;
      state.error = '';
    });
    builder.addCase(getBatchDetailActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  }
});

export default batchSlice.reducer;
