import React from 'react';
import { Input, Label } from 'reactstrap';
import { validationMsg } from '../../helper/commonFunction';
import './Input.scss';

function InputField({
  field,
  onChange,
  value = '',
  label,
  errors,
  inputType,
  readOnly,
  disabled,
  rows,
  className,
  placeholder,
  autoComplete = 'off',
  isHidden = false,
  // defaultChecked = false,
  checked = false,
  style,
  options
}) {
  return (
    <>
      {label && (
        <Label className="font-semi-bold" for={label}>
          {label}
        </Label>
      )}
      {inputType === 'select' ? (
        <Input
          className={`${className} font-regular`}
          type={inputType}
          onChange={onChange}
          {...field}
          value={field?.value || field?.field?.value ? field.value : value}
          ref={field?.ref}
          disabled={disabled}
          readOnly={readOnly}
          rows={rows}
          placeholder={placeholder}
          autoComplete={autoComplete}
          hidden={isHidden}
          // defaultChecked={defaultChecked}
          checked={checked}
          id={label}
          style={style}>
          <option value="">Select an option</option>
          {options &&
            options.length &&
            options.map((option) => (
              <option key={option.id} value={option.value}>
                {option.label}
              </option>
            ))}
        </Input>
      ) : (
        <Input
          className={`${className} font-regular`}
          type={inputType}
          onChange={onChange}
          {...field}
          value={field?.value || field?.field?.value ? field.value : value}
          ref={field?.ref}
          disabled={disabled}
          readOnly={readOnly}
          rows={rows}
          placeholder={placeholder}
          autoComplete={autoComplete}
          hidden={isHidden}
          // defaultChecked={defaultChecked}
          checked={checked}
          id={label}
          style={style}
        />
      )}

      {errors && (
        <p className="input-field-error pt-2 mb-0 font-regular">
          {validationMsg(errors)}
        </p>
      )}
      {/* {errors && <p className="input-field-error pt-2 mb-0">This field is required</p>} */}
    </>
  );
}

export default InputField;
