import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';

const initialState = {
  virtualTeamHeadList: [],
  mentorDetails: [],
  loading: false,
  btnLoader: false,
  status: '',
  statusCode: null,
  error: ''
};

export const getVirtualTeamHeadListActionCreator = createAsyncThunk(
  'virtualTeamHead/list',
  async () => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.VIRTUAL_TEAM_HEAD_LIST}`,
      {}
    );
    return data;
  }
);

const VirtualTeamHeadSlice = createSlice({
  name: 'VirtualTeamHead',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getVirtualTeamHeadListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getVirtualTeamHeadListActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        state.virtualTeamHeadList = action.payload.result;
        state.error = '';
      }
    );
    builder.addCase(
      getVirtualTeamHeadListActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.code;
      }
    );
  }
});

export default VirtualTeamHeadSlice.reducer;
