import axios from 'axios';
import Cookie from 'js-cookie';
import { decryptCookieValue } from './commonFunction';

const fetchUtility = async (
  method,
  url,
  payload = {},
  headers = {},
  options = {},
  enableAuthorizationHeader = true
) =>
  axios({
    method,
    url,
    data: payload,
    headers: {
      Authorization: enableAuthorizationHeader
        ? `Bearer ${decryptCookieValue(Cookie.get('credentials')).token}` //  `Bearer ${JSON.parse(Cookie.get('credentials')).accessToken}`
        : '',
      ...headers
    },
    ...options
  });

export default fetchUtility;
