import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';

const initialState = {
  commentsList: {},
  replyLists: [],
  loading: false,
  btnLoader: false,
  error: ''
};

export const getCommentsParentListActionCreator = createAsyncThunk(
  'comments/list',
  async (param) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.COMMENTS_LIST}${param.postID}/${param.parentID}`
    );
    return data;
  }
);

export const updateCommentsActionCreator = createAsyncThunk(
  'comments/update',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.UPDATE_COMMENT}${params.id}`,
      params.body
    );
    return data;
  }
);

export const deleteCommentsActionCreator = createAsyncThunk(
  'comments/delete',
  async (params) => {
    const { data } = await fetchUtility(
      'delete',
      `${SERVER_URL.DELETE_COMMENT}${params.id}`,
      params.body
    );
    return data;
  }
);

const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getCommentsParentListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getCommentsParentListActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        if (action?.payload?.comments?.length > 0) {
          if (action.meta.arg.parentID === 0) {
            state.commentsList[action.payload.comments[0].post_id] =
              action?.payload?.comments;
          } else {
            const list = {};
            list.postID = action.payload.comments[0].post_id;
            list[action.payload.comments[0].parent_comment_id] =
              action.payload.comments;

            if (state.replyLists.length) {
              const postID = state.replyLists.find(
                (item) => item.postID === list.postID
              );

              if (postID)
                state.replyLists.map((item) => {
                  item[action.payload.comments[0].parent_comment_id] =
                    action.payload.comments;
                  return item;
                });
              else {
                state.replyLists.push(list);
              }
            } else state.replyLists = [list];
          }
        }
        state.error = '';
      }
    );
    builder.addCase(
      getCommentsParentListActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
    builder.addCase(updateCommentsActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateCommentsActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updateCommentsActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });
    builder.addCase(deleteCommentsActionCreator.pending, (state) => {
      state.btnLoader = true;
      state.loading = true;
    });
    builder.addCase(deleteCommentsActionCreator.fulfilled, (state, action) => {
      const postId = action?.meta?.arg?.postID;
      if (action?.meta?.arg?.parentID) {
        state.replyLists.map((item) => {
          let newItem;
          if (item.postID === postId) {
            newItem = item[action?.meta?.arg?.parentID].filter(
              (replyObj) => replyObj.comment_id !== action?.meta?.arg?.id
            );
          }
          item[action?.meta?.arg?.parentID] = newItem;
          return item;
        });
      } else {
        const commentItem = state.commentsList[postId].filter(
          (commentObj) => commentObj.comment_id !== action?.meta?.arg?.id
        );
        state.commentsList[postId] = commentItem;
      }
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(deleteCommentsActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });
  }
});

export default commentsSlice.reducer;
