import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';

const initialState = {
  postList: [],
  wallReportPostList: [],
  myTeamList: [],
  postListPage: [],
  postCurriculumDetails: [],
  postDetail: null,
  notificationList: [],
  loading: false,
  btnLoader: false,
  status: '',
  statusCode: null,
  error: '',
  postPage: 0,
  firstPageUpdate: false,
  postsFetched: false,
  keepOnlyFirstPage: false,
  isWallMember: false
};

export const getPostListActionCreator = createAsyncThunk(
  'post/page/list',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.POST_LIST}`,
      params
    );
    return data;
  }
);

export const shareWallReportActionCreator = createAsyncThunk(
  'post/report/share',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.WALL_REPORT_POST_SHARE}`,
      params
    );
    return { data };
  }
);

export const getReportPostListActionCreator = createAsyncThunk(
  'post/report/list',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.WALL_REPORT_POST_LIST}`,
      params.body
    );
    return data;
  }
);

export const getPerformanceReportListActionCreator = createAsyncThunk(
  'post/performance-report/list',
  async ({ performanceReportID, reportID }) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.WALL_PERFORMANCE_REPORT_POST_LIST}${performanceReportID}/${reportID}`
    );
    return data.result;
  }
);

export const getPostDetailActionCreator = createAsyncThunk(
  'post/detail',
  async ({ postID }) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.POST_DETAIL}${postID}`
    );
    return data.result;
  }
);

export const getpostCurriculumDetailActionCreator = createAsyncThunk(
  'post/curriculum/detail',
  async ({ postId, userId }) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.POST_CURRICULUM_DETAIL}${postId}/${userId}`
    );
    return data.result;
  }
);
export const savepostThanksActionCreator = createAsyncThunk(
  'post/thanks/save',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.POST_THANKS_CREATE}`,
      params.body
    );
    return { data };
  }
);

export const updateThanksActionCreator = createAsyncThunk(
  'walls/thanks/save',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.POST_THANKS_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);

export const savepostCurriculumCompleteActionCreator = createAsyncThunk(
  'post/curriculum/save',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.POST_CURRICULUM_TOPIC_COMPLETE}`,
      params
    );
    return { data, insertedData: { ...params } };
  }
);

export const saveEndorsementPostActionCreator = createAsyncThunk(
  'post/endorsement/save',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.POST_ENDORSEMENT_CREATE}`,
      params.body
    );
    return data;
  }
);

export const updateEndorsementActionCreator = createAsyncThunk(
  'walls/update_post_endorsement',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.POST_ENDORSEMENT_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);

export const saveKudosPostActionCreator = createAsyncThunk(
  'walls/create_post_kudos',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.WALL_KUDOS_POST}`,
      params.body
    );
    return data;
  }
);

export const updateKudosActionCreator = createAsyncThunk(
  'walls/update_post_kudos',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.WALL_KUDOS_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);

export const saveCurriculumPostActionCreator = createAsyncThunk(
  'walls/create_post_curriculum',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.WALL_CURRICULUM_POST}`,
      params.body
    );
    return data;
  }
);

export const updateCurriculumActionCreator = createAsyncThunk(
  'walls/update_post_curriculum',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.WALL_CURRICULUM_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);

export const saveWallPostActionCreator = createAsyncThunk(
  'walls/create_post',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.WALL_POST}`,
      params.body
    );
    return data;
  }
);
export const updatePostActionCreator = createAsyncThunk(
  'walls/update_post_post',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.WALL_POST_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);

export const saveTaskPostActionCreator = createAsyncThunk(
  'walls/create_post_task',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.WALL_TASK_POST}`,
      params.body
    );
    return data;
  }
);
export const updateTaskActionCreator = createAsyncThunk(
  'walls/update_post_task',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.WALL_TASK_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);
export const updateTaskStatusActionCreator = createAsyncThunk(
  'walls/update_post_task_status',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.WALL_TASK_STATUS_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);

export const updateGoalStatusActionCreator = createAsyncThunk(
  'walls/update_post_goal_status',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.WALL_GOAL_STATUS_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);

export const createTaskFeedbackActionCreator = createAsyncThunk(
  'walls/update_post_task_feedback',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.WALL_TASK_FEEDBACK}${params.id}`,
      params.body
    );
    return data;
  }
);

export const createGoalFeedbackActionCreator = createAsyncThunk(
  'walls/update_post_goal_feedback',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.WALL_GOAL_FEEDBACK}${params.id}`,
      params.body
    );
    return data;
  }
);

export const updateProjectActionCreator = createAsyncThunk(
  'walls/update_post_project',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.WALL_PROJECT_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);

export const saveProjectPostActionCreator = createAsyncThunk(
  'walls/create_post_project',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.WALL_PROJECT_POST}`,
      params.body
    );
    return data;
  }
);

export const saveGoalActionCreator = createAsyncThunk(
  'walls/create_post_goal',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.WALL_GOAL_POST}`,
      params.body
    );
    return data;
  }
);

export const updateGoalActionCreator = createAsyncThunk(
  'walls/update_post_goal',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.WALL_GOAL_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);

export const saveAchievementPostActionCreator = createAsyncThunk(
  'walls/create_post_achievement',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.WALL_ACHIEVEMENT_POST}`,
      params.body
    );
    return data;
  }
);

export const updateAchievementActionCreator = createAsyncThunk(
  'walls/update_post_achievement',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.WALL_ACHIEVEMENT_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);

export const saveMeetingPostActionCreator = createAsyncThunk(
  'walls/create_post_meeting',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.POST_MEETING_CREATE}`,
      params.body
    );
    return data;
  }
);

export const updateMeetingActionCreator = createAsyncThunk(
  'walls/meeting/update',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.MEETING_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);

export const savePostPinActionCreator = createAsyncThunk(
  'walls/pin_post',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.POST_PIN}`,
      params.body
    );
    return data;
  }
);

export const saveCommentActionCreator = createAsyncThunk(
  'posts/comment_post',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.POST_COMMENT}`,
      params.body
    );
    return data;
  }
);

export const getMyTeamListActionCreator = createAsyncThunk(
  'walls/my_team',
  async (params) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.MY_TEAM_LIST}/${params?.wallID}`
    );
    return data;
  }
);

export const saveLikesActionCreator = createAsyncThunk(
  'posts/like_post',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.POST_LIKE}`,
      params.body
    );
    return data;
  }
);

export const saveCommentLikesActionCreator = createAsyncThunk(
  'posts/comment_like',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.COMMENT_LIKE}`,
      params.body
    );
    return data;
  }
);

export const saveFeedbackActionCreator = createAsyncThunk(
  'walls/update_project_feedback',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.POST_FEEDBACK}${params.post_id}`,
      params.body
    );
    return data;
  }
);

export const getMyNotificationListActionCreator = createAsyncThunk(
  'walls/notification',
  async (id) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.NOTIFICATIONS}${id}`
    );
    return data;
  }
);

export const updateProjectStatusActionCreator = createAsyncThunk(
  'walls/update_post_project_status',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.WALL_PROJECT_UPDATE_STATUS}${params.id}`,
      params.body
    );
    return data;
  }
);

export const deletePostActionCreator = createAsyncThunk(
  'walls/delete_post',
  async (params) => {
    const { data } = await fetchUtility(
      'delete',
      `${SERVER_URL.DELETE_POST}${params.post_id}`,
      { wall_id: params.wall_id }
    );
    return data;
  }
);

export const getIsWallMember = createAsyncThunk(
  'get/wallMemeber',
  async (params) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.WALL_MEMEBER}${params.wall_id}/
      ${params.employee_id}`
    );
    return data;
  }
);

export const feedBackUpdate = createAsyncThunk(
  'update/feedback',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.FEEDBACK_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);
const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    resetState: (state) => {
      state.postList = [];
      state.myTeamList = [];
      state.postListPage = [];
      state.postCurriculumDetails = [];
      state.notificationList = [];
    },
    resetReportPostState: (state) => {
      state.wallReportPostList = [];
      state.isWallMember = false;
    },
    setPostPage: (state, action) => {
      state.postPage = action.payload;
    },
    setFirstPageUpdate: (state, action) => {
      state.firstPageUpdate = !!action.payload;
    },
    setKeepOnlyFirstPage: (state, action) => {
      state.keepOnlyFirstPage = !!action.payload;
    },
    setIsWallMember: (state, action) => {
      state.isWallMember = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getPostListActionCreator.pending, () => {
      // state.postList = [];
      // state.loading = true;
    });
    builder.addCase(getPostListActionCreator.fulfilled, (state, action) => {
      // state.loading = false;
      state.postsFetched = true;
      // const newPosts= state.postList.filter(
      //   (oldPostList) => action.payload.result.includes(oldPostList)
      // );
      const fulldata = [];
      if (state.firstPageUpdate && !state.keepOnlyFirstPage) {
        fulldata.push(...action.payload.result, ...state.postList);
      } else if (!state.firstPageUpdate && !state.keepOnlyFirstPage) {
        fulldata.push(...state.postList, ...action.payload.result);
        state.postListPage = action.payload.result;
      }
      if (!state.keepOnlyFirstPage)
        state.postList = fulldata.reduce((initialValue, currentValue) => {
          if (
            !initialValue.some((obj) => obj.post_id === currentValue.post_id)
          ) {
            initialValue.push(currentValue);
          }
          return initialValue;
        }, []);
      else if (state.keepOnlyFirstPage) {
        state.postListPage = action.payload.result;
        state.postList = action.payload.result;
      }
      state.error = '';
    });
    builder.addCase(getPostListActionCreator.rejected, (state, action) => {
      // state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getReportPostListActionCreator.pending, (state) => {
      // state.wallReportPostList = [];
      state.loading = true;
    });
    builder.addCase(
      getReportPostListActionCreator.fulfilled,
      (state, action) => {
        state.wallReportPostList = action.payload.result;
        state.loading = false;
        state.error = '';
      }
    );
    builder.addCase(
      getReportPostListActionCreator.rejected,
      (state, action) => {
        state.wallReportPostList = [];
        state.loading = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(getPerformanceReportListActionCreator.pending, (state) => {
      // state.wallReportPostList = [];
      state.loading = true;
    });
    builder.addCase(
      getPerformanceReportListActionCreator.fulfilled,
      (state, action) => {
        state.wallReportPostList = action.payload;
        state.loading = false;
        state.error = '';
      }
    );
    builder.addCase(
      getPerformanceReportListActionCreator.rejected,
      (state, action) => {
        state.wallReportPostList = [];
        state.loading = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(getpostCurriculumDetailActionCreator.pending, (state) => {
      // state.postCurriculumDetails = [];
      state.loading = true;
    });
    builder.addCase(
      getpostCurriculumDetailActionCreator.fulfilled,
      (state, action) => {
        state.postCurriculumDetails = action?.payload;
        state.loading = false;
        state.error = '';
      }
    );
    builder.addCase(
      getpostCurriculumDetailActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );
    builder.addCase(
      savepostCurriculumCompleteActionCreator.pending,
      (state) => {
        state.loading = true;
      }
    );
    builder.addCase(
      savepostCurriculumCompleteActionCreator.fulfilled,
      (state) => {
        // const index = state.postCurriculumDetails.topics.findIndex(
        //   (topic) => topic.topic_id === action?.payload?.insertedData.topic_id
        // );
        // if (index !== -1) {
        //   state.postCurriculumDetails.topics[index].completed_status = 1;
        // }
        state.loading = false;
        state.error = '';
      }
    );
    builder.addCase(
      savepostCurriculumCompleteActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(saveEndorsementPostActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(saveEndorsementPostActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(
      saveEndorsementPostActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(saveKudosPostActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(saveKudosPostActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(saveKudosPostActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(savepostThanksActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(savepostThanksActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(savepostThanksActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(saveCurriculumPostActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(saveCurriculumPostActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(
      saveCurriculumPostActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(saveWallPostActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(saveWallPostActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(saveWallPostActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(saveTaskPostActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(saveTaskPostActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(saveTaskPostActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(saveProjectPostActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(saveProjectPostActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(saveProjectPostActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(saveGoalActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(saveGoalActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(saveGoalActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(saveAchievementPostActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(saveAchievementPostActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(
      saveAchievementPostActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(saveMeetingPostActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(saveMeetingPostActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(saveMeetingPostActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(savePostPinActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(savePostPinActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(savePostPinActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(saveCommentActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveCommentActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(saveCommentActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getMyTeamListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMyTeamListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.myTeamList = action?.payload.result;
      state.error = '';
    });
    builder.addCase(getMyTeamListActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(saveLikesActionCreator.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(saveLikesActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(saveLikesActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(saveFeedbackActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(saveFeedbackActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(saveFeedbackActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(getPostDetailActionCreator.pending, (state) => {
      state.postDetail = null;
      state.loading = true;
    });
    builder.addCase(getPostDetailActionCreator.fulfilled, (state, action) => {
      state.postDetail = action?.payload;
      state.loading = false;
      state.error = '';
    });
    builder.addCase(getPostDetailActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(updateKudosActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateKudosActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updateKudosActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(updateEndorsementActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateEndorsementActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(
      updateEndorsementActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(updateAchievementActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateAchievementActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(
      updateAchievementActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(updateThanksActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateThanksActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updateThanksActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(updatePostActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updatePostActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updatePostActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(updateTaskActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateTaskActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updateTaskActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(updateTaskStatusActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateTaskStatusActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updateTaskStatusActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(updateGoalStatusActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateGoalStatusActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updateGoalStatusActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(createTaskFeedbackActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(createTaskFeedbackActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(
      createTaskFeedbackActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(createGoalFeedbackActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(createGoalFeedbackActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(
      createGoalFeedbackActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(getMyNotificationListActionCreator.pending, () => {
      // state.loading = true;
    });
    builder.addCase(
      getMyNotificationListActionCreator.fulfilled,
      (state, action) => {
        // state.loading = false;
        state.error = '';
        state.notificationList = action.payload.result;
      }
    );
    builder.addCase(
      getMyNotificationListActionCreator.rejected,
      (state, action) => {
        // state.loading = false;
        state.error = action.error.message;
      }
    );
    builder.addCase(updateMeetingActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateMeetingActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updateMeetingActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(updateProjectActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateProjectActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updateProjectActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(updateGoalActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateGoalActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updateGoalActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(updateCurriculumActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateCurriculumActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updateCurriculumActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(updateProjectStatusActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateProjectStatusActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(
      updateProjectStatusActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(saveCommentLikesActionCreator.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(saveCommentLikesActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(saveCommentLikesActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(deletePostActionCreator.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(deletePostActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(deletePostActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(shareWallReportActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(shareWallReportActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(shareWallReportActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(getIsWallMember.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(getIsWallMember.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(getIsWallMember.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(feedBackUpdate.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(feedBackUpdate.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(feedBackUpdate.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });
  }
});

export const {
  resetState,
  resetReportPostState,
  setPostPage,
  setFirstPageUpdate,
  setKeepOnlyFirstPage,
  setIsWallMember
} = postSlice.actions;

export default postSlice.reducer;
