import React, { useState } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import './BranchRow.scss';
import { openUrlInNewPage } from '../../../../../../../shared/helper/commonFunction';
import BranchIcon from '../../Components/IconComponents/BranchIcon';
import CopyIcon from '../../Components/IconComponents/CopyIcon';

export default function BranchRow({ branch }) {
  const [showCopySuccess, setShowCopySuccess] = useState(false);

  const copyBranchName = () => {
    navigator.clipboard.writeText(branch?.branch_name);
    setShowCopySuccess(true);
  };

  const getOpenPrCount = () =>
    branch.mr.filter((item) => item.state === 'opened').length;

  const gotoBranchUrl = (url) => (url ? openUrlInNewPage(url) : null);

  return (
    <div className="py-2 hover-highlight">
      <div className="row text-sub">
        <div className="col-md-4 d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center flex-grow-1">
            <span>
              <BranchIcon />
            </span>
            <p
              role="presentation"
              onClick={() => gotoBranchUrl(branch?.branch_url)}
              className={`mb-0 w-75 ${
                branch?.branch_url
                  ? 'text-primary cursor-pointer clickable-link'
                  : 'text-sub text-line-through'
              }`}>
              {branch?.branch_name}
            </p>
          </div>

          <div
            id="copyBranchId"
            role="presentation"
            onClick={copyBranchName}
            onMouseLeave={() => setShowCopySuccess(false)}
            className="ms-1 text-primary copy-btn">
            <CopyIcon />
          </div>
        </div>
        <div className="col-md-5 d-flex align-items-center text-truncate">
          {branch?.mr.length && (
            <>
              <span>
                {branch?.mr.length}{' '}
                {branch?.mr.length === 1 ? 'Pull request' : 'Pull requests'}
              </span>
              <span className="pr-status small ms-2">
                {getOpenPrCount()} OPEN
              </span>
            </>
          )}
        </div>
        <div className="col-md-3 d-flex align-items-center text-truncate">
          {branch?.branch_url ? (
            <p
              role="presentation"
              onClick={() => openUrlInNewPage(branch?.pull_request_url)}
              className="text-primary cursor-pointer mb-0 clickable-link">
              Create pull request
            </p>
          ) : (
            <p className="text-sub mb-0">
              <em>This branch is deleted!</em>
            </p>
          )}
        </div>
      </div>
      <UncontrolledTooltip
        placement="top"
        target="copyBranchId"
        isOpen={showCopySuccess}>
        Copied!
      </UncontrolledTooltip>
    </div>
  );
}
