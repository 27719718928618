import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Badge, Button, Col, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import InputField from '../../input/inputField/InputField';
import FileUpload from '../../components/file-upload/FileUpload';
import {
  saveGoalActionCreator,
  updateGoalActionCreator
} from '../../../redux/slices/PostSlice';
import SelectField from '../../input/selectField/SelectField';
import TagPeople from '../tagPeople/TagPeople';
import {
  listFormatter,
  convertToFormData,
  getUTCFormat,
  getAutomaticInputHeight
} from '../../helper/commonFunction';
import { formatOptionLabel } from '../../helper/commonDataFormatter';
import CustomDatepicker from '../../input/inputField/Datepicker';
import {
  DATE_ONLY,
  DATE_TIME_MINUTE_SECOND
} from '../../constants/ConstantsData';

function DropdownIndicator() {
  return (
    <svg
      height="20"
      width="30"
      viewBox="4 0 20 20"
      aria-hidden="true"
      focusable="false"
      className="css-tj5bde-Svg">
      <path
        fill="#E75256"
        d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"
      />
    </svg>
  );
}

function GoalField({
  postDetail,
  refreshPost,
  users,
  defaultTag,
  assignees,
  onPostValueChange,
  postValue,
  postTypeList,
  handleCancel,
  handleClose,
  formView = 'addView'
}) {
  const authState = useSelector((state) => state.login.loginDetails);
  const btnLoader = useSelector((state) => state.post.btnLoader);

  const [fileValue, setFileValue] = useState(null);
  const [selectedTag, setSelectedTag] = useState(defaultTag || []);
  const [removedFileID, setRemovedFileID] = useState([]);
  const [resetFilePreview, setResetFilePreview] = useState();
  const [selectedStartDate, setSelectedStartDate] = useState();
  const [descriptionHeight, setDescriptionHeight] = useState(50);

  const { wallID } = useParams();
  const childRef = useRef();
  const {
    reset,
    control,
    handleSubmit,
    setError,
    clearErrors,
    // getValues,
    setValue,
    watch,
    formState: { errors }
  } = useForm();

  const dispatch = useDispatch();

  const handleSelectedTags = (values) => {
    if (values?.find((option) => option.value === '*')) {
      setSelectedTag(users);
      setValue('taggedPeople', users, { shouldValidate: true });
    } else {
      setSelectedTag(values);
      setValue('taggedPeople', values, { shouldValidate: true });
    }
  };

  if (formView !== 'editView') {
    useEffect(() => {
      setSelectedTag(defaultTag || []);
    }, [defaultTag, users]);
  }

  const watchDescription = watch('description');
  const refDescription = document.getElementsByName('description');
  useEffect(() => {
    setDescriptionHeight(getAutomaticInputHeight(refDescription, 50));
  }, [watchDescription]);

  const removeTaggedValue = (e) => {
    const { name: buttonName } = e.currentTarget;
    const removedValue = selectedTag.find((val) => val.value === buttonName);
    if (!removedValue) return;
    handleSelectedTags(selectedTag.filter((val) => val.value !== buttonName));
  };

  useEffect(() => {
    if (postValue) setValue('post', postValue, { shouldValidate: true });
  }, [postValue]);

  useEffect(() => {
    if (postDetail) {
      let postWallData = null;
      if (
        postDetail?.post_type === 'Goal' &&
        postDetail?.wall_goal &&
        Object.keys(postDetail?.wall_goal).length
      ) {
        postWallData = postDetail?.wall_goal;
      }

      if (postDetail?.post_attachments?.length) {
        const fileDataValue = [];
        const fileDetail = [];
        postDetail?.post_attachments.forEach((attachment) => {
          fileDataValue.push(attachment?.file_name);
          fileDetail.push(attachment);
        });
        if (fileDataValue?.length) {
          setValue('wall_file', fileDataValue);
          setFileValue(fileDetail);
        }
      }

      if (postWallData) {
        setValue('description', postWallData.goal);

        let taggedEmployees = [];
        let assigneeList = [];

        if (postWallData?.start_date) {
          setValue('start_date', new Date(postWallData.start_date));
          setSelectedStartDate(
            new Date(moment(postWallData.start_date).add(5, 'minutes'))
          );
        }

        if (postWallData?.end_date) {
          setValue('end_date', new Date(postWallData.end_date));
        }

        if (postWallData?.assignees?.length) {
          assigneeList = postWallData?.assignees;
        }

        if (assigneeList?.length) {
          const filteredAssignees = listFormatter(
            assigneeList,
            (assigneeData) => ({
              id: assigneeData?.employee_id,
              label: assigneeData?.name || '',
              value: assigneeData?.name || ''
            })
          );
          setValue('assignees', filteredAssignees, { shouldValidate: true });
        }

        if (postDetail?.tagged_employees?.length) {
          taggedEmployees = postDetail?.tagged_employees.filter(
            (taggedEmployee) =>
              !assigneeList.some(
                (assigneeData) =>
                  taggedEmployee.employee_id === assigneeData?.employee_id
              )
          );
          // }
          const filteredTaggedEmployees = listFormatter(
            taggedEmployees,
            (taggedPerson) => ({
              id: taggedPerson.employee_id,
              label: taggedPerson?.name || '',
              value: taggedPerson?.name || ''
            })
          );
          handleSelectedTags(filteredTaggedEmployees);
        }
      }
    }
  }, [postDetail]);

  const resetForm = () => {
    reset({
      description: '',
      taggedPeople: '',
      assignees: []
    });
    setSelectedTag(defaultTag || []);
    setDescriptionHeight(50);
  };

  const startDateOnchange = (value) => {
    setValue('start_date', new Date(moment(value)));
    setValue('end_date', '');
    setSelectedStartDate(new Date(moment(value).add(5, 'minutes')));
  };

  const onSubmit = (data) => {
    const apiData = {
      body: {
        tagged_employee_id:
          formView === 'addView'
            ? defaultTag.map((tagedPerson) => tagedPerson.id)
            : [],
        goal: data.description
      }
    };

    let actionCreator = null;
    if (data.start_date)
      apiData.body.start_date = getUTCFormat(
        data.start_date,
        DATE_TIME_MINUTE_SECOND
      );
    if (data.end_date)
      apiData.body.end_date = getUTCFormat(
        data.end_date,
        DATE_TIME_MINUTE_SECOND
      );

    if (formView === 'addView') {
      apiData.body.posted_by = authState.employee_id;
      apiData.body.wall_id = wallID;
    } else if (formView === 'editView') {
      apiData.id = postDetail.post_id;
      apiData.body.wall_id = wallID;
    }

    if (data?.taggedPeople) {
      let taggedEmployeeId = [];
      taggedEmployeeId = data.taggedPeople.map((tagedPerson) => tagedPerson.id);
      apiData.body.tagged_employee_id = taggedEmployeeId;
    }

    if (data?.assignees && data?.assignees?.length) {
      const assigneeList = data.assignees.map(
        (selectedData) => selectedData.id
      );
      if (assigneeList.length)
        apiData.body.tagged_employee_id = [
          ...new Set([...apiData.body.tagged_employee_id, ...assigneeList])
        ];
      apiData.body.assignees = assigneeList;
    }
    const formData = new FormData();
    const formValue = convertToFormData(apiData.body);
    if (data.wall_file?.length) {
      data.wall_file.forEach((wallFile) => {
        if (wallFile instanceof File) formData.append('attachment', wallFile);
        else if (wallFile?.actualFile instanceof File)
          formData.append('attachment', wallFile?.actualFile);
      });
    }
    if (removedFileID?.length) {
      formData.append('remove_attachment', JSON.stringify(removedFileID));
    }
    // eslint-disable-next-line no-restricted-syntax
    for (const pair of formValue.entries()) {
      formData.append(pair[0], pair[1]);
    }
    apiData.body = formData;
    if (formView === 'addView') {
      actionCreator = dispatch(saveGoalActionCreator(apiData));
    } else {
      actionCreator = dispatch(updateGoalActionCreator(apiData));
    }

    actionCreator.unwrap().then(() => {
      if (formView === 'addView') {
        resetForm();
        setResetFilePreview(!resetFilePreview);
        refreshPost();
      } else if (formView === 'editView') {
        handleClose(apiData);
        // navigate(`/report-view-Curriculum/${postDetail?.post_id}`);
      }
    });
  };

  const handleClick = () => {
    childRef.current.parentClick();
  };

  return (
    <div className="project-field-container">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          {formView === 'addView' ? (
            <Col md={5} lg={4} xl={3}>
              <Controller
                name="post"
                control={control}
                rules={{ required: true }}
                render={({ field: { onChange } }) => (
                  <SelectField
                    errors={errors.post}
                    onChange={onPostValueChange}
                    field={onChange}
                    value={postValue}
                    placeholder="Post Here"
                    options={postTypeList}
                    isMulti={false}
                    components={{
                      IndicatorSeparator: () => null,
                      DropdownIndicator
                    }}
                  />
                )}
              />
            </Col>
          ) : (
            ''
          )}
          <Col md={3}>
            <Controller
              name="assignees"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => (
                <SelectField
                  errors={errors.assignees}
                  options={assignees}
                  field={field}
                  isMulti
                  placeholder="Select Assignee"
                  formatOptionLabel={formatOptionLabel}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator
                  }}
                  label={formView === 'editView' ? 'Assignees' : ''}
                />
              )}
            />
          </Col>
          <Col md={3}>
            <Controller
              name="start_date"
              control={control}
              rules={{
                required: true
              }}
              render={({ field: { onChange, value } }) => (
                <CustomDatepicker
                  errors={errors.start_date}
                  field={onChange}
                  onChange={startDateOnchange}
                  valueTime={value}
                  dateFormat={DATE_ONLY}
                  placeholder="Select Start Date"
                  label={formView === 'editView' ? 'Select Start Date' : ''}
                />
              )}
            />
          </Col>
          <Col md={3}>
            <Controller
              name="end_date"
              control={control}
              rules={{
                required: true
              }}
              render={({ field }) => (
                <CustomDatepicker
                  errors={errors.end_date}
                  field={field}
                  startDate={selectedStartDate}
                  dateFormat={DATE_ONLY}
                  placeholder="Select End Date"
                  label={formView === 'editView' ? 'Select End Date' : ''}
                />
              )}
            />
          </Col>
        </Row>
        <Row className="mt-3 pt-3">
          <Col>
            <Controller
              name="description"
              control={control}
              rules={{
                required: true,
                pattern: {
                  value: /.*\S.*/,
                  message: 'Please enter valid text.'
                }
              }}
              render={({ field }) => (
                <InputField
                  errors={errors.description}
                  className="text-area-field"
                  field={field}
                  name="description"
                  style={{
                    height: `${descriptionHeight}px`
                  }}
                  inputType="textarea"
                  placeholder="Type something..."
                  label={formView === 'editView' ? 'Description' : ''}
                />
              )}
            />

            {/* <Controller
                        name="description"
                        control={control}
                        rules={{
                          required: true
                        }}
                        render={({ field }) => (
                          <InputField
                            errors={errors.description}
                            inputType="textarea"
                            field={field}
                            label="Type something..."
                            // readOnly
                          />
                        )}
                      /> */}
          </Col>
          <Col
            lg="auto"
            className="d-flex align-items-start justify-content-center">
            <Button
              className={formView === 'editView' ? 'mt-edit-btn ' : ''}
              outline
              type="button"
              onClick={handleClick}
              color="primary">
              <i className="bi bi-upload " /> Upload File
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Controller
            name="wall_file[]"
            control={control}
            rules={{
              required: false
            }}
            render={({ field: { onChange } }) => (
              <FileUpload
                errors={errors.wall_file}
                field={onChange}
                setError={setError}
                clearErrors={clearErrors}
                setValue={setValue}
                fieldColumn="wall_file"
                setRemovedFileID={setRemovedFileID}
                fileValue={fileValue}
                showPreview={false}
                reset={resetFilePreview}
                ref={childRef}
                isMulti
                handleClickFromParent
                // options={users}
                // setSelected={setSelectedTag}
              />
            )}
          />
        </Row>
        <Row className="mt-3 ">
          {formView === 'addView' ? (
            <Col
              md="auto"
              className="d-flex align-items-start justify-content-center">
              <Button color="primary" disabled={btnLoader}>
                Post
              </Button>
            </Col>
          ) : (
            ''
          )}
          <Col md={3} className="tp-head-col">
            <div>
              <Controller
                name="taggedPeople"
                control={control}
                rules={{
                  required: false
                }}
                render={({ field: { onChange } }) => (
                  <TagPeople
                    errors={errors.taggedPeople}
                    onChange={handleSelectedTags}
                    field={onChange}
                    isMulti
                    options={users}
                    value={selectedTag}
                    setSelected={setSelectedTag}
                  />
                )}
              />
            </div>
          </Col>
          <Col className="tag-people-col align-self-center">
            {selectedTag.map((val) => (
              <Badge key={val.value} className="tagged-badge me-1">
                {val.label}

                <Button
                  className="tagged-close"
                  name={val.value}
                  onClick={removeTaggedValue}>
                  <i className="bi bi-x-circle tag-cls-btn" />
                </Button>
              </Badge>
            ))}
          </Col>
        </Row>

        {formView === 'editView' ? (
          <Row className="btn-container  mt-4">
            <Col md="auto">
              <Button
                outline
                color="primary"
                disabled={btnLoader}
                onClick={handleCancel}>
                Cancel
              </Button>
            </Col>
            <Col md="auto">
              <Button color="primary" disabled={btnLoader}>
                Update
              </Button>
            </Col>
          </Row>
        ) : (
          ''
        )}
      </form>
    </div>
  );
}

export default GoalField;
