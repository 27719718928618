import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';

const initialState = {
  topicList: [],
  topicDetails: null,
  loading: false,
  btnLoader: false,
  error: ''
};

export const getTopicListActionCreator = createAsyncThunk(
  'topic/list',
  async () => {
    const { data } = await fetchUtility('get', `${SERVER_URL.TOPIC_LIST}`);
    return data;
  }
);

export const saveTopicActionCreator = createAsyncThunk(
  'topic/save',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.TOPIC_CREATE}`,
      params.body
    );
    return data;
  }
);

export const getTopicDetailActionCreator = createAsyncThunk(
  'topic/detail',
  async (id) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.TOPIC_DETAIL}${id}`
    );
    return data;
  }
);

export const deleteTopicActionCreator = createAsyncThunk(
  'topic/delete',
  async (id) => {
    const { data } = await fetchUtility(
      'delete',
      `${SERVER_URL.TOPIC_DELETE}${id}`
    );
    return data;
  }
);

export const updateTopicActionCreator = createAsyncThunk(
  'topic/update',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.TOPIC_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);

const topicSlice = createSlice({
  name: 'topic',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getTopicListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTopicListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.topicList = action.payload.result;
      // state.topicList = [];
      state.error = '';
    });
    builder.addCase(getTopicListActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteTopicActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(deleteTopicActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(deleteTopicActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(saveTopicActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(saveTopicActionCreator.fulfilled, (state) => {
      // state.topicList.unshift(action.payload);
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(saveTopicActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(updateTopicActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateTopicActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updateTopicActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(getTopicDetailActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(getTopicDetailActionCreator.fulfilled, (state, action) => {
      if (action.payload.result)
        state.topicDetails = { ...action.payload.result };
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(getTopicDetailActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });
  }
});

export default topicSlice.reducer;
