import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';

const initialState = {
  menteeList: [],
  menteeDetails: null,
  loading: false,
  btnLoader: false,
  status: '',
  statusCode: null,
  error: ''
};

export const getMenteeListActionCreator = createAsyncThunk(
  'mentee/list',
  async () => {
    const { data } = await fetchUtility('get', `${SERVER_URL.MENTEE_LIST}`);
    return data;
  }
);

export const saveMenteeActionCreator = createAsyncThunk(
  'mentee/save',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.MENTEE_CREATE}`,
      params.body
    );
    return data;
  }
);

export const getMenteeDetailActionCreator = createAsyncThunk(
  'mentee/detail',
  async (id) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.MENTEE_DETAIL}${id}`
    );
    return data;
  }
);

export const deleteMenteeActionCreator = createAsyncThunk(
  'mentee/delete',
  async (id) => {
    const { data } = await fetchUtility(
      'delete',
      `${SERVER_URL.MENTEE_DELETE}${id}`
    );
    return data;
  }
);

export const updateMenteeActionCreator = createAsyncThunk(
  'mentee/update',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.MENTEE_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);

const menteeSlice = createSlice({
  name: 'mentee',
  initialState,
  extraReducers: (builder) => {
    builder.addCase(getMenteeListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getMenteeListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.menteeList = action.payload.result;
      state.error = '';
    });
    builder.addCase(getMenteeListActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(deleteMenteeActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(deleteMenteeActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(deleteMenteeActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(saveMenteeActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(saveMenteeActionCreator.fulfilled, (state) => {
      // state.menteeList.unshift(action.payload);
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(saveMenteeActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(updateMenteeActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateMenteeActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updateMenteeActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(getMenteeDetailActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(getMenteeDetailActionCreator.fulfilled, (state, action) => {
      if (action.payload.result)
        state.menteeDetails = { ...action.payload.result };
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(getMenteeDetailActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });
  }
});

export default menteeSlice.reducer;
