import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import SERVER_URL from '../../shared/constants/Constants';
import fetchUtility from '../../shared/helper/fetchUtility';

const initialState = {
  requestFeedbackList: [],
  menteeFeedbackList: [],
  feedbackDetails: null,
  loading: false,
  btnLoader: false,
  status: '',
  statusCode: null,
  error: ''
};

export const getRequestFeedbackListActionCreator = createAsyncThunk(
  'requestFeedback/list',
  async ({ isCurrentUserMentor }) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.REQUEST_FEEDBACK_LIST}/${isCurrentUserMentor}`
    );
    return { data, isCurrentUserMentor };
  }
);

export const saveRequestFeedbackActionCreator = createAsyncThunk(
  'requestSubmitFeedback/save',
  async ({ feedbackID, feedbackData, actionType }) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.SUBMIT_MENTEE_FEEDBACK}${feedbackID}?action=${actionType}`,
      feedbackData.body
    );
    return data;
  }
);

export const getRequestFeedbackDetailActionCreator = createAsyncThunk(
  'requestFeedback/detail',
  async (id) => {
    const { data } = await fetchUtility(
      'get',
      `${SERVER_URL.GET_MENTEE_FEEDBACK_FROM_DETAILS}${id}`
    );
    return data;
  }
);

export const requestMenteeFeedbackActionCreator = createAsyncThunk(
  'requestMenteeFeedback/request',
  // eslint-disable-next-line camelcase
  async ({ requestId, mentorId, is_provisional }) => {
    const { data } = await fetchUtility(
      'put',
      // eslint-disable-next-line camelcase
      `${SERVER_URL.REQUEST_MENTEE_FEEDBACK}${requestId}?mentor_id=${mentorId}&is_provisional=${is_provisional}`
    );
    return data;
  }
);

export const requestMultipleMenteeFeedbackActionCreator = createAsyncThunk(
  'requestMultipleMenteeFeedback/request',
  // eslint-disable-next-line camelcase
  async ({ requestData }) => {
    const { data } = await fetchUtility(
      'post',
      // eslint-disable-next-line camelcase
      `${SERVER_URL.REQUEST_MULTIPLE_MENTEE_FEEDBACK}`,
      requestData
    );
    return data;
  }
);

export const approveMultipleMenteeFeedbackActionCreator = createAsyncThunk(
  'approveMultipleMenteeFeedback/approve',
  // eslint-disable-next-line camelcase
  async ({ requestData }) => {
    const { data } = await fetchUtility(
      'post',
      // eslint-disable-next-line camelcase
      `${SERVER_URL.APPROVE_MULTIPLE_MENTEE_FEEDBACK}`,
      requestData
    );
    return data;
  }
);

export const notificationMenteeFeedbackActionCreator = createAsyncThunk(
  'notificationMenteeFeedback/notify',
  async (id) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.NOTIFICATION_MENTEE_FEEDBACK}${id}`
    );
    return data;
  }
);

const requestFeedbackSlice = createSlice({
  name: 'requestFeedback',
  initialState,
  reducers: {
    resetFeedbackDetails: (state) => {
      state.feedbackDetails = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getRequestFeedbackListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getRequestFeedbackListActionCreator.fulfilled,
      (state, action) => {
        state.loading = false;
        if (action?.payload?.isCurrentUserMentor) {
          state.menteeFeedbackList = action?.payload?.data?.result;
        } else {
          state.requestFeedbackList = action?.payload?.data?.result;
        }
        state.error = '';
      }
    );
    builder.addCase(
      getRequestFeedbackListActionCreator.rejected,
      (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(requestMenteeFeedbackActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(requestMenteeFeedbackActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(
      requestMenteeFeedbackActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(
      requestMultipleMenteeFeedbackActionCreator.pending,
      (state) => {
        state.btnLoader = true;
      }
    );
    builder.addCase(
      requestMultipleMenteeFeedbackActionCreator.fulfilled,
      (state) => {
        state.btnLoader = false;
        state.error = '';
      }
    );
    builder.addCase(
      requestMultipleMenteeFeedbackActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(
      approveMultipleMenteeFeedbackActionCreator.pending,
      (state) => {
        state.btnLoader = true;
      }
    );
    builder.addCase(
      approveMultipleMenteeFeedbackActionCreator.fulfilled,
      (state) => {
        state.btnLoader = false;
        state.error = '';
      }
    );
    builder.addCase(
      approveMultipleMenteeFeedbackActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(saveRequestFeedbackActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(saveRequestFeedbackActionCreator.fulfilled, (state) => {
      // state.requestFeedbackList.unshift(action.payload);
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(
      saveRequestFeedbackActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(
      notificationMenteeFeedbackActionCreator.pending,
      (state) => {
        state.btnLoader = true;
      }
    );
    builder.addCase(
      notificationMenteeFeedbackActionCreator.fulfilled,
      (state) => {
        state.btnLoader = false;
        state.error = '';
      }
    );
    builder.addCase(
      notificationMenteeFeedbackActionCreator.rejected,
      (state, action) => {
        state.btnLoader = false;
        state.error = action.error.message;
      }
    );

    builder.addCase(getRequestFeedbackDetailActionCreator.pending, (state) => {
      state.loader = true;
    });
    builder.addCase(
      getRequestFeedbackDetailActionCreator.fulfilled,
      (state, action) => {
        if (action.payload.result)
          state.feedbackDetails = { ...action.payload.result };
        state.loader = false;
        state.error = '';
      }
    );
    builder.addCase(
      getRequestFeedbackDetailActionCreator.rejected,
      (state, action) => {
        state.loader = false;
        state.error = action.error.message;
      }
    );
  }
});

export const { resetFeedbackDetails } = requestFeedbackSlice.actions;

export default requestFeedbackSlice.reducer;
