import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import fetchUtility from '../../shared/helper/fetchUtility';
import SERVER_URL from '../../shared/constants/Constants';

const initialState = {
  sessionLists: [],
  sessionView: 'viewSessionList',
  sessionDetails: '',
  loading: false,
  status: '',
  btnLoader: false,
  statusCode: null,
  error: ''
};
export const getSessionListActionCreator = createAsyncThunk(
  'session/list',
  async () => {
    const { data } = await fetchUtility('get', `${SERVER_URL.SESSION_LIST}`);
    return data;
  }
);
export const deleteSessionActionCreator = createAsyncThunk(
  'session/delete',
  async (id) => {
    const { data } = await fetchUtility(
      'delete',
      `${SERVER_URL.SESSION_DELETE}${id}`
    );
    return data;
  }
);
export const createSessionActionCreator = createAsyncThunk(
  'session/save',
  async (params) => {
    const { data } = await fetchUtility(
      'post',
      `${SERVER_URL.SESSION_CREATE}`,
      params.body
    );
    return data;
  }
);
export const updateSessionActionCreator = createAsyncThunk(
  'session/update',
  async (params) => {
    const { data } = await fetchUtility(
      'put',
      `${SERVER_URL.SESSION_UPDATE}${params.id}`,
      params.body
    );
    return data;
  }
);

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    sessionState: (state, { payload }) => {
      state.sessionView = payload.sessionView;
    },
    sessionDetails: (state, { payload }) => {
      state.sessionDetails = payload.sessionDetails;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getSessionListActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSessionListActionCreator.fulfilled, (state, action) => {
      state.loading = false;
      state.sessionLists = action?.payload?.result;
      state.error = '';
    });
    builder.addCase(getSessionListActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.code;
    });

    builder.addCase(deleteSessionActionCreator.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteSessionActionCreator.fulfilled, (state) => {
      state.loading = false;
      state.error = '';
    });
    builder.addCase(deleteSessionActionCreator.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(createSessionActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(createSessionActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(createSessionActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });

    builder.addCase(updateSessionActionCreator.pending, (state) => {
      state.btnLoader = true;
    });
    builder.addCase(updateSessionActionCreator.fulfilled, (state) => {
      state.btnLoader = false;
      state.error = '';
    });
    builder.addCase(updateSessionActionCreator.rejected, (state, action) => {
      state.btnLoader = false;
      state.error = action.error.message;
    });
  }
});
export const { sessionState, sessionDetails } = sessionSlice.actions;
export default sessionSlice.reducer;
