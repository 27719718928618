import React, { useEffect, useMemo, useState } from 'react';
import './Branches.scss';
import {
  Popover,
  PopoverBody,
  UncontrolledPopover,
  UncontrolledTooltip
} from 'reactstrap';
// import { useSelector } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { openUrlInNewPage } from '../../../../../../shared/helper/commonFunction';
import CopyInput from '../Components/CopyInput/CopyInput';
import GitLabConnectButton from '../../GitLabConnectButton/GitLabConnectButton';
import { setGitLabPopupState } from '../../../../../../redux/slices/kanban/KanbanSlice';

export default function Branches({
  callback,
  taskData,
  isDevelopmentModalOpen,
  openBranchModal
}) {
  const dispatch = useDispatch();
  const projectId = useSelector(
    (state) => state?.wallList?.wallListElementDetails?.git_project_id
  );
  const isGitLabPopupOpen = useSelector(
    (state) => state?.kanban?.isGitLabPopupOpen
  );

  const repoName = useSelector(
    (state) => state?.wallList?.wallListElementDetails?.git_repo_name
  );
  const [showBranchPopover, setShowBranchPopover] = useState(false);
  const [showAddBranchPopover, setShowAddBranchPopover] = useState(false);

  const recentActiveBranch = useMemo(
    () => taskData?.git?.branches.find((branch) => branch.branch_url != null),
    [taskData]
  );

  const onHover = () => {
    if (!isDevelopmentModalOpen && !showAddBranchPopover) {
      setShowBranchPopover(true);
    }
  };

  const onHoverLeave = () => {
    setShowBranchPopover(false);
  };

  const toggleAddBranchPopover = () => {
    // setShowBranchPopover(false);
    setShowAddBranchPopover((value) => !value);
  };

  useEffect(() => {
    if (
      isGitLabPopupOpen.status === 'closed' &&
      isGitLabPopupOpen.taskId.includes(taskData?.kanban_task_id)
    ) {
      callback();
      dispatch(setGitLabPopupState({ status: 'notInitialised', taskId: null }));
    }
  }, [isGitLabPopupOpen]);

  return (
    <>
      <div
        className="d-flex align-itens-center dev-details mb-1"
        id="gitBranchesPopoverTriggerId"
        onMouseEnter={onHover}
        onMouseLeave={onHoverLeave}>
        <div
          role="presentation"
          onClick={openBranchModal}
          className="label font-regular cursor-pointer flex-grow-1 d-flex align-items-center p-2">
          {taskData?.git?.branches.length ? (
            <div>
              {taskData?.git?.branches.length}{' '}
              {taskData?.git?.branches.length === 1 ? 'branch' : 'branches'}
            </div>
          ) : (
            <div> No branches </div>
          )}
        </div>

        <button
          type="button"
          id={`createNewBranchPopoverId${taskData?.kanban_task_id}`}
          className="add-branch-btn d-flex align-items-center text-primary"
          style={showAddBranchPopover ? { maxWidth: '1rem' } : null}>
          <i className="bi bi-plus-square-dotted fw-500" />
        </button>

        <Popover
          target="gitBranchesPopoverTriggerId"
          isOpen={showBranchPopover && taskData?.git?.branches.length > 0}
          placement="bottom"
          offset={[35, 0]}
          popperClassName="branch-popover"
          hideArrow>
          <PopoverBody>
            <div>
              <p className="font-bold text-secondary mb-1">BRANCH</p>
              <div className="border-bottom">
                <h6
                  role="presentation"
                  onClick={() =>
                    openUrlInNewPage(recentActiveBranch?.branch_url)
                  }
                  className="text-primary branch-link cursor-pointer">
                  {recentActiveBranch?.branch_name}
                </h6>
                <p className="text-sub small">
                  Repository: {taskData?.git?.git_repo_name}
                </p>
              </div>

              <p
                role="presentation"
                onClick={openBranchModal}
                className="text-primary branch-link cursor-pointer mb-0 mt-2">
                View all development information
              </p>
            </div>
          </PopoverBody>
        </Popover>
      </div>

      <UncontrolledTooltip
        placement="top"
        target={`createNewBranchPopoverId${taskData?.kanban_task_id}`}>
        Create new branch
      </UncontrolledTooltip>

      <UncontrolledPopover
        target={`createNewBranchPopoverId${taskData?.kanban_task_id}`}
        trigger="legacy"
        isOpen={showAddBranchPopover}
        toggle={toggleAddBranchPopover}
        placement="bottom"
        offset={[-160, 0]}
        popperClassName="add-branch-popover"
        hideArrow>
        <PopoverBody>
          <div>
            <p className="font-bold text-secondary mb-1">
              SOURCE CODE INTEGRATION
            </p>
            <GitLabConnectButton
              callback={callback}
              redirectRoute={`/gitlab-create-branch/${projectId}/${taskData?.kanban_task_id}/${taskData?.task}/${repoName}`}
              buttonText="Create Branch"
              isCreateBranch
            />

            <p className="font-bold text-secondary mb-1">
              GIT CREATE & CHECKOUT A NEW BRANCH
            </p>

            <CopyInput
              value={`git checkout -b ${taskData?.kanban_task_id}-<branch name>`}
            />
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
}
