import React from 'react';
import { Button, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { deletePostActionCreator } from '../../redux/slices/PostSlice';
import Loader from '../../shared/components/loader/Loader';

function DeleteWallPost({ handleClose, postData, getPostList }) {
  const btnLoader = useSelector((state) => state.post.loading);
  const dispatch = useDispatch();

  const handleCancel = () => {
    handleClose();
  };

  const onSubmit = () => {
    dispatch(
      deletePostActionCreator({
        post_id: postData?.post_id,
        wall_id: postData?.wall_id
      })
    )
      .unwrap()
      .then(() => {
        if (!btnLoader) {
          handleClose();
          getPostList(true, true);
        }
      });
  };

  return (
    <div className="delete-user-container">
      {btnLoader && <Loader show={btnLoader} />}
      <h6>Are you sure you want to delete this post?</h6>
      <Row className="pt-3">
        <Col md="auto">
          <Button
            outline
            color="primary"
            disabled={btnLoader}
            onClick={handleCancel}>
            Cancel
          </Button>
        </Col>
        <Col md="auto">
          <Button color="primary" disabled={btnLoader} onClick={onSubmit}>
            Delete
          </Button>
        </Col>
      </Row>
    </div>
  );
}

export default DeleteWallPost;
