/* eslint-disable react/function-component-definition */
import React, {
  useRef,
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle
} from 'react';
import { Button, Col, Row } from 'reactstrap';
import { validationMsg, fileToDataURL } from '../../helper/commonFunction';
import './FileUpload.scss';
import pdfImg from '../../../assets/images/pdf.png';
import excelImg from '../../../assets/images/excel.png';
// import InputField from '../../input/inputField/InputField';

const FileUpload = forwardRef((props, ref) => {
  const {
    errors,
    setError,
    clearErrors,
    setValue,
    fileValue,
    fieldColumn = 'file',
    fieldCaption = 'Upload File',
    fileSize = '10MB',
    supportedType = [
      '.jpg',
      '.jpeg',
      '.png',
      '.pdf',
      '.xlsx',
      '.xls',
      '.vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    ],
    reset,
    isMulti = false,
    setRemovedFileID,
    storyBookView = false,
    handleClickFromParent = false
  } = props;
  const [fileLoader, setFileLoader] = useState(false);
  const [fileData, setFileData] = useState([]);
  const inputFileRef = useRef(null);

  const addThumbnail = (file, type) => {
    let fileExt;
    if (type === 'url') {
      fileExt = file.split('.').pop();
    } else if (type === 'base64') {
      // eslint-disable-next-line prefer-destructuring
      fileExt = file.split(';')[0].split('/')[1];
    }

    if (fileExt === 'pdf') {
      return pdfImg;
    }
    if (
      fileExt === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      fileExt === 'xlsx' ||
      fileExt === 'xls'
    ) {
      return excelImg;
    }
    return file;
  };

  useEffect(() => {
    if (fileValue) {
      const fileSetData = [];
      fileValue.forEach((fileDetail) => {
        const fileName = fileDetail.file_name
          .substring(fileDetail.file_name.lastIndexOf('/') + 1)
          .split(/_(.*)/s)[1]
          .toLowerCase();
        fileSetData.push({
          fileName,
          thumbnail: addThumbnail(fileDetail.file_name, 'url'),
          actualFile: null,
          imgID: Math.random(),
          fileDetail
        });
      });
      if (fileSetData?.length) setFileData(fileSetData);
    }
  }, [fileValue]);

  const fileValidator = (file) => {
    let returnData = null;
    const formatBytes = (bytes) => {
      const si = true;
      const thresh = si ? 1000 : 1024;
      if (Math.abs(bytes) < thresh) {
        return `${bytes} BYTES`;
      }
      const units = si
        ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
        : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
      let u = -1;
      do {
        // eslint-disable-next-line no-param-reassign
        bytes /= thresh;
        // eslint-disable-next-line no-plusplus
        ++u;
      } while (Math.abs(bytes) >= thresh && u < units.length - 1);
      return `${bytes.toFixed(1)} ${units[u]}`;
    };

    const sizeValidator = (sizeVal) => {
      if (file.size > Number(sizeVal)) {
        return {
          message: `File should be less than ${formatBytes(sizeVal)}`,
          type: 'file',
          currentFileSize: file.size,
          actualFileSize: sizeVal
        };
      }
      return null;
    };

    if (
      typeof fileSize === 'string' &&
      (fileSize.includes('MB') ||
        fileSize.includes('KB') ||
        fileSize.includes('BYTES'))
    ) {
      const data =
        Number(
          fileSize
            .replace(/ /g, '')
            // eslint-disable-next-line no-useless-escape
            .replace(/[^0-9\.]/g, '')
            .trim()
        ) || 0;
      if (fileSize.includes('MB')) {
        returnData = sizeValidator(data * 1e6);
      } else if (fileSize.includes('KB')) {
        returnData = sizeValidator(data * 1000);
      } else {
        returnData = sizeValidator(data);
      }
    }

    if (![null, undefined].includes(supportedType)) {
      const fileExt = `.${file.type.split('/')[1].toLowerCase()}`;
      if (
        (typeof supportedType === 'string' && supportedType !== fileExt) ||
        (Array.isArray(supportedType) &&
          supportedType.length > 0 &&
          !supportedType.includes(fileExt))
      ) {
        returnData = {
          type: 'file',
          allowedExtension: supportedType,
          givenExtension: fileExt,
          message: 'Invalid file format.'
        };
      }
    }

    return returnData;
  };

  const handleFileChange = async (event) => {
    setFileLoader(true);
    if (!storyBookView) {
      clearErrors(fieldColumn);
    }
    if (event?.target?.files) {
      let fileObj = [];
      if (!isMulti) {
        fileObj = [event.target.files[0]];
      } else {
        fileObj = [...event.target.files];
      }
      let validatedFiles = [];

      if (fileData?.length && isMulti) {
        validatedFiles = [...fileData];
      }
      let filesCount = 0;
      await fileObj.forEach(async (fileObjectData) => {
        filesCount += 1;
        const invalidFileMsg = fileValidator(fileObjectData);
        if (invalidFileMsg) {
          if (!storyBookView) setError(fieldColumn, invalidFileMsg);
          setFileLoader(false);
        } else {
          const fileBlobURL = await fileToDataURL(fileObjectData);
          const thumbnail = addThumbnail(fileBlobURL, 'base64');
          validatedFiles = [
            ...validatedFiles,
            {
              fileName: fileObjectData.name,
              thumbnail,
              imgID: Math.random(),
              actualFile: fileObjectData
            }
          ];
        }
        if (filesCount === fileObj?.length && !invalidFileMsg) {
          if (validatedFiles?.length) {
            if (isMulti) {
              setValue(fieldColumn, [...fileObj, ...fileData], {
                shouldValidate: true
              });
            } else if (!storyBookView && !isMulti) {
              setValue(fieldColumn, fileObj[0], {
                shouldValidate: true
              });
            }
          }
          setFileData(validatedFiles);
          setFileLoader(false);
        }
      });
    }
    event.target.value = null;
  };

  const resetFile = () => {
    if (!storyBookView) setValue(fieldColumn, null, { shouldValidate: true });
    setFileData([]);
  };

  const removeFile = (fileIndex) => {
    if (fileData?.length && fileData[fileIndex]?.fileDetail?.id) {
      setRemovedFileID((prevState) => [
        ...prevState,
        fileData[fileIndex]?.fileDetail?.id
      ]);
    }
    if (fileData?.length > 1) {
      const alteredFileData = fileData.filter(
        (fileDetail, index) => fileIndex !== index
      );
      setFileData(alteredFileData);
      if (!storyBookView)
        setValue(fieldColumn, alteredFileData, { shouldValidate: true });
    } else {
      resetFile();
    }
  };

  const triggerClick = () => {
    inputFileRef.current.click();
  };

  useEffect(() => {
    resetFile();
  }, [reset]);

  useImperativeHandle(ref, () => ({
    parentClick() {
      triggerClick();
    }
  }));

  return (
    <div className="file-upload-container">
      <Row>
        {fileData?.length
          ? fileData.map((fileImageData, index) => (
              <Col key={fileImageData?.imgID} className="mb-2" sm="auto">
                <div className="d-flex justify-content-between attach-img-container">
                  <div className="d-flex">
                    <img
                      id="image-preview"
                      className="image-preview"
                      src={fileImageData?.thumbnail}
                      alt=""
                      crossOrigin="anonymous"
                    />
                    <div className="px-3 pr-5 d-flex flex-column justify-content-center text-char-limit-15">
                      <span className="fs-6 font-regular">
                        <i>{fileImageData?.fileName}</i>
                      </span>
                    </div>
                    <div className="d-flex flex-column justify-content-center">
                      <Button
                        color="none"
                        className=""
                        onClick={() => removeFile(index)}>
                        <i className="bi bi-x-circle text-primary" />
                      </Button>
                    </div>
                  </div>
                </div>
                {/* <div className="preview-block mb-3" key={fileImageData?.imgID}>
                  <div className="image-container d-flex justify-content-center">
                    {fileImageData?.thumbnail && (
                      <img
                        id="image-preview"
                        src={fileImageData?.thumbnail}
                        alt=""
                      />
                    )}

                    <Button
                      color="none"
                      className="position-absolute top-0 end-0 p-0 pt-1 pe-1"
                      onClick={() => removeFile(index)}>
                      <i className="bi bi-x-circle text-primary" />
                    </Button>
                    <div className="single-line">
                      <span>
                        <i>{fileImageData?.fileName}</i>
                      </span>
                    </div>
                  </div>
                </div> */}
              </Col>
            ))
          : ''}
      </Row>

      <div className="mt-3">
        <input
          style={{ display: 'none' }}
          //   {...props}
          ref={inputFileRef}
          type="file"
          onChange={handleFileChange}
          accept={supportedType}
          //   value={fileList || ''}
          hidden
          multiple={isMulti}
        />
        {!handleClickFromParent && (
          <div>
            <Button
              color="primary"
              onClick={triggerClick}
              disabled={fileLoader}>
              {fieldCaption} &nbsp;
              <i className="bi bi-upload fw-500" />
            </Button>
          </div>
        )}

        {/* </form> */}
      </div>
      {errors && (
        <p className="input-field-error pt-2 mb-0">{validationMsg(errors)}</p>
      )}
    </div>
  );
});

export default FileUpload;
