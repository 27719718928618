import React, { useState } from 'react';
import './Commits.scss';
import {
  Popover,
  PopoverBody,
  UncontrolledPopover,
  UncontrolledTooltip
} from 'reactstrap';
import {
  timeago,
  openUrlInNewPage
} from '../../../../../../shared/helper/commonFunction';
import CopyInput from '../Components/CopyInput/CopyInput';

export default function Commits({
  taskData,
  isDevelopmentModalOpen,
  openCommitsModal
}) {
  const [showCommitPopover, setShowCommitPopover] = useState(false);
  const [showAddCommitPopover, setShowAddCommitPopover] = useState(false);

  const onHover = () => {
    if (!isDevelopmentModalOpen && !showAddCommitPopover) {
      setShowCommitPopover(true);
    }
  };

  const onHoverLeave = () => {
    setShowCommitPopover(false);
  };

  const toggleAddCommitPopover = () => {
    // setShowCommitPopover(false);
    setShowAddCommitPopover((value) => !value);
  };

  return (
    <>
      <div
        className="d-flex align-itens-center dev-details"
        id="gitDetailsPopoverTriggerId"
        onMouseEnter={onHover}
        onMouseLeave={onHoverLeave}>
        <div
          role="presentation"
          onClick={openCommitsModal}
          className="label font-regular cursor-pointer flex-grow-1 d-flex align-items-center justify-content-between p-2">
          {taskData.git?.commits.length ? (
            <div>
              {taskData.git?.commits.length}{' '}
              {taskData.git?.commits.length === 1 ? 'commit' : 'commits'}
            </div>
          ) : (
            <div> No commits </div>
          )}
          {taskData.git?.commits.length > 0 && (
            <div className="text-sub small">
              <em> {timeago(taskData.git?.commits[0].created_at)} </em>
            </div>
          )}
        </div>

        <button
          type="button"
          id={`createNewCommitPopoverId${taskData?.kanban_task_id}`}
          className="add-commit-btn d-flex align-items-center text-primary"
          style={showAddCommitPopover ? { maxWidth: '1rem' } : null}>
          <i className="bi bi-plus-square-dotted fw-500" />
        </button>

        <Popover
          target="gitDetailsPopoverTriggerId"
          isOpen={showCommitPopover && taskData.git?.commits.length > 0}
          placement="bottom"
          offset={[35, 0]}
          popperClassName="commit-popover"
          hideArrow>
          <PopoverBody>
            <div>
              <p className="font-bold text-secondary mb-1">COMMITS</p>
              <div
                className={
                  taskData.git?.commits.length > 1 ? 'border-bottom' : null
                }>
                <h6
                  role="presentation"
                  onClick={() =>
                    openUrlInNewPage(taskData.git?.commits[0]?.commit_url)
                  }
                  className="text-primary commit-link cursor-pointer text-truncate">
                  #{taskData.git?.commits[0]?.commit_id}
                </h6>
                <p
                  className={`text-sub small ${
                    taskData.git?.commits.length === 1 ? 'mb-0' : null
                  }`}>
                  <em>
                    {' '}
                    Last updated {timeago(
                      taskData.git?.commits[0]?.created_at
                    )}{' '}
                  </em>
                </p>
              </div>
              {taskData.git?.commits.length > 1 && (
                <p
                  role="presentation"
                  onClick={openCommitsModal}
                  className="text-primary commit-link cursor-pointer mb-0 mt-2">
                  +1 more commit
                </p>
              )}
            </div>
          </PopoverBody>
        </Popover>
      </div>

      <UncontrolledTooltip
        placement="top"
        target={`createNewCommitPopoverId${taskData?.kanban_task_id}`}>
        Create new commit
      </UncontrolledTooltip>

      <UncontrolledPopover
        target={`createNewCommitPopoverId${taskData?.kanban_task_id}`}
        trigger="legacy"
        isOpen={showAddCommitPopover}
        toggle={toggleAddCommitPopover}
        placement="bottom"
        offset={[-160, 0]}
        popperClassName="add-commit-popover"
        hideArrow>
        <PopoverBody>
          <div>
            <p className="font-bold text-secondary mb-1">
              LINK COMMITS TO KANBAN TASKS
            </p>
            <p className="text-primary">
              Include task keys in your commit messages to link them to your
              Kanban tasks.
              <br />
              {taskData?.git?.branches.length < 1 &&
                'Create a branch to add new commit.'}
            </p>

            <CopyInput label="Copy task key" value={taskData?.kanban_task_id} />
            <CopyInput
              label="Copy sample Git commit"
              value={`git commit -m "${taskData?.kanban_task_id} <message>"`}
            />
          </div>
        </PopoverBody>
      </UncontrolledPopover>
    </>
  );
}
